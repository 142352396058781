import { acceptHMRUpdate, defineStore } from 'pinia'
import { ref } from 'vue'

export const useTraverseStore = defineStore('traverse', () => {
  const isOpen = ref(false)

  function openTraverse() {
    isOpen.value = true
  }

  function closeTraverse() {
    isOpen.value = false
  }

  return {
    isOpen,
    openTraverse,
    closeTraverse
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTraverseStore, import.meta.hot))
}
