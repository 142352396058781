import * as types from '../../mutation-types'
import TranslationMixin from '../../../mixins/TranslationMixin'
import eventBus from '../../../eventBus'

const { l, getLang } = TranslationMixin

const baseModal = {
  name: '',
  size: 'md',
  resizeable: true,
  moveable: true,
  type: null,
  objects: [],
  go: true,
  open: () => {},
  opened: () => {},
  close: () => {},
  closed: () => {},
  save: () => {},
  saved: () => {},
  select: () => {},
  selected: () => {},
  timeAdded: 0
}

const state = {
  visible: [],
  topZIndex: 2000,
  unsavedChanges: [],
  selectorSelectedByType: {}
}

const getters = {}

const actions = {
  /**
   * Call this to select certain objects and return
   *   a promise that returns a payload with 'selected'
   *   object property with an array of selected objects:
   *
   *  $store.dispatch('modal/selector', {
   *    type: 'file',
   *    multiple: true })
   *    .then(({ selected = [] }) => {
   *       // selected == array of files selected, or
   *       //  an empty array if none selected
   *    });
   *
   * @param commit
   * @param state
   * @param payload
   * @returns {Promise}
   */
  selector({ dispatch }, payload = {}) {
    const { type = 'file', multiple = true, filters = {} } = payload
    const listenerId = _.uniqueId()

    const modal = {
      name: 'selector',
      listenerId,
      type,
      filters,
      multiple
    }

    return new Promise((resolve) => {
      dispatch('open', { modal })
      eventBus.$once(listenerId, (selected) => {
        resolve({ ...payload, selected })
      })
    })
  },
  /**
   * Unlike 'confirm', 'quickConfirm'
   * returns a promise with value true or false
   * depending on whether confirmed was chosen orn ot
   *
   *
   * @param dispatch
   * @param payload
   * @returns bool true|false
   */
  asyncConfirm({ dispatch, rootState }, payload = {}) {
    const size = rootState.session.deviceSize
    const {
      yes = l(size === 'xs' ? 'Yes' : 'Yes, go ahead', getLang(rootState.session.user)),
      no = l(size === 'xs' ? 'No' : 'No, cancel', getLang(rootState.session.user)),
      close = null,
      /**
       * [{
       *   value: any,
       *   title: string,
       *   desc: string,
       *   icon: string,
       *   html: string,
       *   containerClass: mixed,
       * }, ...]
       */
      actions = {},
      choices = []
    } = payload
    return new Promise((resolve) => {
      dispatch('confirm', {
        message: payload.message || 'Are you sure you would like to continue?',
        subMessage: payload.subMessage || '',
        choices: choices.map((ch) => ({
          ...ch,
          action: () => resolve(ch.value ?? null)
        })),
        choiceSkip: () => resolve(null),
        actions: {
          confirm: {
            class: 'primary',
            action: () => resolve(true),
            title: yes,
            hotkey: 'enter',
            ...('confirm' in actions ? actions.confirm : {})
          },
          cancel: {
            class: 'tertiary',
            action: () => resolve(false),
            title: no,
            hotkey: 'cmd-enter',
            visible: () => !!no,
            ...('cancel' in actions ? actions.cancel : {})
          },
          ...(close
            ? {
                close: {
                  class: 'tertiary',
                  icon: 'fa-xmark',
                  action: () => resolve('close'),
                  setDone: false,
                  title: close,
                  hotkey: 'esc',
                  ...('close' in actions ? actions.close : {})
                }
              }
            : {})
        }
      })
    })
  },
  /**
   * Unlike 'confirm', 'quickConfirm'
   * resolves if the user chooses 'Yes' and rejects
   * if the user chooses 'No'
   *
   *
   * @param dispatch
   * @param payload
   * @returns {*}
   */
  quickConfirm({ dispatch, rootState }, payload = {}) {
    const {
      yes = l('Yes, go ahead..', getLang(rootState.session.user)),
      no = l('No, cancel..', getLang(rootState.session.user)),
      actions = {}
    } = payload
    return new Promise((resolve, reject) => {
      dispatch('confirm', {
        title: payload.title || '',
        message: payload.message || 'Are you sure you would like to continue?',
        subMessage: payload.subMessage || '',
        actions: {
          confirm: {
            class: 'success',
            action: () => resolve(),
            title: yes,
            ...('confirm' in actions ? actions.confirm : {})
          },
          cancel: {
            class: '',
            action: () => reject(),
            title: no,
            ...('cancel' in actions ? actions.cancel : {})
          }
        }
      })
    })
  },
  /**
   * ALWAYS RESOLVES
   * Provide message, and actions.
   * Default action for cancel is already set,
   *
   * Provide actions in this format:
   * {
      title: '',
      glyph: null,
      action: () => {},
      class: 'btn btn-outline-primary',
      visible: () => true,
      setDone: true,
    }

   * if you provide a promise for the action,
   * the promise will must resolve before
   * the modal is closed.
   *
   *
   * @param commit
   * @param state
   * @param payload
   * @returns {Promise}
   */
  confirm({ commit, rootState }, payload = {}) {
    let {
      title = '',
      message = 'Are you sure you want to perform that action?',
      subMessage = '',
      body = '',
      actions = {},
      choices = [],
      choiceSkip: cs = () => {}
    } = payload

    const defaultAction = {
      title: '',
      glyph: null,
      action: () => {},
      class: '',
      visible: () => true,
      setDone: true
    }
    const defaultActions = {
      cancel: {
        ...defaultAction,
        title: l('No, cancel..', getLang(rootState.session.user)),
        icon: 'ban',
        setDone: false
      },
      confirm: {
        ...defaultAction,
        class: 'info',
        title: l('Yes, go ahead..', getLang(rootState.session.user)),
        icon: 'check'
      }
    }

    return new Promise((resolve) => {
      actions = _.merge(defaultActions, actions)
      Object.keys(actions).forEach((k) => {
        actions[k] = {
          ...defaultAction,
          ...actions[k],
          title: actions[k].text || actions[k].title,
          icon: actions[k].glyph || actions[k].icon
        }
      })
      let modal
      const refId = `modal-${new Date().valueOf()}-${_.uniqueId()}`

      // Wrap actions so we can resolve this
      //  and a then() can be called on this dispatch
      //  once an action is taken by the user.
      // Bound to component instance
      choices = choices.map((ch) => ({
        ...ch,
        action: async (m) => {
          await ch.action()
          setTimeout(m.close)
        }
      }))
      const choiceSkip = (m) => {
        cs()
        if (m) m.close()
      }

      let wrappedActions = {}
      Object.keys(actions).forEach((actionName) => {
        wrappedActions = {
          ...wrappedActions,
          [actionName]: {
            ...actions[actionName],
            action() {
              // Bound to component instance
              const closeConfirm = actions[actionName].setDone
                ? () => setTimeout(this.close, 1000)
                : this.close

              return new Promise((rs) => {
                const promise = actions[actionName].action()
                if (promise && promise.then) {
                  promise
                    .then(() => {
                      resolve()
                      rs()
                      closeConfirm()
                    })
                    .catch(() => {
                      resolve()
                      rs()
                      closeConfirm()
                    })
                } else {
                  resolve()
                  rs()
                  closeConfirm()
                }
              })
            }
          }
        }
      })

      modal = {
        ...baseModal,
        name: 'Confirm',
        title,
        message,
        subMessage,
        body,
        actions: wrappedActions,
        choices,
        choiceSkip,
        refId
      }

      commit({
        type: types.MODAL_OPEN,
        modal
      })
    })
  },
  async closeAll({ commit, dispatch, state }, { confirm = false } = {}) {
    if (
      state.visible.length &&
      (!confirm || (await dispatch('asyncConfirm', { message: 'Close this & go?' })))
    ) {
      commit({
        type: types.MODAL_CLOSE_ALL
      })

      return true
    }

    return false
  },

  /**
   * Get a prompt modal that asks for
   * input then returns when done
   *
   * const name = await this.$store.dispatch('prompt', { message: "what is your name?" });
   *
   * @param dispatch
   * @param payload
   *    @param string message
   *    @param string inputType       default 'text'
   *    @param string defaultValue    default ''
   *    @param bool required          default true
   * @returns {Promise}
   */
  prompt({ dispatch, rootState }, payload = {}) {
    const {
      message = l('Answer', getLang(rootState.session.user)),
      /**
       * text|choose
       */
      inputType = 'text',
      /**
       * If not required will return null
       */
      required = true,
      /**
       * ID of choose value, or string for text
       */
      defaultValue = '',
      /**
       * For choose
       */
      entityType = null,
      /**
       * For choose
       */
      filters = {},
      /**
       * staticSet for chosoe instead of fetch
       */
      staticSet = null,

      confirmText = l('Save', getLang(rootState.session.user)),

      format = null,

      title = '',

      placeholder = ''
    } = payload

    return new Promise((resolve, reject) => {
      dispatch('open', {
        modal: {
          name: 'Prompt',
          message,
          defaultValue,
          inputType,
          required,
          entityType,
          filters,
          staticSet,
          format,
          confirmText,
          title,
          placeholder,
          prompt(value) {
            if (value && value !== '') resolve(value)
            reject()
          }
        }
      })
    })
  },

  /**
   *
   * @param commit
   * @param state
   * @param dispatch
   * @param modal { name: 'MyModalName', ...other options }
   *  -close (vm) (before object is destroyed)
   *  -closed
   *  -open
   *  -opened
   *  -select (before object is fully loaded)
   *  -selected (vm) (after object is fully loaded)
   *  -save
   *  -saved
   *  -resizable
   *  -size
   *  -moveable
   *  -objects (can also be set in the payload)
   * @param embue Fill all objects with these predefined values { type: 'quote', client_id: '123' }
   * @param objects [{ quote_id: 321, quote_name: 'my quote', }]
   * @param button
   * @returns {Promise}
   */
  open({ commit, dispatch }, payload) {
    const { modal, objects = [], filters, button = null, embue = {}, closeOthers = false } = payload
    const go = typeof payload.go !== 'undefined' ? payload.go : modal.go
    let defaultedModal = {
      ...baseModal,
      ...(typeof modal === 'string'
        ? {
            name: modal
          }
        : modal),
      embue: modal.embue || embue,
      objects:
        (objects && objects.length && objects) ||
        (modal.objects && modal.objects.length && modal.objects) ||
        [],
      filters: filters || modal.filters,
      go,
      uid: `modal_${_.uniqueId()}`
    }
    c.assert(
      !modal.grid,
      `Do not put grid objects into modal. It creates recursive reference.
      Put it in the general payload instead ie:
      ...$dispatch('modal/open', {
        grid, // DO put it here
        button,
        modal: {
          /* grid, */ // DO NOT PUT IT HERE
          save() {},
          // etc
        }
      })`
    )
    c.addLoadingAll(button)
    if (closeOthers) {
      commit({
        type: types.MODAL_CLOSE_ALL
      })
    }
    dispatch('hideAllTooltips', {}, { root: true })
    commit({
      type: types.MODAL_OPEN,
      modal: defaultedModal
    })
    setTimeout(() => c.removeLoadingAll(button), 1000)

    return Promise.resolve({ modal: defaultedModal, objects, button })
  },
  close({ commit }, payload) {
    const { modal = null } = payload
    commit({
      type: types.MODAL_CLOSE,
      modal
    })
    return Promise.resolve(payload)
  },
  focus(payload, { modal, button = null }) {
    return new Promise((resolve) => {
      resolve({ modal, button })
    })
  },
  setSelectorSelected({ commit }, { type, selected }) {
    commit(types.SET_SELECTOR_TYPE_SELECTED, { type, selected })
  }
}

const mutations = {
  [types.MODAL_CLOSE_ALL](state) {
    state.visible = []
    state.topZIndex = 2000
  },
  [types.MODAL_OPEN](state, { modal = [] }) {
    modal.timeAdded = new Date().valueOf()
    state.visible.push(modal)
    state.topZIndex = 2000 + state.visible.length * 10
  },
  [types.MODAL_CLOSE](state, { modal = [] }) {
    const index = state.visible.indexOf(modal)
    if (index > -1) {
      state.visible.splice(index, 1)
      state.topZIndex = 2000 + state.visible.length * 10
    }
  },
  [types.SET_SELECTOR_TYPE_SELECTED](state, { type, selected }) {
    state.selectorSelectedByType[type] = selected
  },
  [types.UPDATE_VISIBLE_INDEX](state, { index, modal }) {
    state.visible[index] = {
      ...state.visible[index],
      modal
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
