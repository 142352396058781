import LogRocket from 'logrocket'
import { LogRocketSymbol } from './symbol'
import DummyLogRocket from '@/plugins/vue-logrocket/dummyPlug'

export default {
  install(app) {
    let LogRocketInstance
    if (import.meta.env.MODE === 'staging' || import.meta.env.MODE === 'production') {
      LogRocketInstance = LogRocket
    } else {
      LogRocketInstance = new DummyLogRocket()
    }

    LogRocketInstance.init('5xf2ya/costcertified')

    app.config.globalProperties.$logrocket = LogRocketInstance
    app.provide(LogRocketSymbol, LogRocketInstance)
  }
}
