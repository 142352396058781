import { enterSuperScope } from '@/router/guards/beforeEnter'

const meta = {
  scopesAllowed: ['user'],
  menuContext: 'super'
}

const metaWithFilters = {
  ...meta,
  permanentFilters: {
    company_id: 'NULL'
  }
}

export default [
  {
    path: '/super',
    beforeEnter: [enterSuperScope],
    children: [
      {
        path: '/super/assistants',
        name: 'Super Assistants',
        component: () => import('@/components/pages/SuperAssistants.vue'),
        meta
      },
      {
        path: 'companies',
        alias: ['/super'],
        name: 'Super Companies',
        component: () => import('@/components/pages/SuperCompanies.vue'),
        meta
      },
      {
        path: 'companiesLegacy',
        alias: ['/companiesLegacy'],
        name: 'Super Companies Legacy',
        component: () => import('@/components/pages/SuperCompaniesLegacy.vue'),
        meta
      },
      {
        path: 'company/:id',
        name: 'Edit Company',
        component: () => import('@/components/pages/Company.vue'),
        meta: {
          confirmLeave: true,
          menu: () => import('@/components/menus/CompanyMenu.vue'),
          header: () => import('@/components/headers/CompanyHeader.vue'),
          type: 'company',
          ...meta
        }
      },
      {
        path: 'users',
        alias: ['/users'],
        name: 'Super Users',
        component: () => import('@/components/pages/SuperUsers.vue'),
        meta
      },
      {
        path: '/:scopeRoute/user/:id',
        name: 'Edit User',
        component: () => import('@/components/pages/User.vue'),
        meta: {
          confirmLeave: true,
          menu: () => import('@/components/menus/UserMenu.vue'),
          header: () => import('@/components/headers/UserHeader.vue'),
          type: 'user',
          ...meta
        }
      },
      {
        path: 'items',
        alias: ['/items'],
        name: 'Items',
        component: () => import('@/components/pages/SuperItems.vue'),
        meta: metaWithFilters
      },
      {
        path: 'import',
        alias: ['/import', '/importitems'],
        name: 'Import',
        component: () => import('@/components/pages/SuperImport.vue'),
        meta
      },
      {
        path: 'dimensions',
        alias: ['/dimensions'],
        name: 'Super Dimensions',
        component: () => import('@/components/pages/SuperDimensions.vue'),
        meta: metaWithFilters
      },
      {
        path: 'report/:id?',
        alias: ['/report/:id?', '/reports/:id?'],
        name: 'Super Reports',
        component: () => import('@/components/pages/SuperReports.vue'),
        meta
      },
      {
        path: '/super/stripe-products',
        alias: '/stripe-products',
        name: 'StripeProducts',
        component: () => import('@/components/pages/SuperStripeProductMap.vue'),
        meta
      },
      {
        path: '/super/products',
        alias: '/products',
        name: 'SuperProducts',
        component: () => import('@/components/pages/SuperProducts.vue'),
        meta
      },
      {
        path: 'settings',
        alias: ['/settings'],
        name: 'System Settings',
        component: () => import('@/components/pages/SuperSettings.vue'),
        meta
      }
    ]
  },
  {
    path: '/super/quote',
    alias: ['/quote', '/project'],
    name: 'Super Quote',
    component: () => import('@/components/pages/SuperQuote.vue'),
    props: {
      type: 'quote'
    },
    meta: {
      confirmLeave: true,
      ...meta,
      ...metaWithFilters,
      header: () => import('@/components/headers/SuperQuoteHeader.vue'),
      menu: () => import('@/components/menus/SuperQuoteMenu.vue'),
      type: 'quote'
    }
  },
  {
    path: '/copyitems',
    alias: ['/copyitems', '/copy'],
    name: 'Copy Items',
    component: () => import('@/components/pages/SuperItemCopy.vue'),
    meta,
    beforeEnter: [enterSuperScope]
  }
]
