<script setup>
import { watch, ref, defineProps } from 'vue'

const props = defineProps({
  loading: {
    default: 0
  },
  exact: {
    type: Boolean,
    default: false
  }
})

const loadingProgress = ref(0)
let interval = null
watch(
  () => props.loading,
  (newLoad, oldLoad) => {
    const num = newLoad || 0

    let val = 0
    if (props.exact) {
      val = newLoad
    } else {
      if (num > 2) val = 15
      if (num > 1) val = 25
      if (num === 1) val = 45
    }

    if (num === 0 && oldLoad) {
      val = 99
      setTimeout(() => {
        loadingProgress.value = 0
      }, 400)
    }

    loadingProgress.value = val

    if (!props.exact) {
      // interval = setInterval(() => {
      //   if (loadingProgress.value > 0 && loadingProgress.value < 85) {
      //     loadingProgress.value += Math.round(Math.random() * 5)
      //   }
      // }, 400)

      if (!props.loading) clearInterval(interval)
    }
  },
  { immediate: true }
)
</script>

<template>
  <div class="absolute top-0 right-0 left-0 bottom-auto h-[4px]">
    <div
      class="bg-secondary h-full transition-[width] ease-out"
      :style="`width: ${loadingProgress}%; opacity: ${loadingProgress === 100 || loadingProgress === 0 ? '0' : '1'};`"
    ></div>
  </div>
</template>

<style scoped lang="scss"></style>
