import { createStore } from 'vuex'
import alert from './modules/alert'
import modal from './modules/modal'
import directPay from './modules/directPay'
import message from './modules/message'
import milestone from './modules/milestone'
import session from './modules/session'
import phone from './modules/phone'
import general from './modules/general'
import help from './modules/help'
import search from './modules/search'
import sign from './modules/sign'
import banner from './modules/banner'
import activityChat from './modules/activityChat'
import rating from './modules/rating'
import eventNotification from './modules/eventNotification'
import payfac from './modules/payfac'
import EntityStores from '../EntityStores'

function createWarningPlugin() {
  return (store) => {
    let inMutation = false

    const originalCommit = store.commit
    store.commit = function (...args) {
      inMutation = true
      originalCommit.apply(this, args)
      inMutation = false
    }

    store.watch(
      () => store.state,
      () => {
        if (!inMutation) {
          console.warn('[vuex] Detected state mutation outside of mutation handlers.')
        }
      },
      { deep: true, flush: 'sync' }
    )
  }
}

const modules = {
  alert,
  eventNotification,
  modal,
  message,
  session,
  general,
  phone,
  help,
  search,
  sign,
  milestone,
  directPay,
  banner,
  activityChat,
  rating,
  payfac,
  ...EntityStores
}

export const store = createStore({
  modules,
  strict: false,
  plugins: import.meta.env.DEV ? [createWarningPlugin()] : []
})

/*
 * Set up Vuex for HMR.
 *
 * Some things to note:
 * - Due to the way the Vuex `hotUpdate` function works, only _actions_ and
 *   _mutations_ are hot reloadable. Check out https://vuex.vuejs.org/api/#hotupdate
 *   and https://vuex.vuejs.org/guide/hot-reload.html for more info.
 * - Since we don't export the modules name inside the module itself, we have
 *   to handle the naming here in a very verbose way, hence the explicit module
 *   naming and redundant calls to store.hotUpdate()
 */
if (import.meta.hot) {
  import.meta.hot.accept(
    [
      './modules/alert/index.js',
      './modules/modal/index.js',
      './modules/directPay/index.js',
      './modules/message/index.js',
      './modules/milestone/index.js',
      './modules/session/index.js',
      './modules/phone/index.js',
      './modules/general/index.js',
      './modules/help/index.js',
      './modules/search/index.js',
      './modules/sign/index.js',
      './modules/banner/index.js',
      './modules/activityChat/index.js',
      './modules/rating/index.js',
      './modules/eventNotification/index.js',
      './modules/payfac/index.js',
      '../EntityStores.js'
    ],
    ([
      alertModule,
      modalModule,
      directPayModule,
      messageModule,
      milestoneModule,
      sessionModule,
      phoneModule,
      generalModule,
      helpModule,
      searchModule,
      signModule,
      bannerModule,
      activityChatModule,
      ratingModule,
      eventNotificationModule,
      payfacModule,
      entityStoresModule
    ]) => {
      if (alertModule) store.hotUpdate({ modules: { alert: alertModule.default } })
      if (modalModule) store.hotUpdate({ modules: { modal: modalModule.default } })
      if (directPayModule) store.hotUpdate({ modules: { directPay: directPayModule.default } })
      if (messageModule) store.hotUpdate({ modules: { message: messageModule.default } })
      if (milestoneModule) store.hotUpdate({ modules: { milestone: milestoneModule.default } })
      if (sessionModule) store.hotUpdate({ modules: { session: sessionModule.default } })
      if (phoneModule) store.hotUpdate({ modules: { phone: phoneModule.default } })
      if (generalModule) store.hotUpdate({ modules: { general: generalModule.default } })
      if (helpModule) store.hotUpdate({ modules: { help: helpModule.default } })
      if (searchModule) store.hotUpdate({ modules: { search: searchModule.default } })
      if (signModule) store.hotUpdate({ modules: { sign: signModule.default } })
      if (bannerModule) store.hotUpdate({ modules: { banner: bannerModule.default } })
      if (activityChatModule)
        store.hotUpdate({ modules: { activityChat: activityChatModule.default } })
      if (ratingModule) store.hotUpdate({ modules: { rating: ratingModule.default } })
      if (eventNotificationModule)
        store.hotUpdate({ modules: { eventNotification: eventNotificationModule.default } })
      if (payfacModule) store.hotUpdate({ modules: { payfac: payfacModule.default } })
      if (entityStoresModule) store.hotUpdate({ modules: { ...entityStoresModule.default } })
    }
  )
}

export default store
