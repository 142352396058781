export default {
  root: ({ state, instance }) => ({
    class: [
      // Shape
      'rounded-lg',
      'shadow-lg',
      'border-0',
      'max-w-[100vw]',

      // Size
      {
        'w-full': instance.$attrs.includeGutter,
        'h-[100vh]': instance.$attrs.includeGutter,
        'max-h-[100vh]': instance.$attrs.includeGutter,
        'max-h-[90vh]': !instance.$attrs.includeGutter,
        'w-[600px]': instance.$attrs.size === 'md',
        'w-[800px]':
          !instance.$attrs.includeGutter &&
          !instance.$attrs.isResetPassword &&
          !(instance.$attrs.size === 'lg' || instance.$attrs.size === 'md'),
        'w-[1200px]': instance.$attrs.size === 'lg',
        'flex flex-col justify-start items-stretch':
          instance.$attrs.fixed || (instance.$attrs.full && !instance.$attrs.scrollable)
      },
      'm-0',

      // Color
      'dark:border',
      'dark:border-surface-700',
      'bg-surface-0',

      // Transitions
      'transform',
      'scale-100',

      // Maximized State
      {
        'transition-none': state.maximized,
        'transform-none': state.maximized,
        '!w-screen': state.maximized,
        '!h-screen': state.maximized,
        '!max-h-full': state.maximized,
        '!top-0': state.maximized,
        '!left-0': state.maximized
      }
    ]
  }),
  header: ({ instance }) => ({
    class: [
      // Flexbox and Alignment
      'flex items-center justify-between',
      {
        'shrink-0 grow-0':
          instance.$attrs.fixed || (instance.$attrs.full && !instance.$attrs.scrollable),
        'flex-row-reverse': !instance.$attrs.closex
      },

      // Spacing
      {
        'py-4 px-2 lg:px-5 leading-none': !instance.$attrs.full || instance.$attrs.full
      },

      // Shape
      'border-t-0',
      // {
      //     'border-b-2': instance.$attrs.hasHeaderBorder
      // },
      'rounded-tl-lg',
      'rounded-tr-lg',

      // Font
      // 'font-header',
      'text-xl font-medium',

      // Colors
      'bg-surface-100/60 dark:bg-surface-800',
      'text-primary-950 dark:text-surface-0/80'
    ]
  }),
  title: {
    class: ['font-bold text-lg']
  },
  icons: {
    class: ['flex items-center']
  },
  closeButton: ({ instance }) => ({
    class: [
      'relative',

      {
        hidden: !instance.$attrs.closeable
      },

      // Flexbox and Alignment
      'flex items-center justify-center',

      // Size and Spacing
      'mr-2',
      'last:mr-0',
      'w-8',

      // Shape
      'border-0',
      'rounded-full',

      // Colors
      'text-surface-800',

      // Transitions
      'transition duration-200 ease-in-out',

      // States
      // 'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-inset',
      // 'focus:ring-primary-400/50 dark:focus:ring-primary-300/50',

      // Misc
      'overflow-hidden'
    ]
  }),
  maximizablebutton: {
    class: [
      'relative',

      // Flexbox and Alignment
      'flex items-center justify-center',

      // Size and Spacing
      'mr-2',
      'last:mr-0',
      'w-8 h-8',

      // Shape
      'border-0',
      'rounded-full',

      // Colors
      'text-surface-500',
      'bg-transparent',

      // Transitions
      'transition duration-200 ease-in-out',

      // States
      'hover:text-surface-700 dark:hover:text-white/80',
      'hover:bg-surface-100 dark:hover:bg-surface-800/80',
      // 'focus:outline-none focus:outline-offset-0 focus:ring focus:ring-inset',
      // 'focus:ring-primary-400/50 dark:focus:ring-primary-300/50',

      // Misc
      'overflow-hidden'
    ]
  },
  closeButtonIcon: {
    class: [
      // Display
      'inline-block',

      // Size
      'w-6',
      'h-6',
      'hover:bg-surface-100'
    ]
  },
  maximizableicon: {
    class: [
      // Display
      'inline-block',

      // Size
      'w-4',
      'h-4'
    ]
  },
  content: ({ state, instance }) => ({
    class: [
      // Spacing
      {
        'px-2 lg:px-6': !instance.$attrs.hasSidePanel && !instance.$attrs.fixed,
        'basis-full shrink grow':
          instance.$attrs.fixed || (instance.$attrs.full && !instance.$attrs.scrollable),

        // this is the outer scroll container
        // so it shouldn't be larger than its container.
        // The contents of this container can be whatever height because they are what will scroll
        'basis-full shrink grow-0': instance.$attrs.full && instance.$attrs.scrollable,
        'pb-8':
          !instance.$attrs.hasSidePanel && (!instance.$attrs.full || instance.$attrs.scrollable)
      },

      // Shape
      {
        grow: state.maximized,
        'rounded-bl-lg': !instance.$slots.footer,
        'rounded-br-lg': !instance.$slots.footer
      },

      // Colors
      'bg-surface-100/60 dark:bg-surface-800 overflow-y-scroll',
      'text-primary-900 dark:text-surface-0/80',

      // Misc
      {
        'overflow-y-auto': instance.$attrs.scrollable,
        'overflow-y-hidden': !instance.$attrs.scrollable
      }
    ]
  }),
  footer: ({ instance }) => ({
    class: [
      // Flexbox and Alignment
      'w-full',
      {
        'flex justify-end items-end': instance.$attrs?.size === '',
        block: !!instance.$attrs?.size
      },
      'shrink-0',
      'text-right',
      'gap-2',

      // Spacing
      'p-4',
      'px-2 lg:px-6',
      'pb-6',

      // Shape
      'border-t-0',
      'rounded-b-lg',

      // Colors
      {
        'bg-surface-100/60 dark:bg-surface-800':
          instance.$attrs.footerAccent || !instance.$attrs.footerAccent
      },

      'text-surface-700 dark:text-surface-0/80'
    ]
  }),
  mask: ({ props }) => ({
    class: [
      // Transitions
      'transition-all',
      'duration-300',
      { 'p-5': !props.position == 'full' },

      // Background and Effects
      {
        'has-[.mask-active]:bg-transparent bg-black/40': props.modal,
        'has-[.mask-active]:backdrop-blur-none backdrop-blur-sm': props.modal
      }
    ]
  }),
  transition: ({ props }) => {
    return props.position === 'top'
      ? {
          enterFromClass:
            'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active',
          enterActiveClass: 'transition-all duration-200 ease-out',
          leaveActiveClass: 'transition-all duration-200 ease-out',
          leaveToClass:
            'opacity-0 scale-75 translate-x-0 -translate-y-full translate-z-0 mask-active'
        }
      : props.position === 'bottom'
        ? {
            enterFromClass: 'opacity-0 scale-75 translate-y-full mask-active',
            enterActiveClass: 'transition-all duration-200 ease-out',
            leaveActiveClass: 'transition-all duration-200 ease-out',
            leaveToClass:
              'opacity-0 scale-75 translate-x-0 translate-y-full translate-z-0 mask-active'
          }
        : props.position === 'left' ||
            props.position === 'topleft' ||
            props.position === 'bottomleft'
          ? {
              enterFromClass:
                'opacity-0 scale-75 -translate-x-full translate-y-0 translate-z-0 mask-active',
              enterActiveClass: 'transition-all duration-200 ease-out',
              leaveActiveClass: 'transition-all duration-200 ease-out',
              leaveToClass:
                'opacity-0 scale-75  -translate-x-full translate-y-0 translate-z-0 mask-active'
            }
          : props.position === 'right' ||
              props.position === 'topright' ||
              props.position === 'bottomright'
            ? {
                enterFromClass:
                  'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active',
                enterActiveClass: 'transition-all duration-200 ease-out',
                leaveActiveClass: 'transition-all duration-200 ease-out',
                leaveToClass:
                  'opacity-0 scale-75 translate-x-full translate-y-0 translate-z-0 mask-active'
              }
            : {
                enterFromClass: 'opacity-0 scale-75 mask-active',
                enterActiveClass: 'transition-all duration-200 ease-out',
                leaveActiveClass: 'transition-all duration-200 ease-out',
                leaveToClass: 'opacity-0 scale-75 mask-active'
              }
  }
}
