// Core imports
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import store from './store'
import $h from './Helpers'
import './theme/css/style.css'

// Router
import router, { registerGlobalGuards } from '@/router'

// Initialize Amplify
import '@/config/amplify.js'
import { addCapacitorListeners } from '@/config/capacitor'

// External imports
import PrimeVue from 'primevue/config'
import moment from 'moment'
import AsyncComputed from 'vue-async-computed'
import Vue3TouchEvents from 'vue3-touch-events'
import VueObserveVisibility from 'vue-observe-visibility'
import contenteditable from 'vue-contenteditable'
import VueClickAway from 'vue3-click-away'
import FontAwesomeIcon from './fontAwesome'

// Theme imports
import bolsterTheme from '@/theme/presets/bolster'

// Filter imports
import filters from './filters'

import vueZxcvbn from '@/plugins/vue-zxcvbn'
import vueLogRocket from '@/plugins/vue-logrocket'

// Graphql appsync libraries
import { createApolloProvider } from '@vue/apollo-option'
import { client } from './apollo-client/apollo-config'

// Localization libraries
import { createI18n } from 'vue-i18n'
import messages from './languages'

// Directives imports
import Tooltip from 'primevue/tooltip'
import BadgeDirective from 'primevue/badgedirective'
import ToastService from 'primevue/toastservice'

// Global Components
// IF YOU ADD COMPONENTS HERE I WILL HUNT YOU DOWN AND SMASH YOUR KNEECAPS
import Btn from '@/components/ui/Btn.vue'
import Icon from '@/components/ui/Icon.vue'
import Choose from '@/components/ui/Choose/Choose.vue'

// set moment configuration
moment.updateLocale('en', {
  calendar: {
    lastDay: '[Yesterday at] LT',
    sameDay: '[Today at] LT',
    nextDay: '[Tomorrow at] LT',
    lastWeek: '[last] dddd [at] LT',
    nextWeek: 'dddd [at] LT',
    sameElse: 'D MMM YYYY'
  }
})

const pinia = createPinia()

// instantiate the app
const app = createApp(App)

// add global helpers
app.config.c = $h

// external
app.use(AsyncComputed)
app.use(Vue3TouchEvents)
app.use(VueObserveVisibility)
app.use(contenteditable)
app.use(VueClickAway)

// graphql apollo
const apolloProvider = new createApolloProvider({
  defaultClient: client
})
app.use(apolloProvider)

// setup locale
const i18n = new createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en',
  messages,
  silentTranslationWarn: true,
  silentFallbackWarn: true
})
app.use(i18n)

app.use(vueZxcvbn)
app.use(vueLogRocket)

// Add directives
app.directive('tooltip', Tooltip)
app.directive('badge', BadgeDirective)

// Add global components
app.component('Btn', Btn)
app.component('Icon', Icon)
app.component('Choose', Choose)

// External components
app.component('font-awesome-icon', FontAwesomeIcon)

// Add filters
app.config.globalProperties.$f = filters

// add router and guards
app.use(router)
registerGlobalGuards()
addCapacitorListeners(router)
// add component library
app.use(PrimeVue, {
  unstyled: true,
  pt: bolsterTheme
})
app.use(ToastService)
// add the vuex store
app.use(store)
app.use(pinia)
// mount the app
app.mount('#app')
