// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import CostMatrix from './CostMatrix'
import _ from '../Helpers'
import UserError from '../UserError'
import NormalizeUtilities from '../NormalizeUtilities'
import QuoteAddons from './QuoteAddons'
import AutoCost from '../AutoCost.js'
import { v4 } from 'uuid'

let laborPerc = null

const { getNormalizedRootRefId } = NormalizeUtilities

export const defaultVariationItem = {
  // Full item name
  name: 'Item name',
  // unique identifier (whether saved or not)
  uid: '',
  // Item id
  id: '',
  // description
  desc: '',
  // per unit price
  rate: 0,
  // per unit cost
  cost: 0,
  // List of pictures, first one being main
  file_ids: [],
  // Allow the user to add their own option, with pending pricing?
  allow_custom: 1,
  // VarianceValues color: 'sm', size: 'blue', shape: 'round' etc
  variations: {},
  // tag -> oTag[key]
  tag: {},

  live_price_reference: null,

  livePriceRef: null,

  aoImages: [],

  sku: '',

  labor_type_id: ''
}

export const defaultSelectionTraits = {
  imgs: [],
  desc: '',
  rate: 0,
  tags: [],
  name: ''
}

const defaultTraitType = {
  variationTypeOptions: {
    backgroundSize: 'cover',
    imageShape: 'circle', // square, circle, fit
    locked: 0,
    hidden: 0
  }
}

const getComputedDependants = () => {
  // Table that converts old units of measure to new
  // units of measure, and adds the required dimension to the equation
  const conversions = {
    9: ['fa', 'ft2'],
    10: ['iwp', 'ft'],
    23: ['iwa', 'ft2'],
    24: ['iwh', 'ft'],
    29: ['iwp', 'ft'],
    510: ['rl', 'm'],
    600: ['fp', 'ft2'],
    601: ['ewp', 'ft'],
    602: ['ewh', 'ft'],
    603: ['ewa', 'ft2'],
    604: ['ra', 'ft2'],
    605: ['rl', 'ft'],
    606: ['fa', 'ft2'],
    607: ['ca', 'ft2'],

    608: ['fa', 'm2'],
    609: ['fp', 'm'],
    610: ['iwh', 'm'],
    611: ['iwa', 'm2'],
    612: ['ewp', 'm'],
    613: ['ewh', 'm'],
    614: ['ewa', 'm2'],
    615: ['ra', 'm2'],
    616: ['ca', 'm2'],
    617: ['rl', 'm']
  }

  return {
    cost_type_hours_per_unit(state) {
      if (state.cost_type_is_fee && state.cost_type_has_labor) {
        const labRate = state.labor_type_cost_net
        return _.divide(1, labRate) // set to 1 dollar
      }

      if (state.cost_type_is_fee && !state.cost_type_has_labor) {
        return 0
      }

      return state.cost_type_hours_per_unit
    },
    cost_type_qty_equation(state) {
      if (
        state.cost_type_is_fee ||
        (state.cost_type_qty_equation &&
          state.cost_type_qty_equation !== 'null' &&
          state.cost_type_qty_equation !== '') ||
        (state.type === 'cost_item' && !state.cost_item_link_qty)
      ) {
        return state.cost_type_qty_equation
      }

      const uomid = String(state.unit_of_measure_id)
      if (uomid in conversions) {
        return conversions[uomid][0]
      }

      return null
    },
    unit_of_measure_id(state) {
      if (state.cost_type_is_fee) {
        return 'count'
      }
      const uomid = String(state.unit_of_measure_id)
      if (uomid in conversions) {
        return conversions[uomid][1]
      }

      return uomid
    },
    unit_of_measure_abbr(state) {
      if (state.cost_type_is_fee) {
        return 'each'
      }
      if (state.unit_of_measure_abbr === 'ft2 (floor)') return 'ft2'
      if (state.unit_of_measure_abbr === 'ft2 (walls)') return 'ft2'
      if (state.unit_of_measure_abbr === 'ft (walls)') return 'ft'
      return state.unit_of_measure_abbr
    },
    dimension_measure_type(state) {
      if (state.cost_type_is_fee) {
        return 'count'
      }
      return _.getMeasureTypeForUnitOfMeasure(state.unit_of_measure_id)
    },
    cost_type_materials_purchase_qty_per_unit(state) {
      if (
        state.cost_type_materials_purchase_qty_per_unit === null ||
        typeof state.cost_type_materials_purchase_qty_per_unit === 'undefined' ||
        state.cost_type_is_subcontracted
      ) {
        return 1
      }

      return state.cost_type_materials_purchase_qty_per_unit
    },

    purchase_unit_of_measure_id(state) {
      if (!state.purchase_unit_of_measure_id || state.cost_type_is_subcontracted) {
        return state.unit_of_measure_id
      }

      return state.purchase_unit_of_measure_id
    },

    purchase_unit_of_measure_abbr(state) {
      if (!state.purchase_unit_of_measure_abbr || state.cost_type_is_subcontracted) {
        return state.unit_of_measure_abbr
      }

      return state.purchase_unit_of_measure_abbr
    },

    purchase_unit_of_measure_name(state) {
      if (!state.purchase_unit_of_measure_name || state.cost_type_is_subcontracted) {
        return state.unit_of_measure_name
      }

      return state.purchase_unit_of_measure_name
    },

    cost_type_material_waste_factor_net(state) {
      if (state.cost_type_is_subcontracted) {
        return 0
      }

      return state.cost_type_material_waste_factor_net
    },
    cost_type_materials_purchase_price_net(state) {
      return (
        state.cost_matrix_materials_cost_net *
        (state.cost_type_materials_purchase_qty_per_unit || 1)
      )
    },
    ...CostMatrix.getComputedDependants(),

    /**
     * Linked dimensions, not including addons
     * @param state
     * @param parent
     * @param children
     * @param possibleDimensions
     * @returns {[]|Array}
     */
    asDimensionsLinked(state, parent, children, possibleDimensions) {
      const { usingDimensions: dimensionsInEquation } = _.getComputedDimension(
        {
          ...(possibleDimensions || {}),
          ...(parent.oDimensions || {})
        },
        state.cost_type_qty_equation
      )

      return _.uniq([..._.makeArray(dimensionsInEquation || [])])
    },

    /**
     * Based on options/upgrades as well as the
     * unit of measure, figure out what dimensions
     * are required
     * @returns {string[]}
     */
    asDimensionsUsed(state) {
      return _.uniq([
        ..._.makeArray(state.asDimensionsLinked || []),

        // Addon required dimensions
        ...state.aoAddons.reduce(
          (acc, addon) => [...acc, ..._.makeArray(addon.asDimensionsRequired || [])],
          []
        )
      ])
    },

    /**
     * Adjusts non-overridden addons when a price adjustment has been observed
     */
    aoAddons(state, parent, children, possibleDimensions, latestSet, changeSet) {
      const addons = _.imm(state.aoAddons)
      const updatedPrice = changeSet[state.refId] && changeSet[state.refId].cost_matrix_rate_net

      if (updatedPrice) {
        for (let index = 0; index < addons.length; index++) {
          const addon = { ...addons[index] }
          addon.targetPriceAdjustment =
            updatedPrice - (addon.targetPrice || updatedPrice) + (addon.targetPriceAdjustment || 0)
          addon.targetPrice = updatedPrice
          addons[index] = addon
        }
      }

      return addons
    },

    /**
     * Refactors oVariations for backwards compat
     */
    oVariations(state) {
      const forwardCompatible = Object.keys(
        state.oVariations?.variationTypes?.[0]?.traits ?? []
      ).length
      const hasOldVariations = !!state.cost_type_is_variation_parent
      if (!hasOldVariations || forwardCompatible) return state.oVariations ?? {}

      // See documetnation at https://www.notion.so/Migration-to-new-variations-selections-items-schema-format-1056db99ef3180ac871ae64e877f5cc1?pvs=4
      const allTypes = _.uniq([
        ...(state.asVariationTypes ?? Object.keys(state.oVariations ?? {}) ?? [])
      ])
      return {
        variationTypes: allTypes.map((type) => {
          const traits = Object.keys(state.oVariationTraits?.[type] ?? {})
          return {
            ...defaultTraitType.variationTypeOptions,
            name: type,
            backgroundSize: 'cover',
            imageShape: 'circle',
            locked: 0,
            hidden: 0,
            desc: '',
            ...(state.oVariationTraits?.[type]?.variationTypeOptions ?? {}),
            traits: traits
              .filter((trait) => trait !== 'variationTypeOptions')
              .map((trait) => ({
                ...defaultSelectionTraits,
                desc: '',
                img: null,
                backgroundSize: 'cover',
                ...state.oVariationTraits[type][trait],
                name: trait
              }))
          }
        }),
        parentId:
          state.oVariations?.parentId ?? state.variation_parent_cost_type_id ?? state.cost_type_id,
        items: (state.aoVariationItems ?? []).map((item) => ({
          ...defaultVariationItem,
          ...item,
          uid: item.uid || v4(),
          variations: item.variations ?? {}
        })),
        links: { ...(state.oVariationLinks ?? {}) },
        selectionPrice: {
          rate: state.cost_matrix_rate_net,
          cost: state.cost_matrix_aggregate_cost_net
        },
        selectedItem: {
          ...defaultVariationItem,
          variations:
            (hasOldVariations ? state.oVariations : state.oVariations?.selectedItem?.variations) ??
            {},
          type: 'cost_type',
          id: state.selected_cost_type_id,
          rate:
            state.oVariations?.selectionPrice?.rate ?? // the intermediate refactor used oVariations.selectionPrice as the selection
            state.cost_matrix_rate_net,
          cost: state.oVariations?.selectionPrice?.rate ?? state.cost_matrix_aggregate_cost_net
        },
        // whether thie selection should be given to the estimator or the homeowner
        // if 'company' then hide from homeowner/client
        audience: 'client' // client or company
      }
    },
    cost_type_is_variation_parent(state) {
      return Object.keys(state.oVariations?.variationTypes?.[0]?.traits ?? []).length
    }
  }
}

const viewOptionsDefaulter = (embue = null) => {
  let vo = {}

  if (!embue) return vo
  if (!embue.pres) return vo

  vo.pres = {}

  if (embue.vis) {
    vo.pres.vis = embue.vis ? 1 : 0
  }
  if (embue.price) {
    vo.pres.price = embue.price ? 1 : 0
  }
  if (embue.cost) {
    vo.pres.cost = embue.cost ? 1 : 0
  }
  if (embue.options) {
    vo.pres.options = embue.options ? 1 : 0
  }
  if (embue.dim) {
    vo.pres.dim = embue.dim ? 1 : 0
  }
  if (embue.qty) {
    vo.pres.qty = embue.qty ? 1 : 0
  }

  return vo
}

const inputRequiredDefaulter = (embue) => {
  if (!embue) return {}

  const inputAllowed = {
    required: 0, // 0|1
    type: 'text', // text|color
    id: null, // text|color
    message: '',
    input: {
      text: '',
      data: {},
      setBy: null // null|company|client
    }
  }

  if (!('inputs' in embue)) return {}

  embue.inputs = embue.inputs.map((input) => _.defaultsDeep(input, inputAllowed))

  return embue
}

const getAllVariationPossibilities = (variationItems) => {
  const poss = {}
  variationItems.forEach((item) => {
    Object.keys(item.variations).forEach((vtype) => {
      poss[vtype] = _.uniq([...(poss[vtype] || []), item.variations[vtype]])
    })
  })
  return poss
}

const variationTraitsDefaultMethod = (embue = {}, obj = {}) => {
  const defaultTrait = _.imm(defaultSelectionTraits)
  const defaultType = _.immutable(defaultTraitType)
  const item = _.immutable(obj)
  const traits = _.immutable(item.oVariationTraits) || {}
  const newTraits = { ...(embue || {}) }

  const poss = getAllVariationPossibilities(item.oVariations?.items ?? item.aoVariationItems ?? [])
  Object.keys(poss).forEach((vtype) => {
    const selections = poss[vtype]

    if (!(vtype in newTraits)) {
      newTraits[vtype] = {}
    }

    newTraits[vtype] = {
      ..._.imm(defaultType),
      ...(traits[vtype] || {}),
      ...(newTraits[vtype] || {}),
      variationTypeOptions: {
        ..._.imm(defaultType.variationTypeOptions),
        ...((traits[vtype] && traits[vtype].variationTypeOptions) || {}),
        ...((newTraits[vtype] && newTraits[vtype].variationTypeOptions) || {})
      },

      ...selections.reduce(
        (acc, sel) => ({
          ...acc,
          [sel]: {
            ...defaultTrait,
            ...((traits[vtype] && traits[vtype][sel]) || {}),
            ...((newTraits[vtype] && newTraits[vtype][sel]) || {})
          }
        }),
        {}
      )
    }
  })

  return newTraits
}

/**
 * Function to cache objects based on a cache key and type.
 *
 * @async
 * @param {string} cacheKey - The cache key for the object.
 * @param {string} type - The type of the object.
 * @param id
 * @returns {Promise<Object>} - The cached object or the fetched object if not found in the cache.
 */
const fetchCachedOrResolve = async (cacheKey, type, id, dispatch) => {
  const cachedObject = await dispatch(`${type}/getCacheItem`, { key: cacheKey }, { root: true })
  // If we never found it in the cache, fetch it and add it
  if (cachedObject === null) {
    const { object } = await dispatch(`${type}/resolveObject`, { id }, { root: true })
    dispatch(`${type}/setCacheItem`, { key: cacheKey, value: object }, { root: true })
    return object
  }

  return cachedObject
}

// Designed for a fresh cost type only
const applyMod = (item, mod, defaultMarkup) => {
  const changes = {}
  const useDefaultMarkup =
    !item.company_id ||
    item.cost_type_is_indexed ||
    item.cost_matrix_use_company_markup ||
    item.cost_matrix_markup_net === null ||
    typeof item.cost_matrix_markup_net === 'undefined' ||
    !item.cost_matrix_markup_net

  const markup = useDefaultMarkup ? defaultMarkup : item.cost_matrix_markup_net

  let modLab = mod.mod_labor_net ?? 1
  let modMat = mod.mod_materials_net ?? 1

  if (!item.cost_type_is_indexed) {
    modMat = 1
  }

  if (!item.labor_type_is_indexed) {
    modLab = 1
  }

  const {
    cost_matrix_labor_cost_net,
    cost_matrix_materials_cost_net,
    cost_matrix_aggregate_cost_net,
    cost_matrix_rate_net
  } = getComputedDependants()

  changes.cost_matrix_markup_net = markup
  changes.labor_type_rate_net = item.labor_type_rate_net_index * modLab

  changes.cost_matrix_labor_cost_net_index =
    item.labor_type_rate_net_index * item.cost_type_hours_per_unit
  changes.cost_matrix_labor_cost_net = cost_matrix_labor_cost_net({ ...item, ...changes })

  changes.cost_matrix_materials_cost_net = item.cost_matrix_materials_cost_net_index * modMat
  changes.cost_matrix_materials_cost_net = cost_matrix_materials_cost_net({ ...item, ...changes })

  changes.cost_matrix_aggregate_cost_net = cost_matrix_aggregate_cost_net({ ...item, ...changes })

  changes.cost_matrix_rate_net = cost_matrix_rate_net({ ...item, ...changes })

  changes.mod_id = mod.mod_id
  changes.mod_labor_net = mod.mod_labor_net
  changes.mod_materials_net = mod.mod_materials_net

  return {
    changes: {
      ...changes
    },
    item: {
      ...item,
      ...changes
    }
  }
}

// Designed for an existing cost item
const reMod = (item, newMod, oldMod = {}) => {
  const changes = {}
  const markup = item.cost_matrix_markup_net

  if (item.company_id || !item.cost_type_is_indexed)
    return {
      changes: {},
      item: { ...item }
    }

  const old = {
    mod_labor_net:
      item.cost_type_is_indexed || item.labor_type_is_indexed
        ? item.mod_labor_net || oldMod.mod_labor_net || 1
        : 1,
    mod_materials_net: item.cost_type_is_indexed
      ? item.mod_materials_net || oldMod.mod_materials_net || 1
      : 1,
    mod_id: item.mod_id || oldMod.mod_id || null
  }

  if (!item.labor_type_rate_net_index) {
    changes.labor_type_rate_net_index = item.labor_type_rate_net / old.mod_labor_net
  }

  if (!item.cost_matrix_materials_cost_net_index) {
    changes.cost_matrix_materials_cost_net_index =
      item.cost_matrix_materials_cost_net / old.mod_materials_net
  }

  const { changes: applyChanges } = applyMod({ ...item, ...changes }, newMod, markup)

  return {
    changes: {
      ...changes,
      ...applyChanges
    },
    item: {
      ...item,
      ...changes,
      ...applyChanges
    }
  }
}

const reModNormalized = (set, refId, newMod, oldMod = {}) => {
  const subset = NormalizeUtilities.extractDescendants(set, [refId], true)

  for (let ref in subset) {
    ;({ item: subset[ref] } =
      subset[ref].type === 'assembly' ? { item: subset[ref] } : reMod(subset[ref], newMod, oldMod))
  }

  return subset
}

export default {
  type: 'cost_type',

  inputRequiredDefaulter,

  reMod: reMod,
  applyMod: applyMod,
  reModNormalized,

  defaultVariationItem,
  defaultSelectionTraits,
  defaultTraitType,
  getAllVariationPossibilities,

  possibleStatuses: ['a', 'h', 'i', 'y', '@'],

  /**
   * Items that can be intermixed with this object type,
   * that could be cached.  Each of these object types
   * will be cleared from cache, when clearning assemblies from cache.
   * @type {[string,string,string]}
   */
  cachedTypes: ['cost_item'],

  fields: {
    /**
     * For version control
     */
    cost_type_hash: {
      type: 'string',
      save: false,
      reload: true,
      trackChanges: false
    },
    cost_type_current_hash: {
      type: 'string',
      save: false,
      reload: false,
      trackChanges: false
    },
    /**
     * Saved settings for this item. DO NOT put state flags here. For example do not
     * put itemIsPaid or itemAssignedTo or something because those will not be reset
     * when the item is used or re-used across other quotes. This is not reset in resetFlags
     * calls. Use a different object for that if necessary and make sure it is reset
     * in resetFlags
     */
    oMeta: {
      type: 'object',
      save: true,
      trackChanges: true,
      deep: true,
      defaultSetting: false,
      default: () => ({})
    },
    /**
     * We price the floor by square foot (cost_matrix_materials_cost_net and
     * unit_of_measure_id), but we purchase by box and there are 30.46 square feet
     * per box then cost_type_materials_purchase_qty_per_unit = 30.46 based on
     * unit_of_measure_id and then the purchase_unit_of_measure_id = 'box',
     * divide cost_item_qty_net by cost_type_materials_purchase_qty_per_unit, to get
     * Number of boxes
     */
    cost_type_materials_purchase_qty_per_unit: {
      type: 'float',
      save: true,
      trackChanges: true,
      defaultSetting: false,
      default: null
    },
    cost_type_materials_purchase_price_net: {
      type: 'float',
      save: false,
      trackChanges: false,
      defaultSetting: false,
      default: null
    },
    cost_type_manufacturer_name: {
      type: 'string',
      save: true,
      default: null
    },
    cost_type_sku: {
      type: 'string',
      save: true,
      default: null
    },
    currency_id: {
      type: 'string',
      save: false,
      trackChanges: false,
      mapTo: 'currency',
      defaultSetting: false
    },
    currency_iso: {
      type: 'string',
      save: false,
      trackChanges: false,
      mapTo: 'currency',
      defaultSetting: false
    },
    company_name: {
      type: 'string',
      save: false,
      trackChanges: false
    },
    company_city: {
      type: 'string',
      save: false,
      trackChanges: false
    },
    province_id: {
      type: 'string',
      save: false,
      trackChanges: false,
      mapTo: 'province'
    },
    country_id: {
      type: 'string',
      save: false,
      trackChanges: false,
      mapTo: 'country'
    },
    live_price_country: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      save: true,
      trackChanges: true
    },
    purchase_unit_of_measure_id: {
      type: 'string',
      save: true,
      trackChanges: true,
      mapTo: 'unit_of_measure',
      defaultSetting: false,
      default: 40
    },
    cost_item_schedule_end_date: {
      type: 'string',
      save: true,
      trackChanges: true
    },
    cost_item_schedule_start_date: {
      type: 'string',
      save: true,
      trackChanges: true
    },
    purchase_unit_of_measure_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      defaultSetting: false,
      default: 'box'
    },
    purchase_unit_of_measure_abbr: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      defaultSetting: false,
      default: 'box'
    },
    cost_type_weight_per_purchase_unit_net: {
      type: 'float',
      save: true,
      default: null
    },
    weight_unit_of_measure_id: {
      type: 'string',
      save: true,
      trackChanges: true,
      mapTo: 'unit_of_measure',
      defaultSetting: false
    },
    weight_unit_of_measure_abbr: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      defaultSetting: false
    },
    cost_type_material_waste_factor_net: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false,
      defaultSetting: false,
      default: 0
    },
    dimension_measure_type: {
      type: 'string',
      default: null, // length|area|volume|count
      defaultSetting: true
    },
    files_count: {
      type: 'int',
      save: false,
      format: false
    },
    mod_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'mod',
      save: false,
      trackChanges: false
    },
    cost_type_qty_equation: {
      type: 'string',
      filter: false,
      trackChanges: true,
      title: 'Quantity equation',
      save: true,
      default: ''
    },
    cost_type_is_highlighted_for_production: {
      type: 'int',
      filter: true,
      trackChanges: true,
      save: true,
      default: 0
    },
    cost_type_is_highlighted_for_estimating: {
      type: 'int',
      filter: true,
      trackChanges: true,
      save: true,
      default: 0
    },
    mod_labor_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      save: false,
      trackChanges: false
    },
    mod_materials_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      save: false,
      trackChanges: false
    },
    mod_equipment_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false,
      save: false,
      trackChanges: false
    },
    unit_of_measure_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      defaultSetting: true
    },
    unit_of_measure_abbr: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      defaultSetting: true
    },
    cost_matrix_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'cost_matrix',
      trackChanges: false,
      reload: false
    },
    cost_matrix_type: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    unit_of_measure_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'unit_of_measure',
      default: 'count',
      defaultSetting: true
    },
    cost_matrix_time_created: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      save: false,
      trackChanges: false
    },
    cost_matrix_use_company_markup: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_matrix_is_custom: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      save: false,
      trackChanges: false
    },
    cost_matrix_is_override: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      save: false,
      trackChanges: false
    },
    cost_type_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    parent_cost_type_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_name: {
      type: 'string',
      filter: true,
      format: 'text',
      mapTo: false,
      trackChanges: true,
      defaultSetting: false,
      validate: {
        required: true
      }
    },
    cost_type_name_full: {
      type: 'string',
      filter: true,
      format: 'text',
      mapTo: false,
      trackChanges: false,
      defaultSetting: false
    },
    cost_type_desc: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_desc_full: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_production_notes: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_is_variance: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      default: 0
    },
    cost_type_is_fee: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      default: 0
    },
    cost_type_time_created: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      trackChanges: false,
      save: false,
      reload: true
    },
    cost_type_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
      default: 'a'
    },
    cost_type_hours_per_unit: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false
    },
    labor_type_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: 'labor_type',
      defaultSetting: true
    },
    labor_type_is_indexed: {
      type: 'int',
      filter: true,
      format: false,
      reload: true,
      default: 0
    },
    cost_type_is_indexed: {
      type: 'int',
      filter: true,
      format: false,
      reload: true,
      default: 0
    },
    cost_type_is_custom: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      default: 1,
      trackChanges: false,
      save: true,
      reload: false
    },
    cost_type_is_override: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      save: true,
      reload: false
    },
    cost_type_time_last_modified: {
      type: 'float',
      filter: true,
      format: 'datetime',
      mapTo: false,
      save: false,
      trackChanges: false
    },
    labor_type_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      defaultSetting: true
    },
    cost_matrix_is_restorable: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    cost_type_is_child: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    labor_type_rate_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false
    },
    labor_type_rate_net_index: {
      type: 'float',
      filter: false,
      format: 'currency',
      mapTo: false
    },
    cost_matrix_labor_cost_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    cost_matrix_materials_cost_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    cost_matrix_aggregate_cost_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    company_default_markup: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      trackChanges: false
    },
    cost_matrix_markup_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    cost_matrix_rate_net: {
      type: 'float',
      filter: true,
      format: 'currency',
      mapTo: false
    },
    aoChildren: {
      type: 'array',
      filter: true,
      format: false,
      mapTo: (child) => child.type,
      save: false,
      trackChanges: false
    },
    aoFiles: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: 'file',
      deep: false,
      autoPartialSave: true
    },
    image_urls: {
      type: 'string',
      filter: false,
      format: false,
      deep: false,
      normalize: false
    },
    aoImages: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: 'file',
      deep: false,
      normalize: false
    },
    aoProperties: {
      type: 'array',
      filter: false,
      format: false
    },
    cost_type_is_parent: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      trackChanges: false,
      saveChanges: true
    },
    cost_type_is_variation_parent: {
      type: 'int',
      default: 0,
      filter: true,
      trackChanges: true,
      saveChanges: true
    },
    /** deprecated **/
    cost_type_is_variation_none_allowed: {
      type: 'int',
      default: 1,
      filter: true,
      trackChanges: true,
      saveChanges: true
    },
    /** deprecated **/
    asVariationTypes: {
      type: 'array',
      trackChanges: true,
      saveChanges: true,
      default: () => []
    },
    /** deprecated **/
    aoVariationItems: {
      type: 'array',
      trackChanges: false,
      saveChanges: true,
      save: true,
      default: (embue = []) => {
        return embue.map((e) => ({
          ..._.immutable(defaultVariationItem),
          ...e
        }))
      }
    },

    /** deprecated in favor of oMeta.optionGroupName */
    variation_parent_cost_type_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      trackChanges: false
    },
    /** modified, see this new defaulter in the computedDependents **/
    oVariations: {
      type: 'object',
      trackChanges: true,
      saveChanges: true,
      default: () => ({})
    },
    cost_type_is_addon_group: {
      type: 'int',
      filter: true,
      trackChanges: true,
      saveChanges: true,
      default: 0
    },
    /** modified, see this new defaulter in the computedDependents **/
    selected_cost_type_id: {
      type: 'string',
      filter: true,
      trackChanges: true,
      saveChanges: true
    },
    aoPath: {
      type: 'array',
      trackChanges: false,
      save: false
    },
    aoCollections: {
      type: 'array',
      mapTo: false,
      trackChanges: false,
      save: false,
      default: []
    },
    stage_id: {
      mapTo: 'stage',
      default: 120,
      save: true,
      type: 'int',
      defaultSetting: true
    },
    stage_name: {
      type: 'string',
      save: true,
      defaultSetting: true
    },
    trade_type_id: {
      type: 'int',
      save: true,
      mapTo: 'trade_type',
      defaultSetting: true
    },
    trade_type_name: {
      type: 'string',
      save: true,
      defaultSetting: true
    },
    aoStageTasks: {
      type: 'array',
      mapTo: 'task'
    },
    cost_type_minimum_qty_net: {
      type: 'float',
      default: null
    },
    cost_type_minimum_price_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      title: 'Minimum price'
    },
    cost_type_static_materials_cost_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      title: 'Static base cost'
    },
    cost_type_static_labor_cost_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      title: 'Static base cost'
    },
    cost_type_minimum_labor_cost_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      title: 'Minimum labor cost',
      save: true,
      trackChanges: true
    },
    cost_type_minimum_materials_cost_net: {
      type: 'float',
      filter: false,
      format: 'currency',
      title: 'Minimum materials cost'
    },
    aoAssembliesUsingItem: {
      type: 'array',
      mapTo: 'assembly',
      filter: false,
      save: false,
      trackChanges: false,
      reload: true
    },
    cost_type_is_used_in_assembly: {
      type: 'int',
      filter: true,
      save: false,
      reload: true,
      trackChanges: true
    },
    vendor_id: {
      type: 'string',
      filter: true,
      save: true,
      trackChanges: true,
      mapTo: 'vendor'
    },
    cost_type_is_subcontracted: {
      type: 'int',
      filter: true,
      default: 0,
      defaultSetting: true
    },
    cost_type_has_labor: {
      filter: true,
      type: 'int',
      default: 1,
      defaultSetting: true
    },
    cost_type_has_materials: {
      filter: true,
      type: 'int',
      default: 1,
      defaultSetting: true
    },
    /**
     * Changes appearance in the presentation
     * -1 === show in a bundle of other de-emphasized items
     * 0 === normal, show as item
     * +1 === show as item normally but with extra emphasis
     */
    cost_type_emphasis: {
      type: 'int',
      default: 0,
      filter: false,
      reload: false,
      save: true,
      trackChanges: true,
      autoPartialSave: true,
      defaultSetting: false
    },
    aoAddons: {
      type: 'array',
      default: (embue = []) => {
        const defaultUpgrade = _.imm(_.defaultAddon)
        return _.makeArray(embue).map((u) => ({ ...defaultUpgrade, ...u }))
      },
      save: true,
      trackChanges: true,
      deep: true,
      title: 'Available add-ons',
      // Only check
      isChanged: (oldSet = [], newSet = []) => {
        const newPared = (newSet || []).map(
          (addon) => ({
            addonType: addon.addonType,
            key: addon.key,
            embue: addon.embue
          }),
          {}
        )
        const oldPared = (oldSet || []).map(
          (addon) => ({
            addonType: addon.addonType,
            key: addon.key,
            embue: addon.embue
          }),
          {}
        )

        return !_.deepEquals(oldPared, newPared)
      }
    },
    file_ids: {
      save: true,
      type: 'array',
      trackChanges: true,
      normalize: false
    },
    asDimensionsUsed: {
      type: 'array',
      default: [],
      save: false,
      trackChanges: false
    },
    item_count_upgrades: {
      type: 'int',
      default: 0,
      trackChanges: false,
      save: true
    },
    variation_parent_cost_type_id: {
      type: 'string',
      mapTo: 'cost_type',
      save: true,
      trackChanges: false
    },
    oVariationTraits: {
      type: 'object',
      save: true,
      trackChanges: true,
      default: variationTraitsDefaultMethod
    },
    /**
     * DEPRECATED
     * Form: {
     *   '{cost_type_id}': {
     *     '{thisVariationType}': '{thatVariationType}',
     *   },
     * }
     */
    oVariationLinks: {
      type: 'object',
      deep: true,
      save: true,
      trackChanges: true
    },
    /**
     * Form: {
     *   'Sale!': {
     *     'color': null,
     *     'shape': null,
     *   },
     * }
     */
    oTag: {
      type: 'object',
      deep: true,
      save: true,
      trackChanges: true,
      default(embue) {
        return Object.keys(embue || {}).reduce(
          (acc, tag) => ({
            ...acc,
            [tag]: {
              color: null,
              shape: null,
              ...(acc[tag] || {}),
              ...embue[tag]
            }
          }),
          {}
        )
      }
    },
    tax_id: {
      type: 'string',
      save: true,
      default: null,
      mapTo: 'tax'
    },
    oTaxSettings: {
      type: 'object',
      save: true,
      trackChanges: true,
      // Important: This acts as an explicit field
      // to set tax details explicitly. If not explicitly set
      // here this item should adopt the tax settings from the project
      // which is the default behaviour. In order to enable thta
      // as the default behavioru this has to return a blank item as default
      default: () => ({})
    },

    // A place for the customer to put in selections data, or for the contractor
    // to get some sort of input on an item from their client
    oInputRequired: {
      type: 'object',
      save: true,
      trackChanges: true,
      default: (embue = null) => inputRequiredDefaulter(embue)
    },
    oViewOptions: {
      type: 'object',
      save: true,
      trackChanges: true,
      default: (embue = null) => viewOptionsDefaulter(embue)
    },
    live_price_reference: {
      type: 'string',
      save: true,
      trackChanges: true,
      default: null
    },
    csi_code_id: {
      type: 'int',
      mapTo: 'csi_code',
      save: true
    },
    cost_type_budget_code: {
      type: 'string',
      save: true
    },
    cost_type_is_task: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      reload: true,
      default: () => 0
    },
    asSharedCompanies: {
      type: 'array',
      trackChanges: true,
      saveChanges: true,
      default: () => []
    }
  },

  generateVueActions() {
    return {
      ...QuoteAddons,
      async reModNormalized(options, { set, refId, newMod, oldMod = {} }) {
        return reModNormalized(set, refId, newMod, oldMod)
      },
      async reMod(options, { item, newMod, oldMod = {} }) {
        return reMod(item, newMod, oldMod)
      },
      async saveFromCostItem({ dispatch, rootState }, args) {
        const {
          refId,
          store = 'Quote',
          normalized = rootState[store].normalized,
          asSuper = false,
          asNew = false,
          quiet = false,
          force = false
        } = args

        const isSuper = asSuper && asSuper === true && rootState.session.user.user_is_super_user

        const { key: queueKey } = await dispatch(`${store}/queueRequest`, {}, { root: true })

        let savePayload = {}

        const denormalized = await dispatch(
          `${store}/denormalize`,
          { rootRef: refId, set: normalized },
          { root: true }
        )
        const type = denormalized.type

        try {
          // Guess stage if not provided
          if (!denormalized.stage_id) {
            const stages = await dispatch('getSuggestedStages', {
              normalized,
              refId
            })
            denormalized.stage_id = stages[0]?.stage_id ?? null
          }

          let { object: reset } = await dispatch('resetFlags', { object: denormalized })

          const idChanged =
            asNew ||
            !denormalized.cost_type_id ||
            !denormalized.company_id ||
            denormalized.cost_type_status === '@'

          reset = {
            ...reset,

            cost_type_id: asNew ? null : denormalized.cost_type_id,
            cost_matrix_id: asNew ? null : denormalized.cost_matrix_id,
            cost_type_default_qty: denormalized.cost_item_qty_net_base,

            type: 'cost_type'
          }

          const { overrides, scope } = await dispatch('globalSaveCheck', isSuper)

          reset = {
            ...reset,
            ...overrides
          }

          // Release placeholder so we can go to our save
          try {
            savePayload = await dispatch('save', {
              scope: scope,
              go: false,
              force: true,
              alert: false,
              queue: false, // we already waited above ^^
              selected: [reset],
              except: [
                // cannot be edited from here, so don't use potentially bad values to save
                // 'parent_cost_type_id',
                'variation_parent_cost_type_id',
                'variation_parent_cost_type_name',
                'oVariations',
                'cost_type_is_variation_parent',
                'cost_type_is_variation_none_allowed',
                'cost_type_status'
              ]
            })
          } catch (e) {
            if (e.message.includes('already') && force) {
              const newName = `${reset.cost_type_name} COPY ${Date.now()}`
              dispatch(
                `alert`,
                {
                  message: `There is already an item with that name, so we will call this one ${newName} for now. Continuing...`
                },
                { root: true }
              )
              // change name
              await dispatch(
                `${store}/field`,
                {
                  changes: {
                    [refId]: {
                      cost_type_name: newName
                    }
                  },
                  explicit: true
                },
                { root: true }
              )

              reset.cost_type_name = newName

              savePayload = await dispatch('save', {
                scope: scope,
                go: false,
                force: true,
                alert: false,
                queue: false, // we already waited above ^^
                selected: [reset],
                except: [
                  // cannot be edited from here, so don't use potentially bad values to save
                  // 'parent_cost_type_id',
                  'variation_parent_cost_type_id',
                  'variation_parent_cost_type_name',
                  'oVariations',
                  'cost_type_is_variation_parent',
                  'cost_type_is_variation_none_allowed',
                  'cost_type_status'
                ]
              })
            } else throw e
          }

          const { object } = savePayload

          // update fields with changed values after save, like ID
          await dispatch(
            `${store}/field`,
            {
              refId,
              changes: {
                cost_type_id: object.cost_type_id,
                cost_type_status: object.cost_type_status,
                company_id: object.company_id,
                parent_cost_type_id: object.parent_cost_type_id,
                cost_type_hash: object.cost_type_hash
              },
              explicit: true,
              immediate: true,
              queue: false,
              skipAudit: true,
              skipLocalAudit: true
            },
            { root: true }
          )
          _.throttle(() => dispatch(`${store}/getHashes`, {}, { root: true }), { delay: 1000 })

          // If saving a variation item as a new item
          // we also have to save all its variations
          // @todo this should be done in variations editor already
          // if (asNew && object.aoVariationItems.length) {
          //   const variationObject = denormalized
          //   variationObject.oVariations = {}
          //   const variations = variationObject.aoVariationItems
          //
          //   // remove original ids from variations so they can be saved as new
          //   // and change names to match the current parent name
          //   variations.forEach((variationItem, idx) => {
          //     variationObject.aoVariationItems[idx].id = ''
          //     if (variations[idx].name && variationObject.variation_parent_cost_type_name) {
          //       const names = variations[idx].name.split(',')
          //
          //       // did not change the name from the global item
          //       if (names[0] === variationObject.variation_parent_cost_type_name) {
          //         variationObject.variation_parent_cost_type_name = `${variationObject.variation_parent_cost_type_name} - ${variationObject.company_id}`
          //         variationObject.cost_type_name = variationObject.variation_parent_cost_type_name
          //       }
          //
          //       names[0] = variationObject.variation_parent_cost_type_name
          //       variationObject.aoVariationItems[idx].name = names.join(',')
          //     }
          //   })
          //
          //   // save the variations
          //   await dispatch(
          //     'CostType/saveVariations',
          //     {
          //       variationItems: variationObject.aoVariationItems,
          //       object: variationObject
          //     },
          //     { root: true }
          //   )
          // }

          // If we are changing the ID, we should see which assembly this is contained in, and save that too
          if (idChanged) {
            const parent = await dispatch(
              'Assembly/getClosestSavedParent',
              {
                refId,
                store
              },
              { root: true }
            )

            const assemblyName = parent?.assembly_name

            if (
              parent &&
              (await dispatch(
                'modal/asyncConfirm',
                {
                  message: `Would you like to save ${assemblyName}, so it uses your new saved item next time?`
                },
                { root: true }
              ))
            ) {
              // save the container assembly as well
              await dispatch(
                'Assembly/saveFromAssembly',
                {
                  refId: parent.refId,
                  store,
                  queue: false
                },
                { root: true }
              )
            }
          }

          if (!quiet) {
            dispatch(
              'alert',
              {
                message:
                  'You successfully saved line item.  Now it can be used later in another quote.'
              },
              { root: true }
            )
          }

          dispatch('clearCache', { type: 'cost_type' })
        } catch (e) {
          console.log('emitting ', `error-${type}`)

          const { userMessage = 'Could not save. Please try again.' } = e
          dispatch(
            'alert',
            {
              message: userMessage,
              error: true,
              timeout: 6000
            },
            { root: true }
          )

          throw e
        } finally {
          dispatch(`${store}/queueNext`, { key: queueKey }, { root: true })
        }

        return savePayload
      },
      async newCategory({ dispatch, rootState }) {
        const name = await dispatch(
          'modal/prompt',
          {
            message: 'What would you like to name the category?'
          },
          { root: true }
        )

        if (!name) return null

        let cat = null

        try {
          const { object } = await dispatch('save', {
            object: {
              cost_type_is_parent: true,
              parent_cost_type_id: null,
              cost_type_name: name,
              company_id: rootState.session.scope.company || 'NULL'
            },
            alert: false,
            go: false
          })

          cat = object
        } catch (e) {
          dispatch(
            'alert',
            {
              message:
                e.userMessage || e.message || 'Could not save that category now, try again later.',
              error: true
            },
            { root: true }
          )
          return null
        }

        return cat
      },
      async getDefaultLivePriceItem({ dispatch }, { livePriceRef, company, zipcode }) {
        const { payload: livePriceItem } = await dispatch(
          'ajax',
          {
            path: 'live_price/fetchLivePriceItem',
            data: {
              live_price_reference: livePriceRef,
              zipcode
            }
          },
          { root: true }
        )

        if (!livePriceItem || !Object.keys(livePriceItem).length) {
          dispatch(
            'alert',
            {
              error: true,
              message: `Live price not found for ${livePriceRef}, skipping addon`
            },
            { root: true }
          )
          return false
        }

        let { object: defaultCostType } = await dispatch(
          'CostType/buildDefaultObject',
          {},
          { root: true }
        )
        const rate = livePriceItem.material_rate * (company.company_default_markup || 1)
        defaultCostType = {
          ...defaultCostType,
          cost_type_name: livePriceItem.name,
          cost_type_desc: livePriceItem.description,
          cost_type_hours_per_unit: livePriceItem.hours_per_unit,
          cost_type_has_material: 1,
          cost_type_has_labor: 0,
          aoImages: livePriceItem.aoImages,
          unit_of_measure_id: livePriceItem.unit_of_measure_id,
          cost_matrix_materials_cost_net: livePriceItem.material_rate,
          cost_matrix_materials_cost_net_index: livePriceItem.material_rate,
          cost_matrix_labor_cost_net: 0,
          cost_matrix_aggregate_cost_net: livePriceItem.material_rate,
          cost_matrix_aggregate_cost_net_index: livePriceItem.material_rate,
          cost_matrix_use_company_markup: 1,
          cost_matrix_markup_net: company.company_default_markup || 1,
          cost_matrix_rate_net: rate,
          cost_matrix_rate_net_index: rate,
          unit_of_measure_abbr: livePriceItem.unit_of_measure_abbr,
          live_price_reference: livePriceItem.live_price_reference
        }

        return defaultCostType
      },
      async getSuggestedStages({ dispatch, state }, payload) {
        const { normalized = state.normalized, refId, embue = {} } = payload

        const object = { ..._.immutable(normalized[refId]), ...embue }

        const { sorted } = await dispatch(
          'Stage/getSuggestedStage',
          {
            itemName: object.cost_type_name,
            itemDesc: object.cost_type_desc,
            parentName:
              object.parentRefId && object.parentRefId in normalized
                ? normalized[object.parentRefId].assembly_name ||
                  normalized[object.parentRefId].quote_name
                : ''
          },
          { root: true }
        )

        return [...sorted].slice(0, 5)
      },

      async getSuggestedDimensions({ dispatch, state }, payload) {
        const { normalized = state.normalized, refId, embue = {} } = payload

        const object = { ..._.immutable(normalized[refId]), ...embue }

        const { dimensions: list } = await dispatch(
          'Dimension/getSuggestedDimensions',
          {
            object: {},
            itemName: object.cost_type_name,
            itemDesc: object.cost_type_desc,
            parentName: '',
            limit: 3
          },
          { root: true }
        )

        return list
      },

      /**
       * See CostItem.resetFlags
       * @param dispatch
       * @param payload
       * @returns {Promise<*>}
       */
      async resetFlags({ dispatch }, payload = {}) {
        const { store = 'CostType' } = payload
        return dispatch(
          'CostItem/resetFlags',
          {
            ...payload,
            store
          },
          { root: true }
        )
      },

      /**
       * Get list of variations
       * @param dispatch
       * @param payload
       * @returns {Promise<*>}
       */
      async getVariationItems({ dispatch }, payload) {
        return dispatch('getVariants', payload)
      },
      async getVariants({ dispatch, rootState }, { id, zipcode: rzip = null }) {
        let zipcode = rzip ?? rootState.session.company?.company_postal ?? 90210
        const { set } = await dispatch('ajax', {
          path: `cost_type/getVariants/${id}`,
          data: { zipcode }
        })
        return set
      },

      /**
       * Get list of variations for multiple items
       * @param dispatch
       * @param ids
       * @returns {Promise<{}>}
       */
      async getMultipleVariationItems({ dispatch }, { ids }) {
        const { object } = await dispatch('ajax', {
          path: `cost_type/getVariationItemsMultiple/${ids.join(',')}`
        })
        return object
      },

      /**
       *
       * @param dispatch
       * @param payload
       * @returns {Promise<void>}
       */
      async saveVariations({ dispatch }, payload = {}) {
        const {
          variationItems,
          /**
           * Parent item
           */
          object,
          asSuper = false,
          progress = () => {}
        } = payload

        const overrides = await dispatch('globalSaveCheck', asSuper)

        // Update parent
        const parentInitial = {
          ...object,
          type: 'cost_type',
          ...overrides.overrides
        }
        const { set: initialParentSet } = await dispatch('partialUpdate', {
          selected: [parentInitial],
          alert: false,
          scope: overrides.scope
        })
        progress(0.1)

        if (!initialParentSet.length || !initialParentSet[0].cost_type_id) {
          throw new UserError({
            userMessage: 'Could not save parent... The parent object must be saved first.'
          })
        }
        const parent = initialParentSet[0]
        // just in case make sure the parent is not being updated
        const variations = variationItems.filter((i) => i.id != parent.cost_type_id)

        const { object: defaultObject } = await dispatch('buildDefaultObject')

        // filter all
        progress(0.2)

        // Do 20 at a time
        const chunks = _.chunk(variations, 20)

        const audited = []

        await _.waterfall(
          chunks.map((chunkSet, index) => async () => {
            let savedVi = []
            const ids = chunkSet.filter((vi) => !!vi.id).map((vi) => vi.id)
            if (ids.length) {
              const { set } = await dispatch('filter', {
                filters: {
                  cost_type_id: ids.join('||')
                }
              })
              savedVi = set
            }

            const { set: nameSet } = await dispatch('filter', {
              filters: {
                cost_type_name: chunkSet.map((vi) => vi.name).join('||')
              }
            })

            const costTypes = await Promise.all(
              chunkSet.map(async (vi) => {
                const foundById = vi.id
                  ? savedVi.find((svi) => svi.cost_type_id === vi.id) || {}
                  : {}

                const foundByName = nameSet.find((ct) => ct.cost_type_name === vi.name) || {}

                const found = Object.keys(foundById).length ? foundById : foundByName

                const ct = {
                  ...defaultObject,

                  // Addons should be empty, if found has some, let them be,
                  // but they won't get added in when chosen as a selection
                  aoAddons: [],

                  // If this is a saved version, let it retain its equations etc,
                  // these aren't loaded in when this variation is selected so it doesn't
                  // matter anyway, since the equation and related values are taken
                  // from the variation parent
                  // If FOUND has any of these, they will get overridden when it is mixed in below
                  cost_type_qty_equation: parent.cost_type_qty_equation,
                  parent_cost_type_id: parent.parent_cost_type_id,
                  asDimensionsUsed: parent.asDimensionsUsed,
                  asRequiredDimensions: parent.asRequiredDimensions,

                  ...found,

                  // All of the following fields CAN be distinct between different
                  // selections/variations. So, use th saved version if found, and if
                  // not, then defaul to the parent's value for each of these.
                  // If these are NOT set in the saved version, they should draw form the parent.
                  stage_id: found.stage_id || parent.stage_id,
                  stage_name: found.stage_name || parent.stage_name,
                  cost_type_is_indexed: found.cost_type_is_indexed || parent.cost_type_is_indexed,
                  labor_type_id: vi.labor_type_id || found.labor_type_id || parent.labor_type_id,
                  labor_type_name: found.labor_type_name || parent.labor_type_name,
                  labor_type_rate_net: found.labor_type_rate_net || parent.labor_type_rate_net,
                  labor_type_rate_net_index:
                    found.labor_type_rate_net_index || parent.labor_type_rate_net_index,
                  labor_type_is_indexed:
                    found.labor_type_is_indexed || parent.labor_type_is_indexed,
                  trade_type_id: found.trade_type_id || parent.trade_type_id,
                  trade_type_name: found.trade_type_name || parent.trade_type_name,
                  vendor_id: found.vendor_id || parent.vendor_id,
                  vendor_name: found.vendor_name || parent.vendor_name,

                  // If it is found already, use the existing ID
                  cost_type_id: found.cost_type_id || vi.id,

                  // These are fields that gets staged for the user, and the user
                  // can edit. So save these as received from the variation item
                  variation_parent_cost_type_id:
                    vi.id !== parent.cost_type_id ? parent.cost_type_id : '',
                  cost_type_name: vi.name,
                  cost_type_desc: vi.desc,
                  file_ids: vi.file_ids,
                  oVariations: vi.variations,
                  oTag: vi.tag,
                  cost_type_is_variation_parent: 0,
                  cost_type_is_parent: 0,
                  unit_of_measure_id: parent.unit_of_measure_id,
                  unit_of_measure_abbr: parent.unit_of_measure_abbr,
                  unit_of_measure_name: parent.unit_of_measure_name,
                  cost_type_sku: vi.sku,
                  live_price_reference: vi.live_price_reference,
                  aoImages: vi.aoImages,

                  ...(vi.id
                    ? {}
                    : vi.live_price_reference
                      ? { cost_type_has_labor: 1, cost_type_has_materials: 1 }
                      : { cost_type_has_labor: 0, cost_type_has_materials: 1 }),

                  ...(!vi.id && vi.labor_type_id && vi.labor_type_id.startsWith('ac-')
                    ? {
                        cost_type_hours_per_unit: 0,
                        cost_matrix_labor_cost_net: 0,
                        cost_matrix_aggregate_cost_net: vi.cost
                      }
                    : {}),

                  // cost_matrix_markup_net: defaultMarkup,
                  cost_matrix_markup_net: vi.rate / vi.cost,

                  ...overrides.overrides,

                  type: 'cost_type'
                }

                let priced = {
                  ...ct
                }
                if (typeof vi.cost !== 'undefined' && (!_.eq(vi.cost, 0) || vi.cost >= 0)) {
                  const { changes: costChanges } = await dispatch('setAggregateCost', {
                    object: ct,
                    aggregateCost: vi.cost,
                    auditLocal: true
                  })
                  priced = {
                    ...priced,
                    ...costChanges,
                    cost_matrix_markup_net: vi.rate / vi.cost
                  }
                }
                return priced
              })
            )

            await dispatch('partialUpdate', {
              selected: costTypes,
              alert: false,
              scope: overrides.scope
            })

            audited[index] = costTypes
            progress(0.3 + _.divide(index, chunks.length - 1) * 0.65)
          })
        )

        const costTypes = audited.reduce((acc, subset) => [...acc, ...subset], [])

        const savedVariationItems = await dispatch('mapCostTypesToVariationItems', costTypes)
        progress(1)

        return { set: savedVariationItems }
      },

      getDefaultVariationItem() {
        return defaultVariationItem
      },

      mapCostTypesToVariationItems(state, set) {
        return set.map((ct) => ({
          ...defaultVariationItem,
          name: ct.cost_type_name,
          cost: ct.cost_matrix_aggregate_cost_net,
          rate: ct.cost_matrix_rate_net,
          desc: ct.cost_type_desc,
          file_ids: ct.file_ids,
          variations: ct.oVariations,
          id: ct.cost_type_id,
          live_price_reference: ct.live_price_reference,
          aoImages: ct.aoImages,
          labor_type_id: ct.labor_type_id
        }))
      },

      mapAutoCostToVariationItems({ rootState }, set) {
        const variations = []
        const markup =
          (rootState.session &&
            rootState.session.company &&
            rootState.session.company.company_default_markup) ||
          1.43

        set.forEach((ct) => {
          const lab = ct.labor_type_rate_net_index * ct.cost_type_hours_per_unit
          const mat = ct.cost_matrix_materials_cost_net_index
          const net = lab + mat

          variations.push({
            ...defaultVariationItem,
            name: ct.cost_type_name,
            cost: net,
            rate: net * markup,
            desc: ct.cost_type_desc,
            live_price_reference: ct.live_price_reference,
            aoImages: ct.aoImages,
            labor_type_id: ct.labor_type_id
          })
        })
        return variations
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float markup
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setVariationItems({ dispatch, rootState }, payload) {
        const {
          variationItems,
          refId = false,
          store = 'CostType',
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const defaultVariations = _.zipObject(
          object.asVariationTypes,
          Array(object.asVariationTypes.length).fill('')
        )
        // make sure each variation item has the new variatoin type
        const varItems = _.immutable(variationItems).map((item) => ({
          ...defaultVariationItem,
          ...(item || {}),
          variations: {
            ...defaultVariations,
            ...(item.variations || {})
          }
        }))

        const explicitChanges = {
          aoVariationItems: varItems
        }
        let changes = {
          ...explicitChanges
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float markup
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setVariationTypes({ dispatch, rootState }, payload) {
        const {
          variationTypes,
          refId = false,
          store = 'CostType',
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const explicitChanges = {
          asVariationTypes: variationTypes
        }
        let changes = {
          ...explicitChanges
        }

        const defaultVariations = _.zipObject(variationTypes, Array(variationTypes.length).fill(''))
        // make sure each variation item has the new variatoin type
        const varItems = _.immutable(object.aoVariationItems).map((item) => ({
          ...item,
          variations: {
            ...defaultVariations,
            ...item.variations
          }
        }))

        changes = {
          ...changes,
          aoVariationItems: varItems
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float markup
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setMarkup({ dispatch }, payload) {
        const { markup, object = {} } = payload

        const target = _.n(markup)
        const explicitChanges = {
          cost_matrix_markup_net: target
        }
        let changes = {
          ...explicitChanges
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float materialsCost
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setMaterialsCost({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostType',
          materialsCost: cost,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const materials = _.n(cost)

        const explicitChanges = {
          cost_matrix_materials_cost_net: materials
        }
        const changes = {
          ...explicitChanges
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float laborCost
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setLaborCost({ dispatch, rootState }, payload) {
        const {
          refId = null,
          store = 'CostType',
          laborCost,
          object = _.imm(rootState[store].normalized[refId]),
          useCache = false
        } = payload

        const newLaborPer = _.n(laborCost)

        let changes = {}

        if (!object.labor_type_id) {
          changes.labor_type_id = await dispatch('LaborType/getDefaultId', {}, { root: true })
        }

        const laborId = object.labor_type_id || changes.labor_type_id
        if (laborId && !object.labor_type_rate_net) {
          const cacheKey = `cost-type-labor-type-${laborId}`
          let laborType = {}

          if (useCache) {
            laborType = await fetchCachedOrResolve(cacheKey, 'LaborType', laborId, dispatch)
          } else {
            // Pull object from resolveObject, call it resolvedLaborType (Because it's Friday and Sera hate's JS)
            const { object: resolvedLaborType } = await dispatch(
              'LaborType/resolveObject',
              { laborId },
              { root: true }
            )
            // No need to cache it though since we don't wanna
            laborType = resolvedLaborType
          }

          changes.labor_type_rate_net = _.n(laborType.labor_type_rate_net)
          changes.labor_type_rate_net_index = _.n(laborType.labor_type_rate_net_index)
          changes.labor_type_name = laborType.labor_type_name
          changes.labor_type_is_indexed = laborType.labor_type_is_indexed
        }

        // Guess new hours
        const laborTypeRate = _.n(object.labor_type_rate_net) || changes.labor_type_rate_net || 75
        const hours = newLaborPer / laborTypeRate
        const explicitChanges = {
          cost_matrix_labor_cost_net: newLaborPer
        }
        changes = {
          ...changes,
          ...explicitChanges,
          labor_type_rate_net: laborTypeRate
        }

        // If setting this to anything but 0, the item cannot be subcontracted
        if (!_.eq(newLaborPer, 0) || !+object.cost_type_is_subcontracted) {
          changes.cost_type_is_subcontracted = 0
          changes.cost_type_hours_per_unit = hours
        }

        // else if (!(+object.cost_type_is_subcontracted)) {
        //   changes.cost_type_hours_per_unit = hours;
        // }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       * Smart guess on avg labor cost
       * @param dispatch
       * @returns {Promise<*>}
       */
      async getAverageLaborPercentage({ dispatch }) {
        if (!laborPerc) {
          const { aggregations } = await _.throttle(
            () => {
              dispatch(
                'alert',
                {
                  message: 'Guessing materials and labor cost mix..'
                },
                { root: true }
              )

              return dispatch('search', {
                filters: {
                  cost_matrix_labor_cost_net: '>0',
                  cost_type_has_labor: 1,
                  cost_type_is_subcontracted: 0,
                  type: 'cost_type'
                },
                limit: 50,
                order: [
                  ['company_id', 'desc'],
                  ['cost_type_time_created', 'desc']
                ],
                aggregations: {
                  avg_labor: {
                    avg: {
                      field: 'cost_matrix_labor_cost_net'
                    }
                  },
                  avg_total: {
                    avg: {
                      field: 'cost_matrix_aggregate_cost_net'
                    }
                  }
                }
              })
            },
            { key: 'laborperc', delay: 300 }
          )

          laborPerc = _.divide(aggregations.avg_labor.value, aggregations.avg_total.value)
        }

        return laborPerc
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float aggregateCost
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setAggregateCost({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostType',
          aggregateCost: cost,
          GuessLabourPercentage = true,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const agg = _.n(cost)
        const currentAgg = _.n(object.cost_matrix_aggregate_cost_net)
        const hasCosts = !_.eq(currentAgg, 0)
        const currentLabor = _.n(object.cost_matrix_labor_cost_net)
        const isSubcontracted = _.n(object.cost_type_is_subcontracted)
        const hasMaterials = _.n(object.cost_type_has_materials)
        const hasLabor = _.n(object.cost_type_has_labor)

        let laborPercentage = 0
        let labor = 0
        let materials = 0
        if (GuessLabourPercentage) {
          if (hasLabor && hasMaterials && !isSubcontracted && !hasCosts) {
            // Guess percentage
            laborPercentage = await dispatch('getAverageLaborPercentage')
          } else if (hasLabor && hasMaterials && !isSubcontracted && hasCosts) {
            // Calculate percentage
            laborPercentage = _.divide(currentLabor, currentAgg)
          } else if (hasLabor && !hasMaterials && !isSubcontracted) {
            // It is all 100% labor
            laborPercentage = 1
          } else if (isSubcontracted) {
            // Subcontracted costs go entirely into materials
            laborPercentage = 0
          }
          labor = agg * laborPercentage
          materials = agg - labor
        }

        const couldVaryQty =
          _.n(object.cost_type_materials_purchase_qty_per_unit) &&
          _.n(object.cost_type_materials_purchase_qty_per_unit) !== 1
        const couldVaryMinLab = _.n(object.cost_type_minimum_labor_cost_net)
        const couldVaryMinMat = _.n(object.cost_type_minimum_labor_cost_net)

        let message = false
        if (couldVaryQty) {
          message = `The cost could vary relative to what you requested because a \
            purchase quantity was entered, which causes the materials quantity to \
            round up to the nearest ${object.purchase_unit_of_measure_name || 'purchase quantity (ie: box, case etc).'}.
            
            See "Materials options" to change these materials cost options.`
        } else if (couldVaryMinLab && labor < couldVaryMinLab) {
          message = `The labor cost will likely be higher than requested because there is a
            minimum specified of ${_.toNum(couldVaryMinLab, 2)}.
            
            See "Advanced options" to change minimums.`
        } else if (couldVaryMinMat && materials < couldVaryMinMat) {
          message = `The materials cost will likely be higher than requested because there is a
            minimum specified of ${_.toNum(couldVaryMinMat, 2)}.
            
            See "Advanced options" to change minimums.`
        }

        if (message) {
          dispatch(
            'alert',
            {
              message,
              timeout: 10000,
              error: false,
              warning: true
            },
            { root: true }
          )
        }

        const explicitChanges = {
          cost_matrix_aggregate_cost_net: agg
        }
        let changes = {
          ...explicitChanges
        }

        if (!_.eq(laborPercentage, 0, 4) && GuessLabourPercentage) {
          const { changes: laborChanges } = await dispatch('setLaborCost', {
            object,
            laborCost: labor,
            auditLocal: false
          })

          changes = {
            ...changes,
            ...laborChanges
          }
        }

        if (!_.eq(laborPercentage, 1) && GuessLabourPercentage) {
          const { changes: materialsChanges } = await dispatch('setMaterialsCost', {
            object,
            materialsCost: materials,
            auditLocal: false
          })

          changes = {
            ...changes,
            ...materialsChanges
          }
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param bool|int hasMaterials
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setHasMaterials({ dispatch, rootState }, payload) {
        const {
          refId = null,
          store = 'CostType',
          hasMaterials,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const willHave = _.n(hasMaterials)
        const currentlyHas = _.n(object.cost_type_has_materials)
        const hasLabor = _.n(object.cost_type_has_labor)
        const isSubcontracted = _.n(object.cost_type_is_subcontracted)

        const hasChanged = willHave !== currentlyHas
        if (!hasChanged) {
          return dispatch('reportChanges', {
            ...payload,
            changes: {},
            object
          })
        }

        const explicitChanges = {
          cost_type_has_materials: willHave ? 1 : 0
        }
        let changes = {
          ...explicitChanges
        }

        if (!willHave) {
          // Make sure static and minimums are OFF
          changes = {
            ...changes,
            cost_type_static_materials_cost_net: 0,
            cost_type_minimum_materials_cost_net: 0
          }

          if (!hasLabor) {
            // If there is no labor, we need to turn labor on, because
            // there must be either materials or labor costs included in
            // every item.
            const { changes: laborChanges } = await dispatch('setHasLabor', {
              hasLabor: 1,
              object: {
                ...object,
                ...changes
              },
              store
            })
            changes = {
              ...laborChanges,
              ...changes
            }
          }

          if (isSubcontracted) {
            // Subcontracted costs always go into materials,
            // so put the combined vaue there anyway.
            // Labor goes to zero, even though labor costs will be 'included',
            // they are included by the subcontractor, so bundeld with subcontractors expenses (always in materials).
            changes.cost_matrix_materials_cost_net =
              _.n(object.cost_matrix_materials_cost_net) + _.n(object.cost_matrix_labor_cost_net)
            changes.cost_matrix_labor_cost_net = 0
          }
        } else {
          const materialsCostNet =
            object.cost_matrix_materials_cost_net ||
            object.cost_matrix_materials_cost_net_index ||
            0

          changes = {
            ...changes,
            cost_matrix_materials_cost_net: materialsCostNet
          }
        }

        // Fees can only have one or the other
        if (willHave && hasLabor && object.cost_type_is_fee) {
          const { changes: feeLaborChanges } = await dispatch('setHasLabor', {
            hasLabor: 0,
            object: {
              ...object,
              ...changes
            },
            store
          })
          changes = {
            ...feeLaborChanges,
            ...changes
          }
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param int|bool hasLabor
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setHasLabor({ dispatch, rootState }, payload) {
        const {
          refId = null,
          store = 'CostType',
          hasLabor,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const willHave = _.n(hasLabor)
        const currentlyHas = _.n(object.cost_type_has_labor)

        if (currentlyHas === willHave) {
          return dispatch('reportChanges', {
            ...payload,
            changes: {},
            object
          })
        }

        const explicitChanges = {
          cost_type_has_labor: willHave
        }
        let changes = {
          ...explicitChanges
        }

        if (!willHave) {
          const materialsCostNet =
            object.cost_matrix_materials_cost_net ||
            object.cost_matrix_materials_cost_net_index ||
            0

          changes = {
            ...changes,
            cost_type_has_materials: 1,
            cost_matrix_materials_cost_net: materialsCostNet,
            cost_type_minimum_labor_cost_net: 0,
            cost_type_static_labor_cost_net: 0
          }

          if (object.cost_type_is_subcontracted) {
            const { changes: subChanges } = await dispatch('setIsSubcontracted', {
              store,
              object: {
                ...object,
                ...changes
              },
              isSubcontracted: 0,
              auditLocal: false
            })

            changes = {
              ...changes,
              ...subChanges
            }
          }
        } else {
          changes = {
            ...changes,
            cost_type_minimum_labor_cost_net: 0,
            cost_type_static_labor_cost_net: 0,
            cost_matrix_labor_cost_net:
              _.n(object.labor_type_rate_net) * _.n(object.cost_type_hours_per_unit)
          }

          const { changes: aggChanges } = await dispatch('setAggregateCost', {
            store,
            object: {
              ...object,
              ...changes
            },
            aggregateCost:
              changes.cost_matrix_labor_cost_net + object.cost_matrix_materials_cost_net,
            auditLocal: false,
            GuessLabourPercentage: false
          })

          changes = {
            ...changes,
            ...aggChanges
          }
        }

        // Fees can only have one or the other
        if (willHave && object.cost_type_has_materials && object.cost_type_is_fee) {
          const { changes: feeMaterialsChanges } = await dispatch('setHasMaterials', {
            hasMaterials: 0,
            object: {
              ...object,
              ...changes
            },
            store
          })
          changes = {
            ...changes,
            ...feeMaterialsChanges
          }
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       * set the box price of an item as purcahsed from vendor
       * @param dispatch
       * @param rootState
       * @param payload
       * @returns {Promise<*>}
       */
      async setMaterialsPurchasePrice({ dispatch, rootState }, payload) {
        const {
          refId = null,
          store = 'CostType',
          materialsPurchasePrice,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const explicitChanges = {
          cost_type_materials_purchase_price_net: _.n(materialsPurchasePrice),
          cost_matrix_materials_cost_net: _.divide(
            materialsPurchasePrice,
            object.cost_type_materials_purchase_qty_per_unit
          )
        }
        const changes = { ...explicitChanges }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param int isSubcontracted
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setIsSubcontracted({ dispatch, rootState }, payload) {
        const {
          refId = null,
          store = 'CostType',
          isSubcontracted: requestedIs,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const isSubcontracted = !!(requestedIs && !object.cost_type_is_fee) // fees cannot be subed
        const currentlyIs = !!_.n(object.cost_type_is_subcontracted)
        const labor = _.n(object.cost_matrix_labor_cost_net)
        const materials = _.n(object.cost_matrix_materials_cost_net)

        const hasChanged = currentlyIs !== isSubcontracted
        if (!hasChanged) {
          return dispatch('reportChanges', {
            ...payload,
            changes: {},
            object
          })
        }

        const explicitChanges = {
          cost_type_is_subcontracted: isSubcontracted ? 1 : 0
        }
        let changes = {
          ...explicitChanges
        }

        if (isSubcontracted && !currentlyIs && !_.eq(0, labor)) {
          // Transfer labor to materials cost
          const subCost = materials + labor
          const hoursGuess = +object.cost_type_has_labor
            ? (subCost * 0.5) / (_.n(object.labor_type_rate_net) || 75)
            : 0
          const subHours = object.cost_type_hours_per_unit
            ? object.cost_type_hours_per_unit
            : hoursGuess
          changes = {
            ...changes,
            cost_type_has_labor: 1,
            cost_matrix_materials_cost_net: subCost,
            cost_matrix_labor_cost_net: 0,
            cost_type_is_subcontracted: 1,
            cost_type_hours_per_unit: subHours,
            // cost_type_static_labor_cost_net: 0,
            cost_type_static_materials_cost_net:
              _.n(object.cost_type_static_materials_cost_net) +
              _.n(object.cost_type_static_labor_cost_net),
            cost_type_minimum_materials_cost_net: Math.max(
              object.cost_type_minimum_materials_cost_net,
              object.cost_type_minimum_labor_cost_net
            )
            // cost_type_minimum_labor_cost_net: 0,
          }
        } else if (isSubcontracted) {
          changes = {
            ...changes,
            cost_type_has_labor: 1,
            cost_type_minimum_materials_cost_net:
              _.n(object.cost_type_minimum_materials_cost_net) +
              _.n(object.cost_type_minimum_labor_cost_net),
            // cost_type_minimum_labor_cost_net: 0,
            cost_type_static_materials_cost_net:
              _.n(object.cost_type_static_materials_cost_net) +
              _.n(object.cost_type_static_labor_cost_net)
            // cost_type_static_labor_cost_net: 0,
          }
        } else if (!isSubcontracted) {
          // Subtract the future labor cost from the materials cost
          // because the labor cost is going to be computed based on
          // hours * materials
          // if (!hasMaterials) {
          //   const { changes: vendorChanges } = await dispatch('setVendor', {
          //     object,
          //     vendor: null,
          //     store,
          //   });
          //
          //   changes = {
          //     ...changes,
          //     ...vendorChanges,
          //   };
          // }

          // Subtract labor_minimum from materials minimum
          // subtract labor_static from materials static
          const lab = _.n(object.cost_type_hours_per_unit) * _.n(object.labor_type_rate_net)
          changes = {
            ...changes,
            cost_matrix_labor_cost_net: lab,
            cost_matrix_materials_cost_net: Math.max(
              _.n(object.cost_matrix_materials_cost_net) - lab,
              0
            ),
            cost_type_minimum_materials_cost_net: Math.max(
              _.n(object.cost_type_minimum_materials_cost_net) -
                _.n(object.cost_type_minimum_labor_cost_net),
              0
            ),
            cost_type_static_materials_cost_net: Math.max(
              _.n(object.cost_type_static_materials_cost_net) -
                _.n(object.cost_type_static_labor_cost_net),
              0
            )
          }
        }

        if (
          !object.cost_type_has_materials &&
          !changes.cost_type_has_materials &&
          !changes.cost_type_has_labor &&
          !object.cost_type_has_labor
        ) {
          // regardless of the state of is_subcontracted, it still needs to have materials or labor selected
          changes.cost_type_has_labor = isSubcontracted ? 1 : 0
          changes.cost_type_has_materials = !isSubcontracted ? 0 : 1
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float hours
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setHours({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostType',
          hours,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const hoursPer = _.n(hours)

        const explicitChanges = {
          cost_type_hours_per_unit: hoursPer
        }
        const changes = {
          ...explicitChanges
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float price
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setPrice({ dispatch, rootState, rootGetters: gets }, payload) {
        const {
          refId = false,
          store = 'CostType',
          price,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const total = _.n(price)

        /**
         *
         * Given
         * c = cost
         * o = original markup
         * a = markup adjustment percentage
         * p = price
         *
         * c(o + (o - 1)a) = p
         * c(o + ao - a) = p
         * o + ao - a = p/c
         * o(1 + a) - a = p/c
         * o (markup) = (p/c + a) / (1 + a)
         *
         * We use this formula to determine 'o' or original markup if there is
         * an adjustment
         */

        const defaultMarkup = object.cost_matrix_markup_net || gets.defaultMarkup
        const guessedCosts = total / defaultMarkup

        const c = _.n(object.cost_matrix_aggregate_cost_net) || guessedCosts
        const p = total
        const markup = _.divide(p, c)
        const adjustment = _.n(object.cost_item_markup_percentage_adjustment)
        const denom = 1 + adjustment || 1
        let finalMarkup = 0
        if (!_.eq(denom, 0)) {
          finalMarkup = (markup + adjustment) / (1 + adjustment)
        }

        const explicitChanges = {
          cost_matrix_rate_net: total
        }

        let { changes } = await dispatch('setMarkup', {
          object,
          store,
          markup: finalMarkup,
          auditLocal: false
        })

        if (!_.n(object.cost_matrix_aggregate_cost_net)) {
          const { changes: aggChanges } = await dispatch('setAggregateCost', {
            store,
            object,
            aggregateCost: guessedCosts,
            auditLocal: false
          })
          changes = {
            ...changes,
            ...aggChanges
          }
        }

        changes = {
          ...changes,
          ...explicitChanges
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param string|id unitOfMeasure
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setPurchaseUnitOfMeasure({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostType',
          // Id only
          purchaseUnitOfMeasure: id,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const { object: uom } = await dispatch(
          'UnitOfMeasure/resolveObject',
          {
            id
          },
          { root: true }
        )
        const explicitChanges = {
          purchase_unit_of_measure_id: uom.unit_of_measure_id || null,
          purchase_unit_of_measure_name: uom.unit_of_measure_name || null,
          purchase_unit_of_measure_abbr: uom.unit_of_measure_abbr || null
        }
        let changes = {
          ...explicitChanges
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       * @returns {Promise<*>}
       */
      async setWeightUnitOfMeasure({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostType',
          // Id only
          weightUnitOfMeasure: id,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const { object: uom } = await dispatch(
          'UnitOfMeasure/resolveObject',
          {
            id
          },
          { root: true }
        )
        const explicitChanges = {
          weight_unit_of_measure_id: uom.unit_of_measure_id || null,
          weight_unit_of_measure_name: uom.unit_of_measure_name || null,
          weight_unit_of_measure_abbr: uom.unit_of_measure_abbr || null
        }
        let changes = {
          ...explicitChanges
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param string|id unitOfMeasure
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setUnitOfMeasure({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostType',
          // Id only
          unitOfMeasure: id,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        const { object: uom } = await dispatch(
          'UnitOfMeasure/resolveObject',
          {
            id
          },
          { root: true }
        )

        const explicitChanges = {
          unit_of_measure_id: uom.unit_of_measure_id || null,
          unit_of_measure_name: uom.unit_of_measure_name || null,
          unit_of_measure_abbr: uom.unit_of_measure_abbr || null,
          dimension_measure_type: uom.dimension_measure_type || null
        }
        let changes = {
          ...explicitChanges
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param string|id tax
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setIsAddonGroup({ dispatch }, payload) {
        const {
          isAddonGroup: is
          // refId,
          // store,
          // object = _.imm(rootState[store].normalized[refId]),
        } = payload

        let explicitChanges = {
          cost_type_is_addon_group: is
        }

        // Check if this item has any cost information and add it to the variations list
        // if (object.cost_matrix_aggregate_cost_net) {
        //   changes.aoAddons = [
        //     {
        //       id: object.id,
        //       type: 'cost_type',
        //       file_id: object.file_ids?.[0] ?? null,
        //       asDimensionsRequired: object.asDimensionsRequired,
        //       price: object.cost_matrix_rate_net,
        //       equation: null,
        //       desc: object.cost_type_desc,
        //       unit: object.unit_of_measure_id,
        //       markup: object.cost_matrix_markup_net,
        //       targetKey: [object.cost_matrix_rate_net, object.cost_matrix_aggregate_cost_net, object.cost_item_qty_net],
        //     },
        //     ...changes.aoAddons,
        //   ]
        // }

        const changes = {
          ...explicitChanges,
          cost_type_is_variation_parent: 0,
          cost_type_is_fee: 0,
          cost_type_has_labor: 0,
          cost_type_has_materials: 0,
          cost_type_is_subcontracted: 0,
          cost_matrix_materials_cost_net: 0,
          variation_parent_cost_type_name: null
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param string|id tax
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setIsVariationParent({ dispatch, rootState }, payload) {
        const {
          isVariationParent: is,
          refId,
          store,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        let explicitChanges = {
          cost_type_is_variation_parent: is
        }

        if (is && !object.variation_parent_cost_type_name) {
          explicitChanges.variation_parent_cost_type_name =
            object.cost_type_name || 'Choose an option'
        }

        const changes = {
          ...explicitChanges,
          cost_type_is_fee: 0,
          cost_type_is_addon_group: 0
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param string|id tax
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setTax({ dispatch }, payload) {
        const { tax: id } = payload

        const { object: tax } = await dispatch('Tax/resolveObject', { id }, { root: true })

        let explicitChanges = {
          tax_id: null,
          tax_name: '',
          oTaxSettings: {}
        }

        if (tax && tax.tax_id) {
          explicitChanges = {
            tax_id: tax.tax_id,
            tax_name: tax.tax_name,
            oTaxSettings: tax.oTaxSettings
          }
        }

        const changes = {
          ...explicitChanges
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param string|id vendor
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setVendor({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostType',
          vendor: id,
          object = _.imm(rootState[store].normalized[refId]),
          updateVendor = true
        } = payload

        const { object: vendor } = await dispatch('Vendor/resolveObject', { id }, { root: true })
        let explicitChanges = {
          vendor_id: null,
          vendor_name: ''
          // item_is_direct_pay: 0,
        }

        if (vendor && vendor.vendor_id) {
          explicitChanges = {
            vendor_id: vendor.vendor_id,
            vendor_name: vendor.vendor_name
            // item_is_direct_pay: 0,
          }
        }

        const changes = {
          ...explicitChanges
        }
        const r = await dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })

        // Check if we should add the trade type to vendor
        if (updateVendor && object.vendor_id && object.trade_type_id) {
          dispatch(
            'Vendor/addTradeType',
            {
              object: vendor,
              tradeType: object.trade_type_id
            },
            { root: true }
          )
        }

        return r
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param int|string tradeType
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setTradeType({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostType',
          tradeType: id,
          object = _.imm(rootState[store].normalized[refId])
          // suggestLaborType = true,
          // updateVendor = true
        } = payload

        const { object: tradeType } = await dispatch(
          'TradeType/resolveObject',
          { id },
          { root: true }
        )

        const explicitChanges = {
          trade_type_id: tradeType.trade_type_id || null,
          trade_type_name: tradeType.trade_type_name || null
        }
        let changes = {
          ...explicitChanges
        }

        // Confrim change of trade type too, if already set, otherwise just change it
        // if (suggestLaborType
        //   && tradeType.labor_type_id
        //   && (!object.labor_type_id
        //     || (object.labor_type_id
        //       && await dispatch('modal/asyncConfirm', {
        //         message: `The trade type was changed to
        //           <strong class="text-primary">${tradeType.trade_type_name }</strong>, would you also like to
        //           change the labor rate type to the suggested
        //           <strong class="text-primary">${tradeType.labor_type_name || tradeType.labor_type_id} @ ${_.toCurrency(tradeType.labor_type_rate_net)}/hr</strong>?`,
        //       }, { root: true })))) {
        //   changes = {
        //     ...changes,
        //     labor_type_id: tradeType.labor_type_id,
        //     labor_type_name: tradeType.labor_type_name,
        //     labor_type_rate_net: tradeType.labor_type_rate_net,
        //   };
        // }

        // See if we should add this trade type to the vendor
        // if (updateVendor && object.vendor_id && id) {
        //   dispatch('Vendor/addTradeType', {
        //     id: object.vendor_id,
        //     tradeType: id,
        //   }, { root: true });
        // }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param int|string laborType
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setLaborType({ dispatch, rootState, rootGetters: getters }, payload) {
        const {
          refId = false,
          store = 'CostType',
          laborType: id,
          object = _.imm(rootState[store].normalized[refId]),
          useCache = false
        } = payload

        const norm = rootState[store].normalized
        const rootRefId = getNormalizedRootRefId(norm, object.refId)
        let explicitChanges = {}
        let changes = {}

        if (id && AutoCost.isAutoCostLaborTypeId(id)) {
          let ref = id

          dispatch('alert', { message: 'Fetching AutoCost labor rate' }, { root: true })

          const quote = norm[rootRefId]
          const company = rootState.session.company
          const zipcode = AutoCost.getAutoCostZipcode(company, quote)

          const { payload } = await dispatch('ajax', {
            path: 'live_price/fetchLivePriceItem',
            data: {
              live_price_reference: ref,
              zipcode
            }
          })

          explicitChanges = {
            labor_type_id: id,
            labor_type_name: payload.name,
            labor_type_rate_net: payload.labor_rate
          }

          changes = {
            ...explicitChanges
          }
        } else {
          const cacheKey = `cost-type-labor-type-${id}`
          let laborType = {}

          if (useCache) {
            laborType = await fetchCachedOrResolve(cacheKey, 'LaborType', id, dispatch)
          } else {
            // Pull object from resolveObject, call it resolvedLaborType (Because it's Friday and Sera hate's JS)
            const { object: resolvedLaborType } = await dispatch(
              'LaborType/resolveObject',
              { id },
              { root: true }
            )
            // No need to cache it though since we don't wanna
            laborType = resolvedLaborType
          }

          // remod labor type

          explicitChanges = {
            labor_type_id: laborType.labor_type_id || null,
            labor_type_name: laborType.labor_type_name || null,
            labor_type_rate_net: laborType.labor_type_rate_net || null,
            labor_type_rate_net_index: laborType.labor_type_rate_net_index || null,
            labor_type_is_indexed: laborType.labor_type_is_indexed || 0
          }
          // get root/default mod for quote
          const defaultMod =
            norm[rootRefId] && norm[rootRefId].oMod && norm[rootRefId].oMod.mod_id
              ? norm[rootRefId].oMod
              : getters.defaultMod

          const mod = {
            mod_id: object.mod_id || defaultMod.mod_id,
            mod_labor_net: object.mod_labor_net || defaultMod.mod_labor_net,
            mod_materials_net: object.mod_materials_net || defaultMod.mod_materials_net
          }
          const { item: modItem } = applyMod(
            { ...object, ...explicitChanges },
            mod,
            object.cost_matrix_markup_net || getters.defaultMarkup
          )

          changes = {
            ...explicitChanges,
            labor_type_rate_net: modItem.labor_type_rate_net,
            cost_matrix_labor_cost_net: modItem.cost_matrix_labor_cost_net,
            cost_matrix_aggregate_cost_net: modItem.cost_matrix_aggregate_cost_net,
            cost_matrix_rate_net: modItem.cost_matrix_rate_net
          }
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param float laborRate
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setLaborRate({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostType',
          laborRate: rate,
          object = _.imm(rootState[store].normalized[refId])
        } = payload

        let changes = {}

        const type = String(object.labor_type_id)
        const isAutoCost = AutoCost.isAutoCostLaborTypeId(type)

        const id = object.labor_type_id
        const { object: lt } = await dispatch(
          'LaborType/resolveObject',
          {
            id
          },
          { root: true }
        )

        if (_.eq(lt.labor_type_rate_net, rate, 2)) {
          return dispatch('reportChanges', {
            ...payload,
            changes: {},
            explicitChanges: {},
            object
          })
        }

        const coid = (rootState.session.company && rootState.session.company.company_id) || null

        if (
          isAutoCost ||
          !object.company_id ||
          String(object.company_id) !== String(coid) ||
          !(await dispatch(
            'modal/asyncConfirm',
            {
              message: `Would you like to update ${object.labor_type_name} (will affect
            all items with this labor type), or do you want to create a new labor type?`,
              yes: 'Update this labor type',
              no: 'Create new labor type'
            },
            { root: true }
          ))
        ) {
          object.labor_type_id = null
          object.company_id = coid
          object.trade_type_ids = lt.trade_type_ids ?? []
          object.labor_type_name = `${lt.labor_type_name} (${rootState.session.company.company_name})`
        }

        object.labor_type_rate_net = rate

        if (object.company_id) {
          object.labor_type_is_indexed = 0
        }

        const { object: saved } = await dispatch(
          'LaborType/save',
          {
            object,
            go: false
          },
          { root: true }
        )

        changes.labor_type_rate_net = rate
        let explicitChanges = { ...changes }
        changes.labor_type_id = saved.labor_type_id

        if (isAutoCost) {
          // TODO: alert to notify
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      /**
       *
       * @param state
       * @param dispatch
       * @param rootState
       * @param payload
       *    @param string store             store name
       *    @param bool auditLocal          whether to audit before returning changes
       *    @param string|null refId        provide refId, it will automatically make vuex changes.
       *                                    If you don't provide a refId you must provide an object
       *                                    param in payload.
       *    @param object object
       *    @param int|string stage
       * @returns {Promise<Object{changes, explicitChanges}>}
       */
      async setStage({ dispatch, rootState }, payload) {
        const {
          refId = false,
          store = 'CostType',
          stage: id,
          object = _.imm(rootState[store].normalized[refId]),
          suggestTradeType = true,
          confirm = false,
          useCache = false
        } = payload

        const cacheKey = `cost-type-stage-${id}`
        let stage = {}

        if (useCache) {
          stage = await fetchCachedOrResolve(cacheKey, 'Stage', id, dispatch)
        } else {
          // Pull object from resolveObject, call it resolvedStage (Because it's Friday and Sera hate's JS)
          const { object: resolvedStage } = await dispatch(
            'Stage/resolveObject',
            { id },
            { root: true }
          )
          // No need to cache it though since we don't wanna
          stage = resolvedStage
        }

        const explicitChanges = {
          stage_id: stage.stage_id || null,
          stage_name: stage.stage_name || null
        }
        let changes = {
          ...explicitChanges
        }

        // Confirm change of trade type too, if already set, otherwise just change it
        if (
          suggestTradeType &&
          stage.trade_type_id &&
          (!object.trade_type_id ||
            !confirm ||
            (await dispatch(
              'modal/asyncConfirm',
              {
                message: `The stage was changed to <strong class="text-primary">${stage.stage_name}</strong>, would you also like to
                      change the trade type to the suggested <strong class="text-primary">${stage.trade_type_name}</strong>?`,
                subMessage: stage.labor_type_id
                  ? `Changing the trade type will
                        also set the labor rate type to <strong>${stage.labor_type_name} @ ${_.toCurrency(stage.labor_type_rate_net)}/hr</strong>`
                  : null
              },
              { root: true }
            )))
        ) {
          changes = {
            ...changes,
            trade_type_id: stage.trade_type_id,
            trade_type_name: stage.trade_type_name
          }

          // Only add labor type changes if labor_type_id isn't null and confirm is true
          // Without this, labor type gets cleared regardless of whether a replacement was found or not
          if (stage.labor_type_id && confirm) {
            changes = {
              ...changes,
              labor_type_id: stage.labor_type_id,
              labor_type_name: stage.labor_type_name,
              labor_type_rate_net: stage.labor_type_rate_net,
              labor_type_rate_net_index: stage.labor_type_rate_net_index,
              labor_type_is_indexed: stage.labor_type_is_indexed
            }
          }
        }

        return dispatch('reportChanges', {
          ...payload,
          changes,
          explicitChanges,
          object
        })
      },

      async publish({ dispatch }, payload) {
        const { id, store = 'CostType', isPublic = 1 } = payload
        const { object } = await dispatch(`${store}/resolveObject`, { id }, { root: true })
        const type = store.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()
        const field = `${type}_is_public`
        const fieldId = `${type}_id`
        return dispatch('partialUpdate', {
          selected: [
            {
              type,
              [fieldId]: object[fieldId],
              [field]: isPublic
            }
          ]
        })
      }
    }
  },

  getComputedDependants,

  getFieldDependencies() {
    const {
      cost_type_hours_per_unit,
      cost_matrix_labor_cost_net,
      cost_matrix_aggregate_cost_net,
      cost_matrix_rate_net,
      cost_matrix_materials_cost_net,
      cost_type_materials_purchase_price_net,
      cost_type_is_variation_parent
    } = this.getComputedDependants()

    return {
      cost_type_is_fee: {
        cost_type_hours_per_unit,
        cost_matrix_materials_cost_net,
        cost_matrix_labor_cost_net
      },
      labor_type_rate_net: {
        cost_matrix_labor_cost_net
      },
      cost_type_hours_per_unit: {
        cost_matrix_labor_cost_net
      },
      oMeta: {
        cost_matrix_materials_cost_net,
        cost_matrix_labor_cost_net
      },
      cost_matrix_labor_cost_net: {
        cost_matrix_aggregate_cost_net
      },
      cost_matrix_materials_cost_net: {
        cost_matrix_aggregate_cost_net,
        cost_type_materials_purchase_price_net
      },
      cost_type_materials_purchase_qty_per_unit: {
        cost_type_materials_purchase_price_net
      },
      cost_matrix_aggregate_cost_net: {
        cost_matrix_rate_net
      },
      cost_matrix_markup_net: {
        cost_matrix_rate_net
      },
      oVariations: {
        cost_type_is_variation_parent
      }
    }
  }
}
