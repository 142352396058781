import {
  handleOAuthCallback,
  logoutIfAuthenticated,
  redirectWhenLoggedIn
} from '@/router/guards/beforeEnter'

export default [
  {
    path: '/login',
    name: 'Login',
    alias: ['/pub/login'],
    meta: {
      public: true
    },
    component: () => import('@/components/pages/Login.vue'),
    beforeEnter: [redirectWhenLoggedIn]
  },
  {
    path: '/auth/callback',
    name: 'OAuth Callback',
    meta: {
      public: true
    },
    beforeEnter: [handleOAuthCallback]
  },
  {
    path: '/logout',
    name: 'Logout',
    alias: ['/pub/logout'],
    meta: {
      public: true
    },
    beforeEnter: [logoutIfAuthenticated]
  },
  {
    path: '/oauth/:provider/link',
    name: 'Link OAuth Provider',
    component: () => import('@/components/pages/auth/OAuthLink.vue')
  }
]
