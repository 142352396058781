import { computed, onMounted, toValue, ref, isRef, watch } from 'vue'
import { useStore } from 'vuex'
import eventBus from '@/eventBus.js'
import _ from '../../../../imports/api/Helpers.js'
import FieldSetters from './FieldSetters'
import ChangeWatcher from '@/components/Sheets/ChangeWatcher.js'
import Auditing from '../../../../imports/api/Auditing/index.js'
import Dimensions from '@/components/composables/Dimensions.js'

export default {
  useEntityComputedFields(args) {
    const {
      object: robject = null,
      refId: rrefId = 'root',
      type: rtype,
      store: rstore = computed(() => c.titleCase(toValue(rtype))),
      trackChanges: rtrackChanges = false,
      changeHandler: chh = () => {},
      resetHandler: rhh = () => {},
      audit = true,
      $store = useStore()
    } = args

    const refId = computed(() => toValue(rrefId) ?? 'root')
    const { possibleDimensions } = Dimensions.useDimensions()

    const type = computed(() => toValue(rtype))
    const store = computed(() => toValue(rstore) || c.titleCase(type.value))

    const trackChanges = computed(() => toValue(rtrackChanges))

    if (robject && !isRef(robject)) throw new Error('object must be a ref()')
    const usingStore = !Object.keys(robject?.value ?? {}).length

    const norm = computed(() =>
      usingStore ? $store.state[store.value].normalized : { [refId.value]: robject.value }
    )

    // create a waiter that can be awaited for when the object is selected
    let resolver
    const waiter = new Promise((resolve) => {
      resolver = resolve
    })

    const loading = ref(1)

    if (!Object.keys(norm.value).length) {
      let unwatch
      unwatch = watch(norm, () => {
        if (Object.keys(norm.value).length) {
          resolver(true)
          loading.value = 0
          unwatch()
        }
      })
    } else {
      resolver(true)
      loading.value = 0
    }

    const isDirty = ref(null)
    const changes = ref(null)
    const rawChanges = ref(null)

    if (!usingStore && !isRef(robject))
      throw Error(
        'You must either specify a store and refId, or provide a ref() as an object for EntityComputedFields to work properly'
      )

    const object = usingStore ? computed(() => norm.value?.[refId.value]) : robject

    let changeManager
    const initiateChangeTracking = () => {
      const changeHandler = (changePayload) => {
        const { isDirty: is, changeManager } = changePayload
        isDirty.value = is
        changes.value = changeManager.getChangeLogs()
        chh(changePayload)
        eventBus.$emit(`store-changed-${type.value}-${refId.value}`, changePayload)
      }

      const resetHandler = (changePayload) => {
        const { changeManager } = changePayload
        isDirty.value = false
        changes.value = changeManager.getChangeLogs()
        rhh(changePayload)
        eventBus.$emit(`store-reset-${type.value}-${refId.value}`, changePayload)
      }

      const { initiate, changeManager: cm } = ChangeWatcher.useChangeWatcher({
        store: usingStore ? store.value : null,
        refId: refId.value,
        changeHandler,
        resetHandler
      })
      changeManager = cm

      initiate()
    }

    onMounted(() => {
      if (trackChanges.value) initiateChangeTracking()
    })

    // Get mapped fields
    // All
    const constructor = c.getConstructor(type.value)
    const fields = constructor?.fields ?? {}
    const allFields = Object.keys(fields)

    // Equation fields
    const equationFields = allFields.filter((f) => fields[f].type === 'float')

    // Mapped/child fields
    const mappedFields = allFields.filter(
      (f) =>
        c.shouldMapField(f, constructor) ||
        (c.isJsonField(f) && /array|object/.test(fields[f].type))
    )

    // Id mapped fields
    const idFields = allFields.filter(
      (f) => /_id$/.test(f) && fields[f].mapTo && f !== `${type.value}_id`
    )

    const getDeformattedFieldValue = (f, val) => {
      const fields = constructor?.fields
      let deformattedVal = val

      if (fields?.[f]?.type === 'array' || fields?.[f]?.type === 'Array') {
        deformattedVal = c.makeArray(val)
      } else if (Array.isArray(val)) {
        deformattedVal = c.makeArray(val).join(',')
      }

      if (!fields) {
        return val
      }

      if (
        (fields[f].format === 'percent' ||
          fields[f].format === 'percentage' ||
          fields[f].format === 'currency' ||
          fields[f].format === 'number' ||
          (fields[f].type === 'int' && fields[f].format === false)) &&
        val !== null
      ) {
        deformattedVal = c.deformat(val, 'number')
      }
      // If defaulted object, make sure it is defaulted
      const getDefault = (schema, embue) => {
        if (
          schema.type === 'object' &&
          !schema.mapTo &&
          (typeof schema.default === 'object' || typeof schema.default === 'function')
        ) {
          const def =
            typeof schema.default === 'function' ? schema.default(_.imm(embue), {}) : schema.default

          return _.merge(_.imm(def), _.imm(embue))
        }

        return embue
      }

      return getDefault(fields[f], deformattedVal)
    }

    let defaults = {}
    const addDefaults = (changes) => {
      if (!$store.state.session.user.company_id) return
      // accumulate
      defaults = {
        ...defaults,
        ...changes
      }

      c.throttle(
        () => {
          // flush
          const sending = { ...defaults }
          defaults = {}
          $store.dispatch(`${store.value}/addDefaults`, {
            type: type.value,
            object: sending
          })
        },
        { key: type.value }
      )
    }

    const setFields = (changes, ref = refId.value) => {
      rawChanges.value = {
        ...rawChanges.value,
        ...changes
      }

      if (usingStore) {
        $store.dispatch(`${store.value}/field`, {
          changes,
          explicit: true,
          skipAudit: !audit,
          skipLocalAudit: false,
          refId: ref
        })
      } else {
        Object.assign(object.value, rawChanges.value)
        changeManager?.addExplicitChanges({ [ref]: changes })

        if (audit) {
          const [, auditCh] = Auditing.cascadeDependencies(
            { root: { ...object.value } },
            ref,
            { root: changes },
            possibleDimensions.value
          )

          Object.assign(object.value, auditCh.root)
        }
      }

      addDefaults(changes)
    }

    const computedFields = {
      /**
       * Map computed fields that call on their respective
       * intercept fields.
       *
       * For each field in the schema we also have an intercept
       * field that is updated whenever there is upstream changes
       * from the VUEX store this component represents.
       *
       * By having an intercept field for each field, rather than
       * one object having all the field values, we can trigger only computed
       * fields to update that have had changes.  If we simply added the changes
       * to some object like objectLocal, it would trigger ALL the computed
       * properties to update.
       */

      /**
       * Normalized fields
       */
      ...allFields.reduce((acc, f) => {
        return {
          ...acc,

          [f]: computed({
            get() {
              return object.value?.[f] ?? null
            },
            async set(val) {
              const deformattedVal = getDeformattedFieldValue(f, val)
              const stateVal = object.value?.[f]
              if (
                !object.value ||
                stateVal === deformattedVal ||
                c.jsonEquals(stateVal, deformattedVal)
              ) {
                return
              }

              const eq = object.value?.oEquations?.[f]
              const hasEquation = eq && c.isEquation(eq) ? eq : null

              let changes = {
                [f]: deformattedVal
              }

              if (FieldSetters[type.value]?.[f]) {
                const { changes: fschanges } = await FieldSetters[type.value][f]({
                  $store,
                  store: store.value,
                  object: object.value,
                  value: val,
                  norm: norm.value,
                  equations: hasEquation ? { [f]: eq } : {}
                })
                changes = { ...changes, ...fschanges }
              }

              setFields(changes)

              _.throttle(
                () => {
                  eventBus.$emit('change', {
                    refId: refId.value,
                    store: store.value
                  })
                  eventBus.$emit('changed', {
                    refId: refId.value,
                    store: store.value
                  })
                },
                { key: refId.value, delay: 100 }
              )
            }
          })
        }
      }, {}),
      /**
       * Equation fields
       * Add equation fields `${field}_equation`
       */
      ...equationFields.reduce(
        (acc, f) => ({
          ...acc,

          [`${f}_equation`]: computed({
            get() {
              return object.value?.oEquations?.[f] ?? null
            },
            set(text) {
              if (usingStore) {
                $store.dispatch(`${store.value}/fieldEquation`, {
                  field: f,
                  equation: text,
                  refId: refId.value
                })
              } else {
                setFields({
                  oEquations: {
                    ...(object.value?.oEquations ?? {}),
                    [f]: text
                  }
                })
              }
            }
          })
        }),
        {}
      ),
      /**
       * Mapped, children fields
       *
       * Add special mapped fields for fields that refer to refIds
       aoChildren, aoVendors
       Also deals with fields that needn't map to a specific
       object type, but are objects or arrays like aoProperties

       Returns a manipulation-safe field in the non-hungarian version
       for example, with the field 'aoProperties', this will also create a field
       called 'properties'.  Properties will return an immutable object based on
       aoProperties, so from the component you can do: this.properties.splice(1, 1) etc
       without getting a 'do not change vuex state outside of a mutation handler'.
       And changing it, this.properties = []; similarly maps it back into normalized format.
       */
      ...mappedFields.reduce((acc, f) => {
        const schema = f && fields && f in fields && fields[f]
        const isArray = schema.type === 'array'
        const shouldMap = c.shouldMapField(f, type.value)

        return {
          ...acc,

          [_.hungarianToCamelCase(f)]: computed({
            get() {
              const defaultValue = isArray ? [] : {}
              const intercepted = object.value?.[f] ?? null

              if (!intercepted) return intercepted

              if (!shouldMap) {
                return _.imm(intercepted) || defaultValue
              }

              if (!object.value || !object.value[f]) {
                return defaultValue
              }

              const mappedObjects = c.makeArray(_.imm(object.value[f])).map((r) => norm[r] || {})

              if (isArray) {
                return mappedObjects
              } else if (mappedObjects.length) {
                return mappedObjects[0]
              }

              return defaultValue
            },
            set(children) {
              const childrenArray = c.makeArray(children)

              if (shouldMap && usingStore) {
                $store.dispatch(`${store.value}/replaceChildren`, {
                  refId: refId.value,
                  children: childrenArray,
                  field: f
                })
              } else {
                computedFields[f].value = isArray ? childrenArray : childrenArray[0]
              }
            }
          })
        }
      }, {}),
      /**
       * ID mapped fields
       *  Add mapped _id fields like client_id => clientId
       for automated fetching of sub objects
       fetched changes emit 'linkedObjectChanged'
       */
      ...idFields.reduce(
        (acc, f) => ({
          ...acc,

          [_.camelCase(f)]: computed({
            get() {
              return object.value?.[f] ?? null
            },
            async set(id) {
              const schema = f in fields && fields[f]
              const mapTo = schema.mapTo

              const titleMapTo = _.titleCase(mapTo)
              const camelMapTo = _.camelCase(mapTo)

              const nameField = f.replace('_id', '_name')
              const abbrField = f.replace('_id', '_abbr')
              const rootNameField = `${mapTo}_name`
              const rootAbbrField = `${mapTo}_abbr`

              if (c.fieldComparison(computedFields[f].value, id, f, fields, false, false)) {
                return
              }

              computedFields[f].value = id

              const mappedObjectExists =
                camelMapTo in computedFields &&
                (typeof computedFields[camelMapTo].value === 'object' ||
                  computedFields[camelMapTo].value === null)
              const nameFieldExists = nameField in computedFields
              const abbrFieldExists = abbrField in computedFields

              let mappedObject = {}
              let nameValue = null
              let abbrValue = null

              if (id && (mappedObjectExists || nameFieldExists)) {
                const { object } = await $store.dispatch(`${titleMapTo}/fetch`, {
                  id
                })

                mappedObject = object
                nameValue = object[rootNameField] || nameValue
                abbrValue = object[rootAbbrField] || abbrValue
              }

              eventBus.$emit(`linkedObjectChanging:${mapTo}`, {
                field: f,
                object: mappedObject,
                type: mapTo
              })
              eventBus.$emit('linkedObjectChanging', {
                field: f,
                object: mappedObject,
                type: mapTo
              })

              if (mappedObjectExists) {
                computedFields[camelMapTo].value = mappedObject
              }

              if (nameFieldExists) {
                computedFields[nameField].value = nameValue
              }

              if (abbrFieldExists) {
                computedFields[abbrField].value = abbrValue
              }

              eventBus.$emit(`linkedObjectChanged:${mapTo}`, {
                field: f,
                type: mapTo,
                object: mappedObject
              })
              eventBus.$emit('linkedObjectChanged', { field: f, type: mapTo, object: mappedObject })
            }
          })
        }),
        {}
      )
    }

    return {
      usingStore,
      setFields,
      object,
      norm,
      isDirty,
      changes,
      selected: waiter,
      loading,
      refId: computed(() => refId.value),
      parentRefId: computed(() => norm.value[refId.value].parentRefId),
      ...computedFields
    }
  }
}
