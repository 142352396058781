<template>
  <div
    :class="[
      'rounded-sm pointer-events-auto  max-w-[400px]',
      {
        'bg-surface-800/90':
          !alert.error && !alert.loading && !alert.warning && !alert.success && !alert.ai,
        'bg-deep-red-400/90': alert.error,
        'bg-orange-500/80': alert.warning,
        'bg-green-500/80': alert.success,
        'bg-blue-500/80': alert.loading,
        'bg-purple-200/80': alert.ai,
        'px-4 py-3 ': alert.size !== 'sm',
        'px-3 py-2 ': alert.size === 'sm'
      }
    ]"
    @click="(e) => hide(e)"
    @mousedown="(e) => startDrag(e)"
    @touchstart="(e) => startDrag(e)"
    @mousemove="(e) => onDrag(e)"
    @touchmove="(e) => onDrag(e)"
    @mouseup="(e) => stopDrag(e)"
    @touchend="(e) => stopDrag(e)"
    @mouseout="(e) => stopDrag(e)"
    :style="style"
  >
    <div class="flex flex-col gap-4">
      <div
        class="flex flex-row justify-center items-center gap-4"
        :class="[
          'font-medium whitespace-pre-wrap leading-tight',
          {
            'text-sm': alert.size === 'sm',
            'text-white': alert.error || alert.success,
            'text-pitch-black': alert.warning,
            'text-blue-print-900': alert.loading,
            'text-purple-800': alert.ai,
            'text-surface-200':
              !alert.loading && !alert.error && !alert.warning && !alert.success && !alert.ai
          }
        ]"
      >
        <font-awesome-icon icon="check" v-if="alert.success" size="lg" />
        <LoadingIndicator v-if="alert.loading" />
        <font-awesome-icon icon="warning" v-if="alert.error || alert.warning" size="lg" />
        <font-awesome-icon icon="sparkles" v-if="alert.ai" size="lg" />

        <IconText :text="msg" html />
      </div>

      <ul class="alert--warning" v-if="alert.warnings.length">
        <li v-for="(warning, index) in alert.warnings" :key="index">
          {{ warning }}
        </li>
      </ul>

      <div
        class="flex gap-2 justify-end items-stretch"
        v-if="alert.actions?.length || !alert.timeout"
      >
        <template v-if="alert.actions?.length">
          <Btn
            v-for="(action, index) in alert.actions"
            :key="index"
            :size="alert.size ?? 'medium'"
            :action="
              async () => {
                await action.action()
                hide()
              }
            "
            severity="tertiary"
            hotkey="cmd-enter"
            :class="[
              '!whitespace-break-spaces !leading-tight !h-auto py-2',
              {
                'text-white !border-white !hover:text-pitch-black':
                  !alert.warning && !alert.loading && !alert.ai
              }
            ]"
          >
            {{ action.text || action.title || action.label }}
          </Btn>
        </template>
        <Btn
          v-if="!alert.timeout"
          @click="hide()"
          severity="tertiary"
          :size="alert.size ?? 'large'"
          :class="[
            ' !h-auto shrink-0',
            {
              'text-white !border-white !hover:text-pitch-black':
                !alert.warning && !alert.loading && !alert.ai
            }
          ]"
          >Dismiss</Btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import IconText from '@/components/ui/IconText.vue'
import LoadingIndicator from '@/components/ui/LoadingIndicator.vue'

export default {
  name: 'Alert',
  components: { LoadingIndicator, IconText },
  props: ['alert'],
  computed: {
    msg() {
      return c.removeHtml(this.alert.text || this.alert.message).replace(/ {2,}/gi, ' ')
    },
    opacity() {
      return this.dragging && this.offset && this.offset.x
        ? 1 - (this.offset.x / this.destroyAt) * 0.5
        : 1
    },
    style() {
      if (this.dragging && this.offset && this.offset.x) {
        return `position: relative; left:${this.offset.x}px; transition: none; opacity:${this.opacity};`
      }
      return `position: relative; left:0px; transition: all 1s; opacity:${this.opacity};`
    }
  },
  data() {
    return {
      dragging: false,
      offset: {},
      startingPosition: {},
      startingClick: {},
      destroyAt: 600,
      destroying: false,
      destroyTranslate: 0
    }
  },
  methods: {
    startDrag(e) {
      const rect = this.$el.getBoundingClientRect()
      this.destroyAt = rect.width / 2
      this.startingPosition = { x: rect.left }
      this.startingClick = { x: e.clientX }
      this.offset = { x: 0 }
      this.dragging = true
    },
    onDrag(e) {
      if (this.dragging) {
        this.offset = {
          x: e.clientX - this.startingClick.x < 0 ? 0 : e.clientX - this.startingClick.x
        }
        if (this.destroyAt && this.offset.x >= this.destroyAt) {
          this.destroying = true
          this.hide()
        }
      }
    },
    stopDrag() {
      if (this.dragging) {
        this.dragging = false
      }
    },
    hide() {
      this.dragging = false
      this.$store.dispatch('removeAlert', { alert: this.alert })
    }
  },
  mounted() {
    if (this.alert.timeout) {
      setTimeout(() => {
        this.hide()
      }, this.alert.timeout)
    }
  }
}
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
$margin: 2em;
$padding: 2em 2em;
$borderRadius: 10px;

.alert--container {
  width: auto;
  max-width: 400px;
  border-radius: $borderRadius;
  background: rgba($pitch-black, 0.75);
  min-height: 3em;
  padding: $padding;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  pointer-events: all;

  .alert--checkmark {
    zoom: 0.5;
  }
  .alert--spinner {
    margin-right: 1em;
  }

  .alert--content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
  }

  .alert--message {
    color: $flame-white;
    font-weight: normal;
    font-size: 0.9em;
  }

  .alert--warning {
  }

  &.muted {
    background: rgba($pitch-black, 0.7);
    font-size: 0.7em;
  }

  &.error {
    background: rgba($deep-red-500, 0.7);
  }

  &.warning {
    background: rgba($orange-500, 0.7);

    .alert--message {
      color: $pitch-black;
    }
  }
}
</style>
