export enum ENpsEntryFeatureType {
  Engagement = 'engagement',
  Impression = 'impression',
  OnBoarding = 'onboarding',
  Overall = 'overall',
  Estimating = 'estimating',
  Scheduling = 'scheduling',
  Budget = 'budget',
  Selections = 'selections',
  Chat = 'chat',
  Pipeline = 'pipeline'
}
