import { useFileService } from '@/components/composables/UseFileService'

import NormalizeUtilities from '../../../../imports/api/NormalizeUtilities.js'
import { usePresentationManager } from '@/components/quote/presentation/usePresentationManager.js'

import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import jsPDF from 'jspdf'
import CurrencyFilter from '@/components/composables/CurrencyFilter.js'
import QrCodeWithLogo from 'qrcode-with-logos'
import BolsterIcon from '@/assets/logos/Bolster_Icon_Fill_Yellow.png'
import '@/assets/fonts/jsPDF/Whisper-Regular-normal'
import { font } from '@/assets/fonts/jsPDF/Whisper-Regular-normal.js'

const translate = (v) => v
// const l = translate

export function useQuotePdf(props) {
  const $store = props.$store ?? useStore()
  const { currencySymbol } = CurrencyFilter()
  const { getPreSignedPost } = useFileService()

  //
  //   const {
  //     logoFileId,
  //     showItemPrice,
  //     showItemQty,
  //     showItem,
  //     presentationSettings,
  //     termsAndConditions
  //   } = usePresentationManager({
  //     refId: props.refId,
  //     store: props.store,
  //     autoSave: true,
  //     $store: store
  //   })
  //
  //   const l = (v) => v
  //
  //   const ordered = ref(null)
  //   // const currentParent = ref(null)
  //   const artificialMultiplier = ref(1)
  //   const sectionMargin = ref(10)
  //   const page = ref(1)
  //   const itemMarginTop = ref(0)
  //   // const maxWidth = ref(0.7)
  //   const marginTop = ref(0)
  //   const pdfFiles = ref(null)
  //   const headerHeight = ref(0)
  //   const currentParentRefId = ref(null)
  //   const changeOrderList = ref([])
  //   const imageDim = reactive({ w: 20, h: 20 })
  //   const overrides = reactive({
  //     hideTitle: false,
  //     hideImages: true,
  //     hideDescription: false,
  //     hideLocation: true,
  //     hideNotes: false,
  //     hideInputs: false,
  //     hideProperties: true,
  //     hidePrice: false
  //   })
  //
  //   const norm = computed(() => store.state[props.store].normalized)
  //   const object = computed(() => norm.value[props.refId])
  //
  //   const {
  //     doc,
  //     initDoc,
  //     txt,
  //     callout,
  //     pageLine,
  //     getTextHeight,
  //     convertImage,
  //     getImageDimWithMax,
  //     setBlobUrl,
  //     textXY,
  //     pageWidth,
  //     pageMarginX,
  //     pageMarginY,
  //     pageHeight,
  //     alignRight,
  //     lineHeight,
  //     containerMargin,
  //     savePDF
  //   } = usePDF()
  //
  //   const setupItemsForPDF = () => {
  //     const denormalized = c.denormalize(norm.value)
  //     ordered.value = []
  //     orderItems([denormalized], ordered.value)
  //   }
  //
  //   const renderDoc = async () => {
  //     let secureUrl = ''
  //     setupItemsForPDF()
  //     const key = await getPdfKey()
  //     await createDoc()
  //     if (key && !props.isInQuoteEditor) {
  //       secureUrl = `${import.meta.env.VITE_S3_APPROVALS_BUCKET_ENDPOINT}/${key}`
  //     }
  //     setBlobUrl(secureUrl)
  //   }
  //
  //   const getPdfKey = async () => {
  //     const { client_id, quote_id, change_order_id } = object.value
  //     const prefix = `${client_id}/${quote_id}/${change_order_id}`
  //     return await getFileKey(prefix)
  //   }
  //
  //   const createDoc = async () => {
  //     initDoc()
  //     pdfFiles.value = await getAllImageData()
  //     await createPDFHeader()
  //     const contentY = createContent()
  //     const totalsY = createTotals(contentY + sectionMargin.value)
  //     const notesY = getProjectNotes(totalsY + sectionMargin.value)
  //     const termsY = getTermAndConditions(notesY + sectionMargin.value)
  //     await getChangeOrderInfo(termsY + sectionMargin.value)
  //   }
  //   const getTermAndConditions = (startAt) => {
  //     // Check whether the terms and conditions sections should be displayed
  //     if (!props.combinedPresentationSettings?.showTermsAndConditions) {
  //       return startAt - sectionMargin.value
  //     }
  //     if (!props.presentationSettings?.showTermsAndConditions) {
  //       return startAt - sectionMargin.value
  //     }
  //
  //     // Calculate the height of terms content without rendering it to see if we need a new page
  //     const height = setTerms(startAt, false)
  //     let startingPoint = startAt
  //
  //     // Check if a new page is needed
  //     if (startingPoint + height > pageHeight.value) {
  //       doc.addPage()
  //       startingPoint = pageMarginX.value
  //     }
  //
  //     // Add the title for terms and conditions
  //     const titleDim = txt(
  //       'h2',
  //       l('Terms and conditions'),
  //       pageMarginX.value,
  //       startingPoint + lineHeight.value
  //     )
  //
  //     // Render terms content
  //     const endHeight = setTerms(startingPoint + titleDim.h + lineHeight.value, true)
  //
  //     // Return the end position after rendering terms
  //     return startingPoint + endHeight
  //   }
  //   const createContent = () => {
  //     const headerMargin = headerHeight.value + pageMarginY.value * 2 + containerMargin.value
  //     marginTop.value = headerMargin
  //     itemMarginTop.value = pageMarginY.value
  //     page.value = 1
  //     let endAt = marginTop.value + lineHeight.value
  //     txt('h2', l('Items'), pageMarginX.value, marginTop.value - lineHeight.value)
  //     ordered.value.forEach((item) => {
  //       if (item.type === 'quote') return
  //       if (isTask(item)) return
  //       if (!itemVisibilityCheck(item.refId)) return
  //       if (getItemAudience(item) !== 'client') return
  //       let name = item.cost_type_name || item.assembly_name || item.item_name
  //       if (name === '') return
  //       if (['cost_type', 'cost_item'].includes(item.type) && item.cost_type_is_task) return
  //       const { height, endY } = createItem(item)
  //       itemMarginTop.value += height
  //       endAt = endY
  //     })
  //     return endAt
  //   }
  //
  //   const createItem = (item) => {
  //     const meta = setMetaData(item)
  //     const dimensions = {}
  //     let marginX = pageMarginX.value
  //     let prevTab = pageMarginX.value
  //     const hierarchyIndicator = item.asAssemblyPath?.length || 1
  //
  //     if (item.parentRefId !== props.refId) {
  //       const multiplier = hierarchyIndicator > 8 ? 8 : hierarchyIndicator
  //       marginX += multiplier * 4
  //       prevTab += (multiplier - 1) * 4
  //     }
  //
  //     let startingY = marginTop.value + itemMarginTop.value
  //     const price = getPrice(item, meta)
  //     const location = item.asAssemblyPath ? item.asAssemblyPath.join(' → ') : null
  //     const description = item.cost_type_desc || item.quote_notes || ''
  //     const name = item.cost_type_name || item.assembly_name || item.item_name || ''
  //     const productionNotes = item.cost_type_production_notes || item.quote_production_notes || ''
  //     const inputs =
  //       item.oInputRequired && item.oInputRequired.inputs && item.oInputRequired.inputs.length
  //         ? item.oInputRequired.inputs.reduce((acc, input) => {
  //             let accr = ''
  //             if (input.type === 'text') {
  //               accr = `${acc ? `${acc} • ` : ''}${input.message}: ${input.input.text}`
  //             }
  //             if (input.type === 'color') {
  //               accr = `${acc ? `${acc} • ` : ''}${input.input.data.vendor || ''} - ${input.input.data.name || ''}`
  //             }
  //             return accr
  //           }, '')
  //         : false
  //
  //     const h = getItemHeight({
  //       startingY,
  //       title: name,
  //       description,
  //       notes: productionNotes,
  //       location,
  //       properties: item.aoProperties,
  //       item,
  //       meta
  //     })
  //
  //     const addPage = checkPageHeight(itemMarginTop.value + h)
  //     if (addPage) {
  //       doc.value.addPage()
  //       page.value += 1
  //       itemMarginTop.value = pageMarginY.value
  //       marginTop.value = pageMarginY.value
  //       startingY = marginTop.value + itemMarginTop.value
  //     }
  //
  //     let endY = 0
  //     if (showTitle(name)) {
  //       const titleXY = { x: marginX, y: startingY }
  //       if (hierarchyIndicator > 1) {
  //         txt('icon', '↳ ', prevTab - 0.5, titleXY.y - 0.5)
  //       }
  //       dimensions.title = txt(hierarchyIndicator === 1 ? 'h3' : 'h4', name, titleXY.x, titleXY.y)
  //       endY = titleXY.y + dimensions.title.h - 1
  //     }
  //
  //     if (showLocation(location)) {
  //       const locationXY = { x: marginX, y: endY + lineHeight.value / 6 }
  //       dimensions.location = txt('small', location, locationXY.x, locationXY.y)
  //       endY = locationXY.y + dimensions.location.h
  //     }
  //
  //     if (shouldShowImages(meta, item)) {
  //       const imageY = endY
  //       dimensions.images = addImages(item, imageY, marginX)
  //       endY = dimensions.images.h + imageY + 3
  //     }
  //
  //     if (showDescription(meta, description)) {
  //       const descriptionXY = { x: marginX, y: endY + lineHeight.value }
  //       dimensions.description = txt('p', description.trim(), descriptionXY.x, descriptionXY.y)
  //       if (dimensions.description.pageAdded) {
  //         endY = dimensions.description.h + lineHeight.value * 2 + marginTop.value
  //       } else {
  //         endY = descriptionXY.y + dimensions.description.h
  //       }
  //     }
  //
  //     if (showNotes(meta, productionNotes)) {
  //       const notesXY = { x: marginX, y: endY + lineHeight.value }
  //       const red = [156, 0, 0]
  //       dimensions.notesHeader = txt(
  //         'b',
  //         `${l('Production Notes')}:`,
  //         notesXY.x,
  //         notesXY.y,
  //         'left',
  //         red
  //       )
  //       if (dimensions.notesHeader.pageAdded) {
  //         notesXY.y = dimensions.notesHeader.h + lineHeight.value + marginTop.value
  //       }
  //       dimensions.notes = txt(
  //         'p',
  //         productionNotes,
  //         notesXY.x,
  //         notesXY.y + lineHeight.value,
  //         'left',
  //         red
  //       )
  //       if (dimensions.notes.pageAdded) {
  //         endY = dimensions.notes.h + lineHeight.value * 2 + marginTop.value
  //       } else if (dimensions.notesHeader.pageAdded) {
  //         endY = dimensions.notes.h + dimensions.notesHeader.h + lineHeight.value + marginTop.value
  //       } else {
  //         endY = notesXY.y + dimensions.notes.h + dimensions.notesHeader.h + lineHeight.value
  //       }
  //     }
  //
  //     if (showInputs(inputs)) {
  //       const inputsXY = { x: marginX, y: endY + lineHeight.value }
  //       dimensions.inputsHeader = txt(
  //         'b',
  //         `${l('Client Input Details')}:`,
  //         inputsXY.x,
  //         inputsXY.y,
  //         'left',
  //         [156, 0, 0]
  //       )
  //       if (dimensions.inputsHeader.pageAdded) {
  //         inputsXY.y = dimensions.inputsHeader.h + lineHeight.value + marginTop.value
  //       }
  //       dimensions.inputs = txt(
  //         'p',
  //         inputs,
  //         inputsXY.x,
  //         inputsXY.y + lineHeight.value,
  //         'left',
  //         [156, 0, 0]
  //       )
  //       if (dimensions.inputs.pageAdded) {
  //         endY = dimensions.inputs.h + lineHeight.value * 2 + marginTop.value
  //       } else if (dimensions.inputsHeader.pageAdded) {
  //         endY =
  //           dimensions.inputs.h + dimensions.inputsHeader.h + lineHeight.value * 2 + marginTop.value
  //       } else {
  //         endY = inputsXY.y + dimensions.inputs.h + dimensions.inputsHeader.h + lineHeight.value
  //       }
  //     }
  //
  //     // Properties
  //     if (showProperties(meta) || isTextBlock(item)) {
  //       const attrXY = { x: marginX, y: endY + lineHeight.value }
  //       item.aoProperties.forEach((attribute) => {
  //         if (!attribute || attribute.length === 0 || typeof attribute[0] !== 'string') return
  //         const attrString =
  //           attribute[0].trim() !== ''
  //             ? `• ${attribute[0]}${attribute.length > 1 && typeof attribute[1] === 'string' && attribute[1] ? `: ${attribute[1]}` : ''}`
  //             : ''
  //         const dim = txt('p', attrString, attrXY.x, attrXY.y)
  //         if (dim.pageAdded) {
  //           attrXY.y = dim.h + lineHeight.value * 2 + marginTop.value
  //           return
  //         }
  //         attrXY.y += dim.h + doc.value.internal.getLineHeightFactor()
  //       })
  //       endY = attrXY.y
  //     }
  //
  //     // Price
  //     if (!overrides.hidePrice && price) {
  //       const chipXY = { x: alignRight.value, y: startingY }
  //       dimensions.chip = txt('p', price, chipXY.x, chipXY.y, 'right')
  //     }
  //
  //     // Add divider line
  //     pageLine(endY, null, false, marginX)
  //
  //     // Return the calculated height of the item section
  //     const height = endY + lineHeight.value - startingY + 3
  //     currentParentRefId.value = item.parentRefId
  //     return { height, endY: endY + lineHeight.value, addPage }
  //   }
  //
  //   // Additional computation and utility methods
  //   const showTitle = (name) => !overrides.hideTitle && name
  //   const showDescription = (meta, description) =>
  //     !overrides.hideDescription && meta.showDescription && description
  //   const shouldShowImages = (meta, item) => {
  //     if (isGallery(item)) return true
  //     return !overrides.hideImages && meta.hasImages && shouldAddImages(item)
  //   }
  //   const isGallery = (item) => getItemType(item) === 'gallery'
  //   const isTextBlock = (item) => getItemType(item) === 'text'
  //
  //   const setMetaData = (item) => {
  //     const { showProductionNotes } = presentationSettings.value
  //     let qty = 0
  //     if (item.type === 'assembly') {
  //       qty = item.quote_qty_net_base * artificialMultiplier.value
  //     } else {
  //       qty =
  //         (item.cost_item_qty_net_base || item.quote_qty_net_base || 0) * artificialMultiplier.value
  //     }
  //     const parent = norm.value[item.parentRefId]
  //     const showItemized = !parent || parent.quote_show_itemized_prices
  //     const visibilityCheck = showItem(item.refId)
  //     const showAsItem = false
  //     const showPrice = showItemPrice(item.refId)
  //     const showQty = showItemQty(item.refId)
  //     const showSubTotal = showPrice
  //     const itemPictures = getItemPictures(item)
  //     const hasImages = itemPictures.length && presentationSettings.value.showDocumentPictures
  //     const showDescription = presentationSettings.value.showDocumentDescriptions || isTextBlock(item)
  //     const showProperties = item.aoProperties?.some(
  //       (property) =>
  //         Array.isArray(property) &&
  //         property.length > 0 &&
  //         property.some((prop) => prop && prop.trim().length > 0)
  //     )
  //
  //     return {
  //       showProductionNotes,
  //       showQty,
  //       qty,
  //       parent,
  //       showItemized,
  //       showCost: false,
  //       showPrice,
  //       showSubTotal,
  //       assemblyCheck: visibilityCheck,
  //       showAsItem,
  //       hasImages,
  //       showProperties,
  //       showDescription,
  //       visibility: { price: showPrice, qty: showQty, isVisible: visibilityCheck }
  //     }
  //   }
  //
  //   const getItemType = (item) => item.oMeta?.itemType || 'costItem'
  //   const getItemAudience = (item) => item.oMeta?.selectionAudience || 'client'
  //   const getPrice = (item, meta) => {
  //     if (isGallery(item) || isTextBlock(item)) return ''
  //     let price = (item.cost_item_price_net_base || item.quote_subtotal_net || 0) * 1
  //     if (props.combinedPresentationSettings?.showBundledProfit) {
  //       price = item.quote_total_cost_net_base * 1
  //     }
  //     const check =
  //       (meta.showQty && (meta.qty > 1.0 || meta.qty === 0)) || meta.showCost || meta.showPrice
  //     if (!check) return ''
  //     let priceFormatted = 'Not included'
  //     if (meta.qty && meta.qty > 0) {
  //       const quantity =
  //         meta.showQty && meta.qty > 1
  //           ? `${formatCurrencySymbol(meta.qty)} ${l('each').toUpperCase()} • `
  //           : ''
  //       const fullPrice = meta.showPrice ? currency(price) : ''
  //       priceFormatted = `${quantity} ${fullPrice}`
  //     }
  //     return priceFormatted
  //   }
  //
  //   const getItemHeight = ({
  //     startingY,
  //     title,
  //     description,
  //     notes,
  //     location,
  //     properties,
  //     item,
  //     meta
  //   }) => {
  //     let endY = 0
  //     if (showTitle(title)) {
  //       const h = getTextHeight(title, 'h2')
  //       endY = startingY + h
  //     }
  //     if (showLocation(location)) {
  //       const h = getTextHeight(location, 'small')
  //       endY = endY + lineHeight.value / 2 + h
  //     }
  //     if (shouldShowImages(meta, item)) {
  //       const pdfItem = pdfFiles.value.find((i) => i.id === item.item_id)
  //       if (pdfItem?.files) {
  //         const { files } = pdfItem
  //         const rows = Math.floor(files.length / 8)
  //         endY = imageDim.h + rows + (rows - 1) * 5 + endY + lineHeight.value
  //       }
  //     }
  //     if (showDescription(description)) {
  //       const h = getTextHeight(description, 'p')
  //       endY = endY + lineHeight.value + h
  //     }
  //     if (showNotes(meta, notes)) {
  //       const h = getTextHeight(notes, 'p')
  //       endY = endY + lineHeight.value + h
  //     }
  //     if (showProperties(meta)) {
  //       properties.forEach((attribute) => {
  //         if (!attribute || attribute.length === 0 || typeof attribute[0] !== 'string') return
  //         const attrString =
  //           attribute[0].trim() !== ''
  //             ? `• ${attribute[0]}${attribute.length > 1 && typeof attribute[1] === 'string' && attribute[1] ? `: ${attribute[1]}` : ''}`
  //             : ''
  //         const h = getTextHeight(attrString, 'p')
  //         endY = endY + lineHeight.value + h
  //       })
  //     }
  //     return endY + lineHeight.value - startingY + 6
  //   }
  //
  //   const checkPageHeight = (itemMarginTop) => {
  //     let check = itemMarginTop
  //     if (page.value === 1) {
  //       check = itemMarginTop + marginTop.value
  //     }
  //     return check > pageHeight.value
  //   }
  //
  //   const addImages = (item, startY, marginX) => {
  //     const pdfItem = pdfFiles.value.find((i) => i.id === item.item_id)
  //     if (!pdfItem || !pdfItem.files.length) return { h: 0, w: 0 }
  //
  //     const imageSpacing = 5
  //     const imagesPerRow = 4
  //     const scaledWidth = imageDim.w * 2
  //     const scaledHeight = imageDim.h * 2
  //     const rowHeight = scaledHeight + imageSpacing
  //     let imageXY = { x: marginX, y: startY }
  //     let rows = 0
  //
  //     pdfItem.files.forEach((file, index) => {
  //       if (!file) return
  //
  //       const { image: img } = file
  //       if (index > 0 && index % imagesPerRow === 0) {
  //         imageXY.y += rowHeight
  //         imageXY.x = marginX
  //         rows++
  //       }
  //
  //       doc.value.addImage(img, 'JPEG', imageXY.x, imageXY.y, scaledWidth, scaledHeight)
  //       imageXY.x += scaledWidth + imageSpacing
  //     })
  //
  //     rows += Math.ceil(pdfItem.files.length % imagesPerRow === 0 ? 0 : 1)
  //     return { h: rows * rowHeight - imageSpacing, w: imageXY.x - marginX }
  //   }
  //
  //   const getItemPictures = (item) => {
  //     if (!item?.file_ids) return []
  //     const fileIdsArray = Array.isArray(item.file_ids)
  //       ? item.file_ids
  //       : item.file_ids.split(',').map((id) => id.trim())
  //     const ids = []
  //     const fileIds = fileIdsArray.map((id) => c.buildDefaultObject('file', { file_id: id }))
  //     return fileIds.filter((file) => {
  //       const id = String(file.file_id)
  //       const isUnique = !ids.includes(id)
  //       if (isUnique) ids.push(id)
  //       return !c.isempty(id) && isUnique
  //     })
  //   }
  //
  //   const getFileIds = (item) => {
  //     const itemPictures = getItemPictures(item)
  //     return c.uniq(itemPictures.map((f) => f.file_id))
  //   }
  //
  //   const getFilesListWithUrl = (item) => {
  //     const fileIds = getFileIds(item)
  //     return fileIds.map((id) => ({
  //       id,
  //       url: c.link(`file/view/${id}`, {}, true, _.getStorage('scope')),
  //       thumb: c.link(
  //         `file/pic/thumb/${id}`,
  //         { size: 200, square: true },
  //         true,
  //         _.getStorage('scope')
  //       )
  //     }))
  //   }
  //
  //   const getImageData = async (item) => {
  //     const thumbs = getFilesListWithUrl(item)
  //     return Promise.all(
  //       thumbs.map(async ({ thumb, id }) => {
  //         try {
  //           const image = await convertImage(thumb)
  //           return { id, thumb, image }
  //         } catch (e) {
  //           console.error(e) // Silent error handling
  //         }
  //       })
  //     )
  //   }
  //
  //   const getAllImageData = () => {
  //     return Promise.all(
  //       ordered.value.map(async (item) => {
  //         const { item_id: itemId } = item
  //         const files = await getImageData(item)
  //         return { id: itemId, files }
  //       })
  //     )
  //   }
  //
  //   const shouldAddImages = (item) => {
  //     const pdfItem = pdfFiles.value.find((i) => i.id === item.item_id)
  //     let addImages = false
  //     if (pdfItem?.files) {
  //       pdfItem.files.forEach((file) => {
  //         if (!file) return
  //         addImages = true
  //       })
  //     }
  //     return addImages
  //   }
  //
  //   // Visibility and Metadata Utility Functions
  //   const itemVisibilityCheck = (refId) => {
  //     const viewOptions = getVisibility(refId)
  //     return viewOptions.isVisible ?? true
  //   }
  //
  //   const itemPriceVisibilityCheck = (refId) => {
  //     const item = norm.value[refId]
  //     const isAssembly = item.type === 'assembly'
  //     const viewOptions = getVisibility(refId)
  //     return (
  //       viewOptions.price ??
  //       (presentationSettings.value.showItemizedPrices &&
  //         ((!isAssembly && presentationSettings.value.showCostItemPrices) ||
  //           (isAssembly && presentationSettings.value.showAssemblyPrices)))
  //     )
  //   }
  //
  //   const createPDFHeader = async () => {
  //     const estimate = object.value
  //     if (estimate) {
  //       // Client info extraction
  //       const {
  //         company_name: companyName,
  //         user_suite: clientSuite,
  //         user_address: clientAddress,
  //         user_city: clientCity,
  //         user_prov: clientProv,
  //         user_postal: clientPostal
  //       } = estimate.oClient || {}
  //
  //       // Presentation settings extraction
  //       const { props = [] } = estimate.oPresentationSettings
  //
  //       const companyShortName = store.state.session.company?.company_name_short
  //       const companyPhone = store.state.session.company?.company_phone
  //       const companyWebsite = store.state.session.company?.company_website
  //       const companyEmail = store.state.session.company?.company_email
  //
  //       // Estimate information
  //       const {
  //         quote_time_last_modified: quoteTimeLastModified,
  //         quote_time_expired: quoteTimeExpired,
  //         quote_name: quoteName,
  //         quote_address: quoteAddress,
  //         quote_suite: quoteSuite,
  //         quote_city: quoteCity,
  //         quote_prov: quoteProv,
  //         quote_postal: quotePostal,
  //         user_fname: userFname,
  //         user_lname: userLname
  //       } = estimate
  //
  //       const changeOrderTimeSent = object.value.change_order_time_sent
  //       const timeCreated = date(changeOrderTimeSent || quoteTimeLastModified)
  //       const timeExpired = date(quoteTimeExpired)
  //
  //       // Formatting information
  //       const companyText = companyName ? `${companyName} \n` : ''
  //       const userName =
  //         userFname || userLname ? `${l('Attn')}: ${userFname || ''} ${userLname || ''}` : ''
  //       const projectAddressText =
  //         quoteSuite || quoteAddress ? `${quoteSuite || ''} ${quoteAddress || ''} \n` : ''
  //       const project = `${quoteName} \n ${projectAddressText} ${quoteCity || ''} ${quoteProv || ''} ${quotePostal || ''}`
  //       const clientAddressText =
  //         clientSuite || clientAddress ? `${clientSuite || ''} ${clientAddress || ''} \n` : ''
  //       const client = `${companyText} ${userName} \n ${clientAddressText} ${clientCity || ''} ${clientProv || ''} ${clientPostal || ''}`
  //
  //       let logo = c.link(`file/view/${logoFileId.value}`, {}, true, _.getStorage('scope'))
  //       let colLeftHeight = textXY.value.y
  //       let colRightHeight = textXY.value.y
  //
  //       let logoDim = {}
  //       try {
  //         logoDim = await getImageDimWithMax(logo, pageWidth.value * 0.75, 30)
  //       } catch (e) {
  //         logo = null
  //       }
  //       if (logo && logoFileId.value) {
  //         const image = await convertImage(logo).catch((err) => console.error(err))
  //         doc.value.addImage(
  //           image,
  //           'JPEG',
  //           pageMarginX.value,
  //           pageMarginY.value,
  //           logoDim.w,
  //           logoDim.h
  //         )
  //         colLeftHeight += logoDim.h + 8
  //       } else {
  //         txt('h1', companyShortName, pageMarginX.value, textXY.value.y, 'left')
  //         colLeftHeight += getTextHeight(companyShortName, 'h1')
  //       }
  //
  //       if (props && props.length) {
  //         props.forEach((prop) => {
  //           if (prop && prop[0]) {
  //             const credentialString =
  //               prop[0].trim() !== ''
  //                 ? `• ${prop[0]}${prop.length > 1 && typeof prop[1] === 'string' && prop[1] ? `: ${prop[1]}` : ''}`
  //                 : ''
  //             txt('p', credentialString, pageMarginX.value, colLeftHeight, 'left')
  //             colLeftHeight += getTextHeight(credentialString, 'p')
  //           }
  //         })
  //         colLeftHeight += lineHeight.value
  //       }
  //
  //       if (companyPhone) {
  //         txt('p', `${phone(companyPhone)}`, pageMarginX.value, colLeftHeight, 'left')
  //         colLeftHeight += getTextHeight(companyPhone, 'p')
  //       }
  //
  //       if (companyWebsite) {
  //         txt('p', companyWebsite, pageMarginX.value, colLeftHeight, 'left')
  //         colLeftHeight += getTextHeight(companyWebsite, 'p')
  //       }
  //
  //       if (companyEmail) {
  //         txt('p', companyEmail, pageMarginX.value, colLeftHeight, 'left')
  //         colLeftHeight += getTextHeight(companyEmail, 'p')
  //       }
  //
  //       let timesTitle = l('Estimate')
  //       let timesDetails = timeCreated
  //       if (timeExpired) {
  //         timesTitle += ` / ${l('Expires')}`
  //         timesDetails += ` / ${timeExpired}`
  //       }
  //       callout(
  //         timesTitle,
  //         timesDetails,
  //         alignRight.value,
  //         colRightHeight,
  //         'right',
  //         [0, 0, 0],
  //         [0, 0, 0],
  //         'h4'
  //       )
  //       colRightHeight += getTextHeight(timesTitle, 'h4') + getTextHeight(timesDetails, 'p') + 8
  //       callout(
  //         l('Project'),
  //         project,
  //         alignRight.value,
  //         colRightHeight,
  //         'right',
  //         [0, 0, 0],
  //         [0, 0, 0],
  //         'h4',
  //         pageWidth.value * 0.5
  //       )
  //       colRightHeight += getTextHeight(l('Project'), 'h4') + getTextHeight(project, 'p') + 8
  //       callout(
  //         l('Client'),
  //         client,
  //         alignRight.value,
  //         colRightHeight,
  //         'right',
  //         [0, 0, 0],
  //         [0, 0, 0],
  //         'h4'
  //       )
  //       colRightHeight += getTextHeight(l('Client'), 'h4') + getTextHeight(client, 'p') + 8
  //
  //       headerHeight.value = Math.max(colLeftHeight, colRightHeight)
  //       pageLine(headerHeight.value, null)
  //     }
  //   }
  //
  //   const showApprovals = computed(() => object.value.quote_time_booked)
  //
  //   const getChangeOrders = async () => {
  //     const { set } = await store.dispatch('Quote/getChangeOrders', {
  //       id: object.value.quote_id
  //     })
  //     changeOrderList.value = set
  //   }
  //
  //   const getChangeOrderInfo = async (startAt) => {
  //     if (!showApprovals.value) {
  //       return startAt - sectionMargin.value
  //     }
  //
  //     await getChangeOrders()
  //     if (!changeOrderList.value) {
  //       return startAt - sectionMargin.value
  //     }
  //
  //     await addApprovalHistory(startAt)
  //     return null
  //   }
  //
  //   const addApprovalHistory = async (startingPoint) => {
  //     const description = l(`Having read the terms and conditions and \
  // indicated your acceptance by entering your name as a signature in the box provided \
  // and clicking the "I accept the terms and conditions" \
  // button at the bottom of the provided checkout page makes \
  // the acceptance of the agreement binding and means that you are bound by its terms.`) // Truncate for brevity
  //     const descHeight = getTextHeight(description, 'small')
  //     let endLineY = startingPoint + lineHeight.value * 2
  //     const indent = pageMarginX.value + 2
  //     const signatureSpaceNeeded = 10
  //
  //     doc.value.addPage()
  //     endLineY = pageMarginY.value + lineHeight.value
  //     txt('b', l('Approvals History'), pageMarginX.value, endLineY)
  //     endLineY += lineHeight.value
  //     txt('small', description, pageMarginX.value, endLineY)
  //     endLineY += descHeight
  //
  //     if (changeOrderList.value) {
  //       changeOrderList.value.forEach((co) => {
  //         if (
  //           !hasBeenApproved(co) &&
  //           String(co.change_order_id) !== String(object.value.change_order_id)
  //         )
  //           return
  //         if (endLineY + signatureSpaceNeeded > pageHeight.value) {
  //           doc.value.addPage()
  //           endLineY = pageMarginY.value + lineHeight.value
  //         }
  //
  //         const order = `${co.change_order_name} • ${currency(co.quote_price_gross)}`
  //         const version =
  //           String(co.change_order_id) === String(object.value.change_order_id)
  //             ? ` (${l('Version Displayed')})`
  //             : ''
  //
  //         endLineY += lineHeight.value
  //         txt('b', `${order}${version}`, pageMarginX.value, endLineY)
  //
  //         if (hasBeenApproved(co)) {
  //           endLineY += lineHeight.value
  //           txt('small', l('Digital signatures'), indent, endLineY)
  //           endLineY += lineHeight.value * 0.5
  //
  //           // const clientName = co.oClientMeta.user_name
  //           const clientSignature = co.oClientMeta.signature
  //           let orderApproved = ''
  //
  //           if (co.change_order_client_has_approved && co.change_order_client_approved_by) {
  //             orderApproved = `Approved at ${datetime(co.change_order_client_approved_time)} by ${co.oClientMeta.signature} (Client); ${co.oClientMeta?.IP ? `IP: ${co.oClientMeta?.IP}` : ''}` // Truncate for brevity
  //             if (clientSignature) {
  //               endLineY += lineHeight.value
  //               txt('signature', clientSignature, indent, endLineY)
  //               endLineY += lineHeight.value
  //               txt('xs', JSON.stringify(), indent, endLineY)
  //             }
  //           } else {
  //             orderApproved = 'This version was never approved by the client.'
  //           }
  //
  //           txt('small', l(orderApproved), indent, endLineY)
  //         } else {
  //           endLineY += lineHeight.value + 2
  //           txt('small', '• Not approved yet', indent, endLineY)
  //           endLineY += lineHeight.value
  //         }
  //       })
  //     }
  //     return endLineY
  //   }
  //
  //   const friendlyNameTaxOn = (taxOn) => {
  //     if (taxOn === 'all') return l('prices')
  //     if (taxOn === 'cost') return l('costs')
  //     if (taxOn === 'profit') return l('profits')
  //     return taxOn
  //   }
  //
  //   const friendlyNameTaxType = (taxType) => {
  //     if (taxType === 'ihlt') return l('labor')
  //     if (taxType === 'mt') return l('materials')
  //     if (taxType === 'slt') return l('subcontracting')
  //     return l('general')
  //   }
  //
  //   const hasBeenApproved = (co) => {
  //     return (
  //       co.change_order_company_has_approved &&
  //       co.change_order_company_approved_by &&
  //       co.change_order_client_has_approved &&
  //       co.change_order_client_approved_by
  //     )
  //   }
  //
  //   // const getCostItemPrice = (item, meta) => {
  //   //   if (isGallery(item) || isTextBlock(item)) return ''
  //   //   const price = (item.cost_item_price_net_base || item.quote_subtotal_net || 0) * 1
  //   //   let cost =
  //   //     (item.cost_item_total_cost_net_base || item.quote_total_cost_net_base) *
  //   //     artificialMultiplier.value
  //   //
  //   //   if (props.combinedPresentationSettings?.showBundledProfit) {
  //   //     cost = item.cost_item_total_cost_net_base * 1
  //   //   }
  //   //
  //   //   const units = l(String(item.unit_of_measure_abbr || 'each')).toUpperCase()
  //   //   let priceFormatted = l('not included')
  //   //
  //   //   if (meta.qty && meta.qty > 0) {
  //   //     let quantity = ''
  //   //     if (meta.showQty) {
  //   //       quantity = item.cost_type_is_fee ? `1 ${l('each')} • ` : `${number(meta.qty)} ${units} • `
  //   //     }
  //   //     const fullPrice = meta.showPrice ? currency(price) : l('included')
  //   //     priceFormatted = `${quantity}${fullPrice}`
  //   //   }
  //   //
  //   //   if (meta.showCost) {
  //   //     priceFormatted = `${l('Cost')} ${currency(cost)} - ${priceFormatted}`
  //   //   }
  //   //
  //   //   return priceFormatted
  //   // }
  //
  //   const uploadToS3 = async () => {
  //     await renderDoc()
  //     const fileName = `${object.value.change_order_id}`
  //     const key = `${object.value.client_id}/${object.value.quote_id}/${fileName}.pdf`
  //     const file = await doc.value.output('blob')
  //
  //     // Get the pre-signed URL
  //     const { url, fields } = await getPreSignedPost(key)
  //
  //     // Create FormData and append the fields and file
  //     const formData = new FormData()
  //     Object.entries(fields).forEach(([key, value]) => {
  //       formData.append(key, value)
  //     })
  //     formData.append('file', file)
  //
  //     // Upload the file to S3
  //     return await fetch(url, { method: 'POST', body: formData, mode: 'no-cors' })
  //   }
  //
  //   const itemQtyVisibilityCheck = (refId) => {
  //     const viewOptions = getVisibility(refId)
  //     return viewOptions.qty ?? presentationSettings.value.showQuantities
  //   }
  //
  //   const getVisibility = (refId) => {
  //     const item = norm.value[refId]
  //     return { ...(item.oViewOptions?.pres ?? {}), ...(item.oMeta?.viewOptions?.pres ?? {}) }
  //   }
  //
  //   // Check for tasks
  //   const isTask = (item) => getItemType(item) === 'task'
  //
  //   // Ordering Logic
  //   const orderItems = (children, orderedList) => {
  //     children.forEach((item) => {
  //       orderedList.push(item)
  //       if (item.aoChildren) {
  //         if (item.type === 'quote' || itemVisibilityCheck(item.refId)) {
  //           orderItems(item.aoChildren, orderedList)
  //         }
  //       }
  //     })
  //   }
  //
  //   const showLocation = (location) => {
  //     return !overrides.hideLocation && location
  //   }
  //
  //   const showNotes = (meta, productionNotes) => {
  //     return !overrides.hideNotes && meta.showProductionNotes && productionNotes
  //   }
  //
  //   const showInputs = (inputs) => {
  //     return !overrides.hideInputs && inputs
  //   }
  //
  //   const showProperties = (meta) => {
  //     return !overrides.hideProperties && meta.showProperties
  //   }
  //
  //   // const getEmphasis = (item) => {
  //   //   if (item.assembly_emphasis && item.assembly_emphasis < -2) {
  //   //     return false
  //   //   }
  //   //   if (item.cost_type_emphasis && item.cost_type_emphasis < 0) {
  //   //     return false
  //   //   }
  //   //   return true
  //   // }
  //
  //   const createTotals = (startAt) => {
  //     // Check if project totals should be shown
  //     if (!props.presentationSettings?.showProjectTotals) {
  //       return startAt - sectionMargin.value
  //     }
  //
  //     let start = startAt
  //     // Adjust the start position if it exceeds the page height limit
  //     if (startAt > pageHeight.value - 60) {
  //       doc.addPage()
  //       start = pageMarginX.value
  //     }
  //
  //     // Fetch the estimate data
  //     const rootRefId = c.getNormalizedRootRefId(norm.value)
  //     const estimate = norm.value[rootRefId]
  //
  //     // Extract financial data from the estimate
  //     const discount = currency(estimate?.quote_discount_net) || 0
  //     const subTotal = currency(estimate?.quote_price_net) || 0
  //     const total = estimate?.quote_price_gross
  //     const currencyIso = estimate?.currency_iso
  //     const largeLineHeight = lineHeight.value + 2
  //
  //     // Check for management fees
  //     const managementCheck =
  //       estimate?.quote_profit_net >= 0.01 && props.mergedSettings?.showBundledProfit
  //
  //     // Helper function to calculate line positions
  //     const generateLine = (val) =>
  //       managementCheck ? start + largeLineHeight * (val + 1) : start + largeLineHeight * val
  //
  //     const lines = {
  //       one: start + largeLineHeight,
  //       two: managementCheck ? start + largeLineHeight * 2 : start + largeLineHeight,
  //       three: managementCheck ? start + largeLineHeight * 3 : start + largeLineHeight * 2,
  //       four: managementCheck ? start + largeLineHeight * 4 : start + largeLineHeight * 3,
  //       five: managementCheck ? start + largeLineHeight * 5 : start + largeLineHeight * 4
  //     }
  //
  //     // Add management fee information
  //     if (managementCheck) {
  //       const managementFee =
  //         estimate.quote_price_net -
  //         estimate.quote_total_cost_net +
  //         (estimate.quote_discount_net || 0)
  //       const managementText =
  //         typeof props.mergedSettings?.showBundledProfit === 'string'
  //           ? props.mergedSettings.showBundledProfit
  //           : l('Management fee')
  //       txt('h3', managementText, pageMarginX.value, lines.one)
  //       txt('p', currency(managementFee), alignRight.value, lines.one, 'right')
  //     }
  //
  //     let line = generateLine(1)
  //     let lineRef = 2
  //
  //     // Add discount information if applicable
  //     if (estimate.quote_discount_net !== 0) {
  //       txt('h3', l('Discount'), pageMarginX.value, line)
  //       txt('p', discount, alignRight.value, line, 'right')
  //       line = generateLine(2)
  //       lineRef = 3
  //     }
  //
  //     // Add subtotal information
  //     txt('h3', l('Subtotal'), pageMarginX.value, line)
  //     txt('p', subTotal, alignRight.value, line, 'right')
  //
  //     // Add tax information if applicable
  //     const taxes = estimate.oTaxSums
  //     if (taxes && Object.values(taxes).length >= 1) {
  //       Object.values(taxes).forEach((tx) => {
  //         if (tx.sum === 0) return
  //         line = generateLine(lineRef)
  //         txt('h3', `${l(tx.name)}`, pageMarginX.value, line)
  //         txt(
  //           'p',
  //           `${percentage(tx.pcnt)} ${l('tax')} ${l('on')} ${friendlyNameTaxOn(tx.on)} ${l('derived from')} ${friendlyNameTaxType(tx.type)} ${currency(tx.sum)}`,
  //           alignRight.value,
  //           line,
  //           'right'
  //         )
  //         lineRef += 1
  //       })
  //     }
  //
  //     // Add project total information
  //     line = generateLine(lineRef + 1)
  //     txt('h2', l('Project total'), pageMarginX.value, line)
  //     txt('h2', `${currency(total, 2, currencyIso)}`, alignRight.value, line, 'right')
  //
  //     return line
  //   }
  //   const getProjectNotes = (startAt) => {
  //     if (!props.showProductionNotes) return startAt - sectionMargin.value
  //
  //     const height = setProjectNotes(startAt, false)
  //     let startingPoint = startAt
  //
  //     if (startingPoint + height > pageHeight.value) {
  //       doc.addPage()
  //       startingPoint = pageMarginX.value
  //     }
  //
  //     // Add the title
  //     const titleDim = txt(
  //       'h2',
  //       l('Project notes'),
  //       pageMarginX.value,
  //       startingPoint + lineHeight.value
  //     )
  //     const endHeight = setProjectNotes(startingPoint + titleDim.h + lineHeight.value, true)
  //
  //     return startingPoint + endHeight
  //   }
  //
  //   const setTerms = (startingPoint, display = false) => {
  //     const terms =
  //       object.value.change_order_approved_terms ||
  //       ''.concat(
  //         `${store.state.session.company.company_terms_and_conditions || ''}`,
  //         '\n',
  //         `${termsAndConditions.value || ''}`,
  //         '\n',
  //         `${object.value.quote_terms || ''}`
  //       )
  //
  //     const lines = c
  //       .removeHtml(terms)
  //       .replace(/(?:\r\n|\r|\n){2,}/g, '\r\n')
  //       .replace(/\t/g, ' ')
  //       .split(/(?:\r\n|\r|\n)/g)
  //
  //     let height = display ? startingPoint : 0
  //
  //     lines.forEach((l) => {
  //       const line = l.trim()
  //       if (line !== '') {
  //         if (height > pageHeight.value - 30 && display) {
  //           doc.addPage()
  //           height = pageMarginX.value
  //         }
  //
  //         if (/^\[.*?\]/.test(line)) {
  //           const title = line.replace(/\[(.*?)\]/, '$1')
  //           const position = height + lineHeight.value
  //           let textHeight = 0
  //           if (!display) {
  //             textHeight = getTextHeight(title, 'h3')
  //           } else {
  //             const dim = txt('h3', title, pageMarginX.value, position)
  //             textHeight = dim.h
  //           }
  //           height += textHeight + lineHeight.value
  //         } else {
  //           const term = `${line.replace('•', '-')}\n`
  //           const lineSpace = lineHeight.value / 2
  //           const position = height + lineSpace
  //           let textHeight = 0
  //           if (!display) {
  //             textHeight = getTextHeight(term, 'p')
  //           } else {
  //             const dim = txt(
  //               'p',
  //               term,
  //               pageMarginX.value,
  //               position,
  //               'left',
  //               [0, 0, 0],
  //               pageWidth.value * 0.9,
  //               true
  //             )
  //             textHeight = dim.h
  //           }
  //           height += textHeight + lineSpace
  //         }
  //       }
  //     })
  //
  //     return height
  //   }
  //
  //   const setProjectNotes = (startingPoint, display = false) => {
  //     const notes = object.value.quote_notes || ''
  //
  //     const lines = c
  //       .removeHtml(notes)
  //       .replace(/(?:\r\n|\r|\n){2,}/g, '\r\n')
  //       .replace(/\t/g, ' ')
  //       .split(/(?:\r\n|\r|\n)/g)
  //
  //     let height = display ? startingPoint : 0
  //
  //     lines.forEach((l) => {
  //       const line = l.trim()
  //       if (line !== '') {
  //         if (height > pageHeight.value - 30 && display) {
  //           doc.addPage()
  //           height = pageMarginX.value
  //         }
  //
  //         if (/^\[.*?\]/.test(line)) {
  //           const title = line.replace(/\[(.*?)\]/, '$1')
  //           const position = height + lineHeight.value
  //           let textHeight = 0
  //           if (!display) {
  //             textHeight = getTextHeight(title, 'h3')
  //           } else {
  //             const dim = txt('h3', title, pageMarginX.value, position)
  //             textHeight = dim.h
  //           }
  //           height += textHeight + lineHeight.value
  //         } else {
  //           const note = `${line.replace('•', '-')}\n`
  //           const lineSpace = lineHeight.value / 2
  //           const position = height + lineSpace
  //           let textHeight = 0
  //           if (!display) {
  //             textHeight = getTextHeight(note, 'p')
  //           } else {
  //             const dim = txt(
  //               'p',
  //               note,
  //               pageMarginX.value,
  //               position,
  //               'left',
  //               [0, 0, 0],
  //               pageWidth.value * 0.9,
  //               true
  //             )
  //             textHeight = dim.h
  //           }
  //           height += textHeight + lineSpace
  //         }
  //       }
  //     })
  //
  //     return height
  //   }
  //
  //   // Utility functions for formatting
  //   const number = (value) => c.format(value, 'number')
  //   const percentage = (value) => c.format(value, 'percentage')
  //   const currency = (value) => formatCurrencySymbol(value)
  //   const phone = (value) => c.format(value, 'phone')
  //   const datetime = (value) => c.format(value, 'datetime')
  //   const date = (value) => c.format(value, 'date')

  const {
    showItem,
    showPrice,
    showQty,
    showCost,
    termsAndConditions,
    showQuantities,
    showCostItemPrices,
    showItemizedPrices,
    showCosts,
    showSubtotal,
    showItemSpecificTax
  } = usePresentationManager({
    refId: props.refId,
    store: 'Quote',
    autoSave: false,
    $store: $store
  })

  const pdfUrl = ref('')

  const norm = computed(() => $store.state.Quote.normalized)
  const quote = computed(() => $store.state.Quote.normalized[props.refId])

  const refIds = computed(() =>
    NormalizeUtilities.sortNatural(norm.value).filter((ref) => norm.value[ref].type !== 'quote')
  )
  const lineItems = computed(() =>
    refIds.value.map((ref) => {
      const obj = norm.value[ref]
      const depth = obj.depth
      const name = obj.cost_type_name || obj.assembly_name
      const desc = obj.cost_type_desc || obj.quote_notes
      const price = obj.cost_item_price_net || obj.quote_subtotal_net
      const cost = obj.cost_item_total_cost_net || obj.quote_total_cost_net
      const type = obj.type
      const qty = obj.cost_item_qty_net || obj.quote_qty_net
      const units = obj.unit_of_measure_abbr || 'units'
      const inputs =
        obj.oInputRequired?.inputs?.reduce((acc, input) => {
          let accr = ''
          if (input.type === 'text') {
            accr = `${acc ? `${acc}\n` : ''}${input.message}: ${input.input.text}`
          }
          if (input.type === 'color') {
            accr = `${acc ? `${acc}\n` : ''} Color selection - ${input.input.data.vendor || 'Benjamin Moore'} - ${input.input.data.name || ''}`
          }
          return accr
        }, '') ?? ''
      return { depth, name, desc, price, cost, qty, units, inputs, type, refId: obj.refId }
    })
  )

  const topMargin = 15
  const leftMargin = 10
  const indentSize = 3
  const maxWidthExpanded = 200
  const rightCols = [200, 175, 150]
  const defaultLineHeight = 5 // Default vertical line spacing
  const lineSpacing = 3 // Additional space between items

  const drawText = (doc, text = '', x, y, options = {}) => {
    let t = text
    if (typeof text === 'string') {
      t = t.replace(/\u2028|\u2029/g, ' ')
    } else if (Array.isArray(text)) {
      t = t.map((tt) => tt.replace(/\u2028|\u2029/g, ' '))
    }
    doc.text(t, x, y, options)
  }

  const drawLongText = (doc, text, x, y, options = {}) => {
    const { processMarkdown: doMarkdown = false } = options
    const margin = 20
    const maxWidth = doc.internal.pageSize.width - margin * 2
    const lineSpacing = 5
    const pageHeight = doc.internal.pageSize.height
    const bottomMargin = 15 // Space at the bottom before triggering a new page

    let currentY = y
    const paragraphs = text.split(/\n|\r|\r\n/)

    paragraphs.forEach((paragraph) => {
      // Process Markdown and square bracket bolding before wrapping
      const formattedParagraph = doMarkdown ? processMarkdown(paragraph) : paragraph

      const wrappedLines = doc.splitTextToSize(formattedParagraph, maxWidth) // Automatically wrap if too wide

      wrappedLines.forEach((line) => {
        // Check if there's space for the next line; if not, create a new page
        if (currentY + lineSpacing > pageHeight - bottomMargin) {
          doc.addPage()
          currentY = margin // Reset Y position for the new page
        }

        drawFormattedText(doc, line, x, currentY, options)
        currentY += lineSpacing // Move down after each line
      })

      // currentY += lineSpacing // Extra space between paragraphs
    })

    return currentY
  }

  // Helper function to process Markdown and bold square brackets
  const processMarkdown = (text) => {
    return text
      .replace(/\*\*([^*]+)\*\*/g, '<b>$1</b>') // Bold (**text**)
      .replace(/\*([^*]+)\*/g, '<b>$1</b>') // Bold (*text*)
      .replace(/(?:[^_])__([^*]+)__(?:[^_])/g, '<i>$1</i>') // Bold (__text__)
      .replace(/_([^_]+)_/g, '<i>$1</i>') // Italic (_text_)
      .replace(/\[([^\[\]]+)\]/g, '<b>$1</b>') // Bold square brackets [text]
  }

  // Function to draw formatted text (basic HTML-like tags)
  const drawFormattedText = (doc, text, x, y, options = {}) => {
    const parts = text.split(/(<b>|<\/b>|<i>|<\/i>)/)
    let isBold = false
    let isItalic = false
    let currentX = x

    parts.forEach((part) => {
      if (part === '<b>') {
        isBold = true
        doc.setFont(undefined, isItalic ? 'bolditalic' : 'italic')
      } else if (part === '</b>') {
        isBold = false
        doc.setFont(undefined, isItalic ? 'italic' : 'normal')
      } else if (part === '<i>') {
        isItalic = true
        doc.setFont(undefined, isBold ? 'bolditalic' : 'italic')
      } else if (part === '</i>') {
        isItalic = false
        doc.setFont(undefined, isBold ? 'bold' : 'normal')
      } else {
        drawText(doc, part, currentX, y, options)
        currentX += doc.getTextWidth(part) // Move X position for inline formatting
      }
    })

    // Reset font after text drawing
    doc.setFont(undefined, 'normal')
  }

  const drawLine = (doc, x, y, width, lineWidth = 0.5) => {
    // Draw a horizontal line at position (x, y) with the specified width
    doc.setLineWidth(lineWidth) // Set the line width (you can adjust as needed)
    doc.line(x, y, x + width, y) // Draw the line

    // Add some space below the line for the next item
    const newY = y + 5 // Space after the line (adjust as needed)

    return newY // Return the new Y position after the line
  }

  const generateQRCodeAndInject = async (doc, link, label, x, y, size = 50) => {
    // Generate the QR code as a base64 image for the provided link
    const qrCodeData = new QrCodeWithLogo({
      content: link,
      width: 500,
      logo: {
        src: BolsterIcon // Optional logo to embed in the center of the QR code
      }
    })

    const canvas = await qrCodeData.getCanvas()

    // Inject the generated QR code image into the PDF at position (x, y)
    doc.addImage(canvas.toDataURL(), 'JPEG', x, y - 2, size, size) // Adjust size as needed

    // Set the position for the label (just to the right of the QR code)
    const labelX = x + size + 2 // Add 10 units of spacing from the QR code
    const labelY = y + 1 // Vertically centered to the QR code

    // Set font size and style for the label
    doc.setFontSize(8) // Adjust font size as needed

    // Add label text with text wrapping
    const maxWidth = 50 // Max width for text wrapping
    const wrappedText = doc.splitTextToSize(label, maxWidth) // Wrap text

    drawText(doc, wrappedText, labelX, labelY) // Add wrapped text to the PDF

    // Return the new Y coordinate after the QR code and label are added
    return y + size + 1 // Space after the QR code and label
  }

  const generateHeader = async (doc, headerData, yStart) => {
    let y = yStart // Start at the given Y position

    const {
      client: { user_fname: client_fname, user_lname: client_lname },
      company: {
        company_name_short = '', // may not exist
        company_address,
        company_city,
        company_prov,
        company_postal,
        company_unit,
        company_name,
        company_phone,
        company_email
      },
      owner: {
        // the estimator presenting this quote
        user_fname: owner_fname,
        user_lname: owner_lname
      },
      quote: {
        quote_id, // project unique id
        change_order_id, // revision unique ID
        quote_client_ref,
        change_order_name, // revision or change order name
        // change_order_time_created,
        quote_address,
        quote_city,
        quote_prov, // province or state,
        quote_postal, // zip or postal
        quote_unit // suite or unit number
      }
    } = headerData

    let maxY = 0
    const rightMargin = doc.internal.pageSize.width - 10 // Right margin, 10 units from the page right edge

    // ** Company Info (Left Aligned) **
    doc.setFontSize(14)
    doc.setFont('helvetica', 'bold')
    if (company_name) drawText(doc, company_name, leftMargin, y) // Company full name
    y += 7

    if (company_name_short && company_name !== company_name_short) {
      doc.setFontSize(12)
      doc.setFont('helvetica', 'normal')
      drawText(doc, company_name_short, leftMargin, y) // Short company name (optional)
      y += 5
    }
    const companyAddress =
      `${(company_unit && `${company_unit},`) || ''} ${(company_address && `${company_address},`) || ''} ${(company_city && `${company_city},`) || ''} ${(company_prov && `${company_prov},`) || ''} ${(company_postal && `${company_postal}`) || ''}`.trim()

    doc.setFontSize(10)
    doc.setFont('helvetica', 'normal')
    if (companyAddress) {
      drawText(doc, companyAddress, leftMargin, y)
      y += 5
    } // Project address content

    if (company_phone) {
      doc.setFontSize(10)
      doc.setFont('helvetica', 'normal')
      drawText(doc, `Phone: ${c.format(company_phone, 'phone')}`, leftMargin, y) // Company phone
      y += 5
    }

    if (company_email) {
      drawText(doc, `Email: ${company_email}`, leftMargin, y) // Company email
      y += 5
    }

    // ** Owner/Estimator Info (Left Aligned) **
    if (owner_fname && owner_lname) {
      doc.setFontSize(10)
      doc.setFont('helvetica', 'normal')
      drawText(doc, `Estimator: ${owner_fname} ${owner_lname}`, leftMargin, y)
      y += 5
    }

    y = drawLine(doc, leftMargin, y, 100)

    y = await generateQRCodeAndInject(
      doc,
      'https://app.bolsterbuilt.com',
      'Go to your client portal to see your contracts and documents, track progress on the job, approve changes, make payments and chat to us.',
      leftMargin,
      y,
      20
    )
    y = drawLine(doc, leftMargin, y, 100)
    maxY = y
    // if (client_phone) {
    //   doc.setFontSize(10);
    //   doc.setFont("helvetica", "normal");
    //   drawText(doc, `Phone: ${client_phone}`, rightMargin - doc.getTextWidth(`Phone: ${client_phone}`), y); // Client phone
    //   y += 5;
    // }
    //
    // if (client_email) {
    //   drawText(doc, `Email: ${client_email}`, rightMargin - doc.getTextWidth(`Email: ${client_email}`), y); // Client email
    //   y += 10;
    // }

    // ** Project Address (Right Aligned) **
    y = yStart
    if (quote_address || quote_city || quote_prov || quote_postal) {
      doc.setFontSize(12)
      doc.setFont('helvetica', 'bold')
      drawText(doc, `Project`, rightMargin - doc.getTextWidth(`Project`), y) // Project address label
      y += 5

      doc.setFontSize(8)
      doc.setFont('helvetica', 'normal')

      if (quote_client_ref) {
        drawText(
          doc,
          `Project Ref/RFQ#: ${quote_client_ref}`,
          rightMargin - doc.getTextWidth(`Project Ref/RFQ#: ${quote_client_ref}`),
          y
        )
        y += 4
      }

      drawText(
        doc,
        `Project ID: ${quote_id}`,
        rightMargin - doc.getTextWidth(`Project ID: ${quote_id}`),
        y
      )
      y += 4
      drawText(
        doc,
        `Revision: ${change_order_name} - ${change_order_id}`,
        rightMargin - doc.getTextWidth(`Revision: ${change_order_name} - ${change_order_id}`),
        y
      )
      y += 4

      const projectAddress =
        `${(quote_unit && `${quote_unit},`) || ''} ${(quote_address && `${quote_address},`) || ''} ${(quote_city && `${quote_city},`) || ''} ${(quote_prov && `${quote_prov}`) || ''} ${(quote_postal && `${quote_postal}`) || ''}`.trim()

      doc.setFont('helvetica', 'normal')
      if (projectAddress) {
        drawText(doc, projectAddress, rightMargin - doc.getTextWidth(projectAddress), y)
      } // Project address content

      y += 10
    }

    // ** Client Info (Right Aligned) **
    doc.setFontSize(12)
    if (client_fname && client_lname) {
      // Aligning client info to the right
      doc.setFont('helvetica', 'bold')
      drawText(doc, `Client`, rightMargin - doc.getTextWidth(`Client`), y) // Client's full name
      y += 5
      doc.setFont('helvetica', 'normal')
      doc.setFontSize(10)
      drawText(
        doc,
        `${client_fname} ${client_lname}`,
        rightMargin - doc.getTextWidth(`${client_fname} ${client_lname}`),
        y
      )
      y += 7
    }

    return y > maxY ? y : maxY // Return updated Y position for line items
  }

  const generateLineItem = (
    doc,
    item,
    x,
    y,
    { showItemPrice = true, showItemCost = true, showItemQty = true }
  ) => {
    const textX = x
    // Choose the appropriate max width based on the showCost flag
    const maxWidth =
      maxWidthExpanded - (showItemCost ? 20 : 0) - (showItemPrice ? 20 : 0) - (showItemQty ? 20 : 0)

    // Split name and description into multiple lines if needed
    const indent = x
    const nameLines = doc.splitTextToSize(item.name ?? '', maxWidth - indent)

    // Ensure `y` is valid before drawing text
    if (isNaN(y) || y < 0) y = 20

    let left = y
    if (item.type === 'assembly') {
      doc.setFontSize(12)
      doc.setTextColor(100, 100, 100) // Dark grey color
      doc.setFont('helvetica', 'bold')
      left += 2
    } else {
      doc.setFontSize(10)
      doc.setTextColor(0, 0, 0) // Black color
      doc.setFont('helvetica', 'normal')
    }

    // Print name
    drawText(doc, nameLines, textX, left)
    doc.setFontSize(10)
    left += nameLines.length * defaultLineHeight

    // Format the price and cost to always have two decimal places
    let formattedPrice
    if (item.price) formattedPrice = currencySymbol(item.price)
    else if (item.qty) formattedPrice = 'Included'
    else formattedPrice = 'N/A'

    // Format the price and cost to always have two decimal places
    let formattedQty
    if (item.qty) formattedQty = `${_.format(item.qty, 'number')} ${item.units}`
    else formattedQty = 'N/A'

    const formattedCost = showItemCost ? currencySymbol(item.cost) : 'N/A'

    // Calculate right-aligned text positions
    const qtyTextWidth = formattedCost ? doc.getTextWidth(formattedQty) : 0
    const costTextWidth = formattedCost ? doc.getTextWidth(formattedCost) : 0
    const priceTextWidth = doc.getTextWidth(formattedPrice)
    const colPos = [...rightCols]

    // Right-align price and cost by subtracting their width from the margin (page width)
    const rightAlignedPriceX = showItemPrice ? colPos.shift() - priceTextWidth : null
    const rightAlignedCostX = showItemCost ? colPos.shift() - costTextWidth : null
    const rightAlignedQtyX = showItemQty ? colPos.shift() - qtyTextWidth : null

    // Print price (right-aligned)
    if (showItemQty && formattedQty) {
      doc.setFont('helvetica', 'italic')
      drawText(doc, formattedQty, rightAlignedQtyX, y)
    }

    // Print cost (right-aligned) if showCost is true
    if (showItemCost && formattedCost) {
      doc.setFont('helvetica', 'italic')
      drawText(doc, formattedCost, rightAlignedCostX, y)
    }

    // Print price (right-aligned)
    if (showItemPrice) {
      doc.setFont('helvetica', 'bold')
      drawText(doc, formattedPrice, rightAlignedPriceX, y)
    }

    // Print description
    if (item.desc && item.desc.trim()) {
      doc.setTextColor(140, 140, 140) // Dark grey color
      doc.setFont('helvetica', 'normal')
      left = drawLongText(doc, item.desc.trim(), textX, left)
      // drawText(doc, descLines, textX, left)
      doc.setTextColor(0, 0, 0) // Black color
    }

    if (item.inputs) {
      doc.setFontSize(10)
      const inputLines = doc.splitTextToSize(item.inputs ?? '', maxWidth - indent)

      // Print description
      doc.setTextColor(100, 100, 100) // Dark grey color
      doc.setFont('helvetica', 'normal')
      drawText(doc, inputLines, textX, left)
      doc.setTextColor(0, 0, 0) // Black color
      left += inputLines.length * defaultLineHeight
    }

    return left
  }

  const generateLineItems = (doc, lineItems, startY) => {
    let y = startY

    doc.setFontSize(14)
    doc.setFont('helvetica', 'bold')
    drawText(doc, 'Project scope', leftMargin, y)
    doc.setFontSize(10)
    y += defaultLineHeight + lineSpacing // Move down after title

    const other = {
      cost: 0,
      price: 0,
      qty: 1
    }

    // draw headings
    const colPos = [...rightCols]

    // Right-align price and cost by subtracting their width from the margin (page width)
    const rightAlignedPriceX = showItemizedPrices.value ? colPos.shift() : null
    const rightAlignedCostX = showCosts.value ? colPos.shift() : null
    const rightAlignedQtyX = showQuantities.value ? colPos.shift() : null

    // Print price (right-aligned)
    doc.setFontSize(8)
    if (rightAlignedPriceX) {
      doc.setFont('helvetica', 'italic')
      drawText(doc, 'Price', rightAlignedPriceX, y - 6, { align: 'right' })
    }
    if (rightAlignedCostX) {
      doc.setFont('helvetica', 'italic')
      drawText(doc, 'Cost', rightAlignedCostX, y - 6, { align: 'right' })
    }
    if (rightAlignedQtyX) {
      doc.setFont('helvetica', 'italic')
      drawText(doc, 'Qty', rightAlignedQtyX, y - 6, { align: 'right' })
    }
    doc.setFontSize(10)

    let depth = 0
    lineItems.forEach((item) => {
      const itemIsVisible = showItem(item.refId)

      if (!itemIsVisible) {
        if (item.type !== 'assembly' && item.type !== 'quote') {
          other.cost += item.cost
          other.price += item.price
        }
        return
      }

      depth = item.depth ?? depth
      const indent = (depth - 1) * indentSize + (item.type !== 'assembly' ? 2 : 0)
      y = generateLineItem(doc, item, leftMargin + indent, y, {
        showItemCost: item.type !== 'assembly' && showCost(item.refId),
        showItemPrice: item.type !== 'assembly' && showPrice(item.refId),
        showItemQty: item.type !== 'assembly' && showQty(item.refId)
      })

      // Move Y position down for next line item
      y += lineSpacing / 2

      // Prevent text from going off the page (add a new page if needed)
      if (y > 250) {
        // 270px is near bottom of the page
        doc.addPage()
        y = topMargin
      }
    })

    if (other.cost || other.price) {
      y = generateLineItem(
        doc,
        {
          ...other,
          name: 'Other misc',
          type: 'cost_item',
          refId: 'a',
          units: 'each'
        },
        leftMargin,
        y,
        {
          showItemCost: showCosts.value,
          showItemPrice: showItemizedPrices.value && showCostItemPrices.value,
          showItemQty: showQuantities.value
        }
      )
      // Prevent text from going off the page (add a new page if needed)
      if (y > 270) {
        // 270px is near bottom of the page
        doc.addPage()
        y = topMargin
      }
    }

    if (quote.value.quote_notes) {
      y += lineSpacing
      y = drawLongText(doc, quote.value.quote_notes, leftMargin, y, { processMarkdown: true })
    }

    return y
  }

  const drawTotals = (doc, { subTotal = 0, taxes = {}, discount = 0, grandTotal = 0 }, startY) => {
    const xLabel = 100 // X coordinate for labels
    const xValue = 200 // X coordinate for values
    const lineSpacing = 6 // Space between lines

    let y = startY
    // Prevent text from going off the page (add a new page if needed)
    if (y > 250) {
      // 270px is near bottom of the page
      doc.addPage()
      y = topMargin
    }

    doc.setFontSize(10)

    if (showSubtotal.value) {
      doc.setFont('helvetica', 'normal')
      // Draw subtotal
      drawText(doc, 'Subtotal:', xLabel, y)
      drawText(doc, currencySymbol(subTotal), xValue, y, { align: 'right' })
      y += lineSpacing

      // Draw taxes
      if (showItemSpecificTax.value) {
        Object.values(taxes).forEach(({ name, sum }) => {
          if (c.eq(sum, 0)) return
          drawText(doc, `${name}:`, xLabel, y)
          drawText(doc, currencySymbol(sum), xValue, y, { align: 'right' })
          y += lineSpacing
        })
      } else {
        const tax = Object.values(taxes).reduce((acc, { sum }) => {
          acc += sum
          return acc
        }, 0)

        if (!c.eq(tax, 0)) {
          drawText(doc, `Sales tax:`, xLabel, y)
          drawText(doc, currencySymbol(tax), xValue, y, { align: 'right' })
          y += lineSpacing
        }
      }

      // Draw discount if applicable
      if (discount > 0) {
        drawText(doc, 'Discount:', xLabel, y)
        drawText(doc, `- ${currencySymbol(discount)}`, xValue, y, { align: 'right' })
        y += lineSpacing
      }

      // Draw a separator line
      y = drawLine(doc, xLabel, y - 1, 100, 0.1)
    }

    // Draw grand total
    doc.setFont('helvetica', 'bold')
    drawText(doc, 'Grand total:', xLabel, y)
    drawText(doc, currencySymbol(grandTotal), xValue, y, { align: 'right' })

    return y + lineSpacing // Return updated Y position
  }
  const drawTermsAndConditions = (doc, tandc = '', startY) => {
    const margin = 20
    const lineSpacing = 6

    if (!`${tandc}`.trim()) return startY
    doc.addPage()
    startY = topMargin

    doc.setFont('helvetica', 'bold')
    doc.setFontSize(12)
    drawText(doc, 'Terms and conditions', margin, startY)
    startY += lineSpacing

    doc.setFont('helvetica', 'normal')
    doc.setFontSize(10)

    // Split terms into lines where newlines already exist
    startY = drawLongText(doc, tandc, margin, startY, { processMarkdown: true })

    return startY
  }

  const users = {}
  const getUser = async (id) => {
    if (!(id in users)) {
      const { object: user } = await $store.dispatch('User/fetch', { id })
      users[id] = user
    }

    return users[id] ?? false
  }

  const drawApprovals = async (doc, changeOrderList, startY) => {
    const pageMarginX = leftMargin
    const pageMarginY = topMargin
    const pageWidth = doc.internal.pageSize.width
    const pageHeight = doc.internal.pageSize.height
    const lineHeight = 5
    const indent = pageMarginX
    const signatureSpaceNeeded = 20

    let currentY = startY

    // Description text
    const description = translate(
      `Having reviewed the Terms and Conditions and subsequently indicated your acceptance by entering your name in the designated signature box and selecting the ‘I accept the Terms and Conditions’ button at the bottom of the checkout page, you acknowledged and agreed to the terms of this Agreement and the Scope of Work outlined therein. This action constituted your legally binding acceptance of the Agreement, and you were thereby bound by its provisions.`
    )

    // Add a new page for Approvals
    doc.addPage()
    currentY = pageMarginY

    // Title
    doc.setFont('helvetica', 'bold')
    doc.setFontSize(14)
    drawText(doc, translate('Approvals history'), pageMarginX, currentY)
    currentY += lineHeight * 2

    // Description
    doc.setFont('helvetica', 'normal')
    doc.setFontSize(10)
    const wrappedDesc = doc.splitTextToSize(description, pageWidth - 2 * pageMarginX)
    drawText(doc, wrappedDesc, pageMarginX, currentY)
    currentY += wrappedDesc.length * lineHeight

    // Process change orders
    if (changeOrderList && changeOrderList.length) {
      for (const co of changeOrderList) {
        // Page break if needed
        if (currentY + signatureSpaceNeeded > pageHeight - pageMarginY) {
          doc.addPage()
          currentY = pageMarginY
        }

        // Change Order Title
        const orderText = `${co.change_order_name} • ${currencySymbol(co.quote_price_gross)}`
        const versionText =
          String(co.change_order_id) === String(changeOrderList[0].change_order_id)
            ? ` (${translate('Version displayed')})`
            : ''

        doc.setFont('helvetica', 'bold')
        doc.setFontSize(12)
        currentY += lineHeight
        drawText(doc, `${orderText}${versionText}`, pageMarginX, currentY)

        // Approval Details
        if (co.change_order_client_has_approved && co.change_order_client_approved_by) {
          currentY += lineHeight
          doc.setFont('helvetica', 'normal')
          doc.setFontSize(10)

          const sigy = currentY
          // Signature (if exists)
          if (co.oClientMeta?.signature) {
            doc.setFontSize(8)
            drawText(doc, translate('Manually keyed digital signature (Client):'), indent, currentY)
            currentY += lineHeight * 1.5
            doc.setFontSize(24)
            doc.setFont('Whisper-Regular', 'normal')
            drawText(doc, co.oClientMeta.signature, indent, currentY)
            currentY += lineHeight

            doc.setFontSize(8)
            // Approval info
            const clientName = co.oClientMeta?.signature || 'customer'
            const approvalText = translate(
              `Approved at ${new Date(co.change_order_client_approved_time).toISOString()}; \rApproved by ${clientName} (user #${co.change_order_client_approved_by});\r${co.oClientMeta?.IP ? `IP: ${co.oClientMeta?.IP}` : ''}`
            )
            doc.setFont('helvetica', 'normal')
            drawText(doc, approvalText.split('\r'), indent, currentY)
            currentY += lineHeight * 2
          }

          // Signature (if exists)
          const companySigner = await getUser(co.change_order_company_approved_by)
          if (companySigner) {
            currentY = sigy
            const coX = 100
            doc.setFontSize(8)
            drawText(doc, translate('Digitally approved (Company):'), coX, currentY)
            currentY += lineHeight * 1.5
            doc.setFontSize(24)
            doc.setFont('Whisper-Regular', 'normal')
            const coName =
              `${companySigner?.user_fname || ''} ${companySigner?.user_lname || ''}` ||
              'company representative'

            drawText(doc, coName, coX, currentY)
            currentY += lineHeight

            doc.setFontSize(8)
            // Approval info
            const approvalText = translate(
              `Approved at ${new Date(co.change_order_company_approved_time).toISOString()}; \rApproved by ${coName} (user #${co.change_order_company_approved_by});`
            )
            doc.setFont('helvetica', 'normal')
            drawText(doc, approvalText.split('\r'), coX, currentY)
            currentY += lineHeight * 2
          }
        } else {
          currentY += lineHeight
          doc.setFont('helvetica', 'italic')
          drawText(
            doc,
            translate('This version was never approved by the client.'),
            indent,
            currentY
          )
        }

        if (currentY > pageHeight - pageMarginY) {
          doc.addPage()
          currentY = pageMarginY
        }
      }
    }

    return currentY
  }

  const loadFonts = () => {
    const callAddFont = function () {
      this.addFileToVFS('Whisper-Regular-normal.ttf', font)
      this.addFont('Whisper-Regular-normal.ttf', 'Whisper-Regular', 'normal')
    }
    return jsPDF.API.events.push(['addFonts', callAddFont])
  }

  let pdfDoc
  const drawPDF = async (lineItems, tandc = '', approvals = false) => {
    pdfDoc = new jsPDF()
    const doc = pdfDoc

    await loadFonts()

    let y = topMargin // Start position

    y = await generateHeader(
      doc,
      {
        client: quote.value.oClient,
        owner: quote.value.oOwner,
        company: $store.state.session.company,
        quote: quote.value
      },
      y
    )
    y += 4

    y = generateLineItems(doc, lineItems, y)
    y = drawLine(doc, leftMargin, y, 190)
    y += 2
    y = drawTotals(
      doc,
      {
        subTotal: quote.value.quote_price_net_undiscounted,
        discount: quote.value.quote_discount_net,
        grandTotal: quote.value.quote_price_gross,
        taxes: quote.value.oTaxSums
      },
      y
    )

    y = drawTermsAndConditions(doc, tandc, y)

    if (approvals && approvals.length) await drawApprovals(doc, approvals, y)

    const totalPages = doc.internal.getNumberOfPages() // Get total pages
    for (let i = 1; i <= totalPages; i++) {
      doc.setPage(i) // Move to the current page
      doc.setFontSize(10)
      doc.setTextColor(0, 0, 0) // Black color for the page numbers
      drawText(doc, `${i}/${totalPages}`, leftMargin, 290) // Position it at the bottom right
    }

    // Create a Blob URL and update the iframe source
    const pdfBlob = doc.output('blob')
    pdfUrl.value = URL.createObjectURL(pdfBlob)
  }

  const fullTerms = computed(() => {
    const terms =
      (changeOrderIsApproved(quote.value) &&
        `${((quote.value.change_order_approved_terms || '').trim() && `${quote.value.change_order_approved_terms}`) || ''}`) ||
      ''.concat(
        `${($store.state.session.company.company_terms_and_conditions && `${$store.state.session.company.company_terms_and_conditions}\r\n`) || ''}`,
        `${(termsAndConditions.value && `${termsAndConditions.value}`) || ''}`,
        `${(quote.value.quote_terms && `${quote.value.quote_terms}`) || ''}`
      )

    return c
      .removeHtml(terms)
      .replace(/(?:\r\n|\r|\n){2,}/, '\r') // replace multi lines to just one line
      .replace(/\t/g, '  ') // replace tab characters with two spaces
  })

  const changeOrderIsApproved = (co) =>
    co.change_order_status === 'k' &&
    co.change_order_company_has_approved &&
    co.change_order_company_approved_by &&
    co.change_order_client_has_approved &&
    co.change_order_client_approved_by

  const getApprovals = async () => {
    const { set: changeOrders } = await $store.dispatch('Quote/getChangeOrders', {
      id: quote.value.quote_id
    })

    return changeOrders.filter((co) => changeOrderIsApproved(co))
  }

  const renderDoc = async () => {
    const approvals = quote.value.quote_status !== 'p' ? await getApprovals() : false
    await drawPDF(lineItems.value, fullTerms.value, approvals)
    return pdfDoc
  }

  const uploadToS3 = async () => {
    if (!pdfDoc) throw new Error('call renderDoc first')
    const fileName = `${quote.value.change_order_id}`
    const key = `${quote.value.client_id}/${quote.value.quote_id}/${fileName}.pdf`
    const file = await pdfDoc.output('blob')

    // Get the pre-signed URL
    const { url, fields } = await getPreSignedPost(key)

    // Create FormData and append the fields and file
    const formData = new FormData()
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value)
    })
    formData.append('file', file)

    // Upload the file to S3
    return await fetch(url, { method: 'POST', body: formData, mode: 'no-cors' })
  }

  const save = async () => {
    if (!pdfDoc) throw new Error('call renderDoc first')
    pdfDoc.save(`${quote.value.quote_name}-${quote.value.change_order_name}.pdf`)
  }

  const getBlob = () => {
    if (!pdfDoc) throw new Error('call renderDoc first')
    return pdfDoc.output('blob')
  }

  const getObjectUrl = () => {
    return URL.createObjectURL(getBlob())
  }

  return {
    renderDoc,
    save,
    uploadToS3,
    getBlob,
    getObjectUrl
  }
}
