import { client } from '@/apollo-client/ApolloClients'
import indexDbClient from '../../../indexDb-client'
import _ from '../../../../imports/api/Helpers'
import { v4 } from 'uuid'
import {
  getChannelActivities,
  getNotificationsByUser,
  postChannelUserOnline,
  removeChannelUserOnline,
  removeNotification,
  subscribeToChannelActivities,
  subscribeToNotifications,
  postActivity,
  updateActivity,
  removeActivity,
  postMemberActivity,
  getFilePreSignedPost
} from '@/apollo-client/api-calls/activity'
import { postPushNotificationSubscription } from '@/apollo-client/api-calls/pushNotificationSubscription'
import {
  getChannel,
  getChannelByType,
  getChannelsByUserId,
  getChannelMember,
  postChannel,
  postChannelMember,
  postChannelMembers,
  subscribeToChannelMembers,
  removeChannelMembers,
  updateChannelMember,
  updateChannel,
  getChannelsByParent,
  getChannelsByCompanyCompany
} from '@/apollo-client/api-calls/channel'
import {
  SET_CHANNELS_LOADING,
  SET_CHANNEL_OBSERVER,
  SET_CHANNELS,
  SET_HAS_NOTIFICATIONS,
  SET_NOTIFICATION_OBSERVER,
  SET_NOTIFICATIONS,
  SET_NOTIFICATIONS_OBJECT,
  SET_CHANNEL_NOTIFICATIONS,
  SET_CHANNEL_NOTIFICATIONS_OBJECT,
  ADD_CHANNEL_NOTIFICATION,
  ADD_CHANNEL_NOTIFICATION_OBJECT,
  ADD_CHANNEL,
  REMOVE_CHANNEL,
  SET_CHANNEL_ACTIVITIES_OBSERVER,
  SET_CHANNEL_ACTIVITIES_CONNECTED,
  SET_CHANNEL_ACTIVITIES_NEXT_TOKEN,
  SET_CHANNEL_ACTIVITIES_LEGACY_NEXT_TOKEN,
  SET_CHANNEL_HAS_MORE_ACTIVITIES,
  SET_CHANNEL_HAS_MORE_LEGACY_ACTIVITIES,
  ADD_CHANNEL_ACTIVITY,
  PUSH_CHANNEL_ACTIVITY,
  SET_CHANNEL_ACTIVITIES,
  ADD_SEEN_ACTIVITY,
  ADD_CHANNELS,
  REMOVE_CHANNELS,
  UPDATE_CHANNEL_ACTIVITY,
  REMOVE_CHANNEL_ACTIVITY
} from '@/store/mutation-types'

// initial state
const state = {
  channels: {},
  channelNotifications: {},
  channelNotificationsObject: {},
  channelActivities: {},
  channelObserver: null,
  notificationObserver: null,
  activitiesObserver: null,
  activitiesObserverConnected: false,
  hasNotifications: false,
  channelsLoading: false,
  channelNextToken: {},
  hasMoreActivities: {},
  channelLegacyNextToken: {},
  hasMoreLegacyActivities: {},
  seenActivities: {}
}

// methods //
const binarySearchArrayPosition = (arr, item, searchKeyFunc) => {
  let left = 0
  let right = arr.length - 1

  while (left <= right) {
    let mid = Math.floor((left + right) / 2)

    if (searchKeyFunc(arr[mid]) === searchKeyFunc(item)) {
      return mid
    } else if (searchKeyFunc(arr[mid]) < searchKeyFunc(item)) {
      left = mid + 1
    } else {
      right = mid - 1
    }
  }

  return Math.max(left, right)
}

const insertIntoSortedActivityArray = (activity, activities) => {
  const insertIndex = binarySearchArrayPosition(
    activities,
    activity,
    (activity) => activity.activity_time_created
  )
  activities.splice(insertIndex, 0, activity)
  return insertIndex
}

const insertIntoSortedNotificationsArray = (notification, notifications) => {
  const insertIndex = binarySearchArrayPosition(
    notifications,
    notification,
    (notification) => notification.activity.activity_time_created
  )
  notifications.splice(insertIndex, 0, notification)
}

// getters
const getters = {
  getChannelActivities: (state) => (channelId) => {
    return state.channelActivities[channelId]
  },
  getChannelHasMoreActivities: (state) => (channelId) => {
    return !(channelId in state.hasMoreActivities) || state.hasMoreActivities[channelId]
  },
  getChannelHasMoreLegacyActivities: (state) => (channelId) => {
    return !(channelId in state.hasMoreLegacyActivities) || state.hasMoreLegacyActivities[channelId]
  }
}

// actions
const actions = {
  async postPushNotificationSubscription(options, { userId, subscription }) {
    const { data } = await client().mutate({
      mutation: postPushNotificationSubscription,
      variables: {
        input: {
          user_id: String(userId),
          topic: 'notification',
          subscription: JSON.stringify(subscription)
        }
      }
    })

    return data.postPushNotificationSubscription
  },
  async postActivityFiles({ dispatch }, { files }) {
    const fileIds = Promise.all(
      files.map(async (file) => {
        const { url, fields } = await dispatch('getFilePreSignedPostUrl', {
          fileType: file.type
        })
        const formData = new FormData()
        const fileId = fields.key

        Object.entries({ ...fields, file }).forEach(([key, value]) => {
          formData.append(key, value)
        })

        await fetch(url, {
          method: 'POST',
          body: formData,
          mode: 'no-cors'
        })

        return fileId
      })
    )

    files.forEach((file) => URL.revokeObjectURL(file.objectURL))
    return fileIds
  },
  async postActivity(
    { commit, rootState, dispatch },
    {
      channelId,
      activityMessage,
      activityMentions = [],
      files = [],
      parentActivity,
      parentActivityId = ''
    }
  ) {
    const activityTag = v4()
    const activity = {
      channel_id: channelId,
      activity_notification_heading: activityMessage,
      activity_base_type: 'MESSAGE',
      activity_creator: (
        rootState.session.authorizedUser.user_id || rootState.session.user.user_id
      ).toString(),
      activity_owner: (
        rootState.session.authorizedUser.user_id || rootState.session.user.user_id
      ).toString(),
      activity_type: 'Message',
      activity_is_version: false,
      parent_activity_id: parentActivityId,
      company_id: rootState.session.scope.company.toString(),
      file_ids: files,
      target_type: 'Message',
      activity_mentions: activityMentions,
      oTarget: JSON.stringify({
        message: activityMessage,
        tag: activityTag
      }),
      oCreator: JSON.stringify({
        user_id: (
          rootState.session.authorizedUser.user_id || rootState.session.user.user_id
        ).toString(),
        user_email: String(
          rootState.session.authorizedUser.user_email || rootState.session.user.user_email
        ),
        user_fname: String(
          rootState.session.authorizedUser.user_fname || rootState.session.user.user_fname
        ),
        user_lname: String(
          rootState.session.authorizedUser.user_lname || rootState.session.user.user_lname
        ),
        user_phone: String(
          rootState.session.authorizedUser.user_phone || rootState.session.user.user_phone
        )
      }),
      oOwner: JSON.stringify({
        user_id: rootState.session.authorizedUser.user_id.toString(),
        user_email: String(
          rootState.session.authorizedUser.user_email || rootState.session.user.user_email
        ),
        user_fname: String(
          rootState.session.authorizedUser.user_fname || rootState.session.user.user_fname
        ),
        user_lname: String(
          rootState.session.authorizedUser.user_lname || rootState.session.user.user_lname
        ),
        user_phone: String(
          rootState.session.authorizedUser.user_phone || rootState.session.user.user_phone
        )
      })
    }

    commit(PUSH_CHANNEL_ACTIVITY, {
      channelId,
      activity: {
        ...activity,
        temp: true,
        has_member_viewed: false,
        activity_time_created: Date.now() / 1000,
        activity_id: activityTag,
        parent_activity: parentActivity,
        oTarget: JSON.parse(activity.oTarget),
        oCreator: JSON.parse(activity.oCreator),
        oOwner: JSON.parse(activity.oOwner)
      }
    })

    await dispatch('updateChannelLatestActivity', {
      channelId,
      activity: {
        ...activity,
        temp: true,
        has_member_viewed: false,
        activity_time_created: Date.now() / 1000,
        activity_id: activityTag,
        parent_activity: parentActivity,
        oTarget: JSON.parse(activity.oTarget),
        oCreator: JSON.parse(activity.oCreator),
        oOwner: JSON.parse(activity.oOwner)
      }
    })

    activity.file_ids = await dispatch('postActivityFiles', {
      files
    })

    const slowRequestTimeout = setTimeout(() => {
      dispatch(
        'alert',
        {
          error: true,
          message: 'Request is taking longer than usual.'
        },
        { root: true }
      )
    }, 15000)

    const { data } = await client().mutate({
      mutation: postActivity,
      variables: {
        activity
      }
    })

    clearTimeout(slowRequestTimeout)

    const member = await indexDbClient.get(
      'member',
      `${rootState.session.scope.user.toString()}-${channelId}`
    )
    if (member) {
      const transaction = await indexDbClient.getTransaction('member')
      await transaction.store.put({
        ...member,
        channel: {
          ...member.channel,
          latest_channel_activity: {
            channel_activity_id: `${channelId}-${data.postActivity.activity_id}`,
            channel_id: channelId,
            activity_id: data.postActivity.activity_id,
            activity_created_at: data.postActivity.activity_time_created,
            activity: data.postActivity
          }
        }
      })
      await transaction.done
    }

    return data.postActivity
  },
  async editActivity(
    {
      // commit,
      rootState,
      dispatch
    },
    {
      channelId,
      activityMessage,
      editActivityId,
      activityMentions = [],
      files = [],
      parentActivityId = ''
      // parentActivity = {}
    }
  ) {
    const activityTag = v4()
    const activity = {
      channel_id: channelId,
      activity_notification_heading: activityMessage,
      activity_base_type: 'MESSAGE',
      activity_creator: (
        rootState.session.authorizedUser.user_id || rootState.session.user.user_id
      ).toString(),
      activity_owner: (
        rootState.session.authorizedUser.user_id || rootState.session.user.user_id
      ).toString(),
      activity_type: 'Message',
      activity_is_version: false,
      parent_activity_id: parentActivityId,
      company_id: rootState.session.scope.company.toString(),
      file_ids: files,
      target_type: 'Message',
      activity_mentions: activityMentions,
      oTarget: JSON.stringify({
        message: activityMessage,
        tag: activityTag
      }),
      oCreator: JSON.stringify({
        user_id: (
          rootState.session.authorizedUser.user_id || rootState.session.user.user_id
        ).toString(),
        user_email: String(
          rootState.session.authorizedUser.user_email || rootState.session.user.user_email
        ),
        user_fname: String(
          rootState.session.authorizedUser.user_fname || rootState.session.user.user_fname
        ),
        user_lname: String(
          rootState.session.authorizedUser.user_lname || rootState.session.user.user_lname
        ),
        user_phone: String(
          rootState.session.authorizedUser.user_phone || rootState.session.user.user_phone
        )
      }),
      oOwner: JSON.stringify({
        user_id: rootState.session.authorizedUser.user_id.toString(),
        user_email: String(
          rootState.session.authorizedUser.user_email || rootState.session.user.user_email
        ),
        user_fname: String(
          rootState.session.authorizedUser.user_fname || rootState.session.user.user_fname
        ),
        user_lname: String(
          rootState.session.authorizedUser.user_lname || rootState.session.user.user_lname
        ),
        user_phone: String(
          rootState.session.authorizedUser.user_phone || rootState.session.user.user_phone
        )
      })
    }

    activity.file_ids = await dispatch('postActivityFiles', {
      files
    })

    const { data } = await client().mutate({
      mutation: updateActivity,
      variables: {
        activityId: editActivityId,
        activity
      }
    })

    return data.updateActivity
  },
  async updateActivity(
    { rootState, dispatch },
    {
      channelId,
      activityId,
      activityMessage,
      activityMentions = [],
      files = [],
      parentActivityId = ''
    }
  ) {
    const activity = {
      channel_id: channelId,
      activity_notification_heading: activityMessage,
      activity_base_type: 'MESSAGE',
      activity_creator: (
        rootState.session.authorizedUser.user_id || rootState.session.user.user_id
      ).toString(),
      activity_owner: (
        rootState.session.authorizedUser.user_id || rootState.session.user.user_id
      ).toString(),
      activity_type: 'Message',
      activity_is_version: false,
      parent_activity_id: parentActivityId,
      company_id: rootState.session.scope.company.toString(),
      file_ids: files,
      target_type: 'Message',
      activity_mentions: activityMentions,
      oTarget: JSON.stringify({
        message: activityMessage
      }),
      oCreator: JSON.stringify({
        user_id: (
          rootState.session.authorizedUser.user_id || rootState.session.user.user_id
        ).toString(),
        user_email: String(
          rootState.session.authorizedUser.user_email || rootState.session.user.user_email
        ),
        user_fname: String(
          rootState.session.authorizedUser.user_fname || rootState.session.user.user_fname
        ),
        user_lname: String(
          rootState.session.authorizedUser.user_lname || rootState.session.user.user_lname
        ),
        user_phone: String(
          rootState.session.authorizedUser.user_phone || rootState.session.user.user_phone
        )
      }),
      oOwner: JSON.stringify({
        user_id: rootState.session.authorizedUser.user_id.toString(),
        user_email: String(
          rootState.session.authorizedUser.user_email || rootState.session.user.user_email
        ),
        user_fname: String(
          rootState.session.authorizedUser.user_fname || rootState.session.user.user_fname
        ),
        user_lname: String(
          rootState.session.authorizedUser.user_lname || rootState.session.user.user_lname
        ),
        user_phone: String(
          rootState.session.authorizedUser.user_phone || rootState.session.user.user_phone
        )
      })
    }

    activity.file_ids = await dispatch('postActivityFiles', {
      files
    })

    const { data } = await client().mutate({
      mutation: updateActivity,
      variables: {
        activityId: activityId,
        input: activity
      }
    })

    return data.updateActivity
  },
  async removeActivity(options, { activityId, channelId }) {
    const { data } = await client().mutate({
      mutation: removeActivity,
      variables: {
        activityId,
        channelId
      }
    })

    return data.removeActivity
  },
  async postMemberActivity({ state, commit }, { activityId, userId }) {
    if (activityId in state.seenActivities) return

    commit(ADD_SEEN_ACTIVITY, activityId)

    const { data } = await client().mutate({
      mutation: postMemberActivity,
      variables: {
        input: {
          activity_id: activityId,
          user_id: userId
        }
      }
    })

    return data.postMemberActivity
  },
  async getChannelActivities(
    { state, dispatch, commit },
    { channelId, userId, limit = null, nextToken = null, filters = null }
  ) {
    const nToken = nextToken || state.channelNextToken[channelId] || null
    const hasMoreActivities =
      !(channelId in state.hasMoreActivities) || state.hasMoreActivities[channelId]

    if (hasMoreActivities) {
      try {
        const { data } = await client().query({
          query: getChannelActivities,
          variables: {
            input: {
              channel_id: channelId,
              limit,
              nextToken: nToken,
              filters: _.generateAppsyncFilterPattern(filters)
            },
            userInput: {
              user_id: userId
            }
          }
        })

        const activities = await Promise.all(
          data.getChannelActivities.items.map(async (activity) =>
            dispatch(
              'Activity/convertAppsyncActivityFields',
              {
                activity: {
                  ...activity.activity,
                  has_member_viewed: activity.has_member_viewed
                }
              },
              { root: true }
            )
          )
        )
        const resNextToken = data.getChannelActivities.nextToken
        const currentActivities =
          !state.channelNextToken[channelId] && state.hasMoreActivities[channelId]
            ? []
            : state.channelActivities[channelId]

        commit(SET_CHANNEL_ACTIVITIES_NEXT_TOKEN, { channelId, nextToken: resNextToken })
        commit(SET_CHANNEL_HAS_MORE_ACTIVITIES, { channelId, hasNextToken: !!resNextToken })
        commit(SET_CHANNEL_ACTIVITIES, {
          channelId,
          activities: [...activities.reverse(), ...(currentActivities || [])]
        })

        return {
          set: activities,
          nextToken: resNextToken
        }
      } catch (e) {
        console.error(e)
        await dispatch(
          'alert',
          {
            message: 'Failed to fetch activities, please try again.',
            error: true
          },
          { root: true }
        )
      }
    }

    return {
      set: [],
      nextToken: null
    }
  },
  clearChannelActivities({ commit }, { channelId }) {
    commit(SET_CHANNEL_ACTIVITIES_NEXT_TOKEN, { channelId, nextToken: null })
    commit(SET_CHANNEL_ACTIVITIES_LEGACY_NEXT_TOKEN, { channelId, nextToken: null })
    commit(SET_CHANNEL_ACTIVITIES, { channelId, activities: [] })
    commit(SET_CHANNEL_HAS_MORE_ACTIVITIES, { channelId, hasNextToken: true })
    commit(SET_CHANNEL_HAS_MORE_LEGACY_ACTIVITIES, { channelId, hasNextToken: true })
  },
  async getChannelLegacyActivities(
    { state, dispatch, commit },
    { channelType, channelTypeId, limit = null, nextToken = null }
  ) {
    const channelId = `${channelType}-${channelTypeId}`
    const nToken = nextToken || state.channelLegacyNextToken[channelId] || null
    const hasMoreActivities =
      !(channelId in state.hasMoreLegacyActivities) || state.hasMoreLegacyActivities[channelId]

    if (hasMoreActivities) {
      try {
        const { set: activities, nextToken: resNextToken } = await dispatch(
          'Activity/getActivitiesByTarget',
          {
            targetId: channelTypeId,
            limit,
            nextToken: nToken,
            filters: {
              target_type: channelType.toLowerCase()
            }
          },
          {
            root: true
          }
        )

        commit(SET_CHANNEL_ACTIVITIES_LEGACY_NEXT_TOKEN, { channelId, nextToken: resNextToken })
        commit(SET_CHANNEL_HAS_MORE_LEGACY_ACTIVITIES, { channelId, hasNextToken: !!resNextToken })
        commit(SET_CHANNEL_ACTIVITIES, {
          channelId,
          activities: [...activities.reverse(), ...(state.channelActivities[channelId] || [])]
        })

        return {
          set: activities,
          nextToken: resNextToken
        }
      } catch (e) {
        console.error(e)
        await dispatch(
          'alert',
          {
            message: 'Failed to fetch activities, please try again.',
            error: true
          },
          { root: true }
        )
      }
    }

    return {
      set: [],
      nextToken: null
    }
  },
  async getNotificationsByUser(
    { dispatch },
    { userId, activityCreatedAt, limit = null, nextToken = null, filters = null }
  ) {
    try {
      const { data } = await client().query({
        query: getNotificationsByUser,
        variables: {
          input: {
            user_id: userId,
            activity_created_at: activityCreatedAt,
            limit,
            nextToken,
            filters: _.generateAppsyncFilterPattern(filters)
          }
        },
        fetchPolicy: 'no-cache'
      })

      return data.getNotificationsByUser
    } catch (err) {
      console.error(err)
      await dispatch(
        'alert',
        {
          message: 'Failed to fetch notifications',
          error: true
        },
        { root: true }
      )
    }
  },
  async getAllNotificationsByUser({ dispatch, state, commit }, { userId }) {
    let nextToken = null
    let channelNotifications = _.imm(state.channelNotifications)
    let channelNotificationsObject = _.imm(state.channelNotificationsObject)
    let hasNotifications = _.imm(state.hasNotifications)

    const notifications = await indexDbClient.getAllFromIndex('notification', 'activity_created_at')
    const activityCreatedAt = notifications.length
      ? (notifications[notifications.length - 1].activity_created_at.getTime() / 1000).toString()
      : null
    notifications.forEach((item) => {
      if (!channelNotifications[item.channel_id]) channelNotifications[item.channel_id] = []
      if (!channelNotificationsObject[item.channel_id])
        channelNotificationsObject[item.channel_id] = {}
      channelNotifications[item.channel_id].push(item)
      channelNotificationsObject[item.channel_id][item.activity_id] = true
    })

    do {
      const data = await dispatch('getNotificationsByUser', {
        userId,
        activityCreatedAt,
        limit: 50,
        nextToken
      })

      const transaction = await indexDbClient.getTransaction('notification')
      nextToken = data.nextToken

      if (data.items && data.items.length) {
        hasNotifications = true

        for (const item of data.items) {
          if (!channelNotifications[item.channel_id]) channelNotifications[item.channel_id] = []
          if (!channelNotificationsObject[item.channel_id])
            channelNotificationsObject[item.channel_id] = {}
          channelNotifications[item.channel_id].unshift(item)
          channelNotificationsObject[item.channel_id][item.activity_id] = true
          await transaction.store.put({
            ...item,
            activity_created_at: new Date(Number(item.activity_created_at) * 1000),
            notification_id: `${item.activity_id}_${item.user_id}`
          })
        }
        await transaction.done
      }
    } while (nextToken)

    const channels = []
    Object.keys(channelNotifications).forEach((channelId) => {
      if (!state.channels[channelId]) {
        const channel = channelNotifications[channelId][0].channel || null
        if (channel) {
          channels.push(channel)
        }
      }
    })

    commit(SET_HAS_NOTIFICATIONS, hasNotifications)
    commit(SET_NOTIFICATIONS, channelNotifications)
    commit(SET_NOTIFICATIONS_OBJECT, channelNotificationsObject)
    commit(ADD_CHANNELS, channels)

    return channelNotifications
  },
  async removeNotification({ state, commit }, { channelId, activityId, userId }) {
    if (state.channelNotifications[channelId]) {
      const channelNotifications = state.channelNotifications[channelId].filter(
        (notification) => notification.activity_id !== activityId
      )
      const channelNotificationsObject = _.imm(state.channelNotificationsObject)
      delete channelNotificationsObject[channelId][activityId]

      commit(SET_CHANNEL_NOTIFICATIONS, { channelId, notifications: channelNotifications })
      commit(SET_CHANNEL_NOTIFICATIONS_OBJECT, {
        channelId,
        notificationsObject: channelNotificationsObject
      })
    }

    await indexDbClient.remove('notification', `${activityId}_${userId}`)
    const { data } = await client().mutate({
      mutation: removeNotification,
      variables: {
        input: {
          user_id: userId,
          activity_id: activityId
        }
      }
    })

    return data.removeNotification
  },
  async postChannelUserOnline(params, { channelId, userId }) {
    const { data } = await client().mutate({
      mutation: postChannelUserOnline,
      variables: {
        input: {
          channel_id: channelId,
          user_id: userId
        }
      }
    })

    return data.postChannelUserOnline
  },
  async removeChannelUserOnline(params, { channelId, userId }) {
    const { data } = await client().mutate({
      mutation: removeChannelUserOnline,
      variables: {
        input: {
          channel_id: channelId,
          user_id: userId
        }
      }
    })

    return data.removeChannelUserOnline
  },
  async subscribeToNotifications({ dispatch, commit }, { userId, toastService }) {
    const observer = client().subscribe({
      query: subscribeToNotifications,
      variables: {
        userId
      }
    })

    try {
      const notificationObserver = observer.subscribe({
        async next(data) {
          const notification = data.data.onSavedNotification
          if (!notification) return
          notification.activity = await dispatch(
            'Activity/convertAppsyncActivityFields',
            { activity: notification.activity },
            { root: true }
          )

          commit(ADD_CHANNEL_NOTIFICATION, notification)
          commit(ADD_CHANNEL_NOTIFICATION_OBJECT, notification)
          commit(ADD_CHANNEL, {
            ...notification.channel,
            latest_channel_activity: {
              channel_activity_id: `${notification.channel_id}-${notification.activity_id}`,
              channel_id: notification.channel_id,
              activity_id: notification.activity_id,
              activity_created_at: notification.activity_created_at,
              activity: notification.activity
            }
          })
          commit(SET_HAS_NOTIFICATIONS, true)

          await indexDbClient.put('notification', {
            ...notification,
            activity_created_at: new Date(Number(notification.activity_created_at) * 1000),
            notification_id: `${notification.activity_id}_${notification.user_id}`
          })

          const member = await indexDbClient.get('member', `${userId}-${notification.channel_id}`)
          if (member) {
            const transaction = await indexDbClient.getTransaction('member')
            await transaction.store.put({
              ...member,
              channel: {
                ...member.channel,
                latest_channel_activity: {
                  channel_activity_id: `${notification.channel_id}-${notification.activity.activity_id}`,
                  channel_id: notification.channel_id,
                  activity_id: notification.activity.activity_id,
                  activity_created_at: notification.activity_created_at,
                  activity: notification.activity
                }
              }
            })
            await transaction.done
          }

          toastService.add({
            severity: 'contrast',
            summary: notification.activity.activity_notification_heading,
            detail: notification,
            group: 'notification',
            life: 5000
          })
        },
        complete(com) {
          console.log(com)
        },
        error(err) {
          console.error(err)
          setTimeout(() => dispatch('subscribeToNotifications', { userId, toastService }), 5000)
        }
      })

      commit(SET_NOTIFICATION_OBSERVER, notificationObserver)
    } catch (err) {
      console.error(err)
      await dispatch(
        'alert',
        {
          message: 'Failed to attach notification listener',
          error: true
        },
        { root: true }
      )
    }
  },
  unsubscribeToNotifications({ commit }) {
    commit(SET_NOTIFICATION_OBSERVER, null)
  },
  async subscribeToChannelActivities({ dispatch, commit, state }, { channelId, userId }) {
    const observer = client().subscribe({
      query: subscribeToChannelActivities,
      variables: {
        channelId,
        userInput: {
          user_id: userId
        }
      }
    })

    try {
      commit(SET_CHANNEL_ACTIVITIES_CONNECTED, true)

      const activitiesObserver = observer.subscribe({
        async next(data) {
          const channelActivity = data.data.onSavedChannelActivity
          if (!channelActivity) return

          const activity = await dispatch(
            'Activity/convertAppsyncActivityFields',
            {
              activity: {
                ...channelActivity.activity,
                has_member_viewed: channelActivity.has_member_viewed
              }
            },
            { root: true }
          )

          if (channelActivity.channel_activity_id === 'REMOVED') {
            return commit(REMOVE_CHANNEL_ACTIVITY, {
              channelId,
              activityId: channelActivity.activity_id
            })
          }

          const foundActivity = state.channelActivities[channelId]?.findIndex(
            (a) => a.activity_id === activity.activity_id
          )

          if (_.isNumber(foundActivity) && foundActivity >= 0) {
            return commit(UPDATE_CHANNEL_ACTIVITY, {
              activity,
              channelId,
              index: foundActivity
            })
          }

          commit(ADD_CHANNEL_ACTIVITY, {
            channelId: channelActivity.channel_id,
            activity
          })

          await dispatch('updateChannelLatestActivity', {
            channelId: channelActivity.channel_id,
            activity
          })

          const member = await indexDbClient.get(
            'member',
            `${userId}-${channelActivity.channel_id}`
          )
          if (member) {
            const transaction = await indexDbClient.getTransaction('member')
            await transaction.store.put({
              ...member,
              channel: {
                ...member.channel,
                latest_channel_activity: {
                  channel_activity_id: `${channelActivity.channel_id}-${activity.activity_id}`,
                  channel_id: channelActivity.channel_id,
                  activity_id: activity.activity_id,
                  activity_created_at: activity.activity_time_created,
                  activity
                }
              }
            })
            await transaction.done
          }
        },
        complete(com) {
          console.log(com)
        },
        error(err) {
          console.error(err)
          commit(SET_CHANNEL_ACTIVITIES_CONNECTED, false)
        }
      })

      commit(SET_CHANNEL_ACTIVITIES_OBSERVER, activitiesObserver)
    } catch (err) {
      console.error(err)
      await dispatch(
        'alert',
        {
          message: 'Failed to attach channel activity listener',
          error: true
        },
        { root: true }
      )
    }
  },
  unsubscribeFromChannelActivities({ commit }) {
    commit(SET_CHANNEL_ACTIVITIES_OBSERVER, null)
  },
  async getChannel(options, { channelId }) {
    try {
      const { data } = await client().query({
        query: getChannel,
        variables: {
          input: {
            channel_id: channelId
          }
        }
      })

      return data.getChannel
    } catch (e) {
      console.error(e)
    }
  },
  async getChannelByType({ commit }, { channelType, channelTypeId }) {
    let data
    try {
      ;({ data } = await client().query({
        query: getChannelByType,
        variables: {
          input: {
            channel_type: channelType,
            channel_type_id: channelTypeId
          }
        }
      }))

      if (data.getChannelByType) {
        commit(ADD_CHANNEL, data.getChannelByType)
      }

      return data.getChannelByType
    } catch (err) {
      if (!import.meta.env.PROD) {
        console.debug(err.message, getChannelByType, data, {
          channel_type: channelType,
          channel_type_id: channelTypeId
        })
      }
      return null
    }
  },
  async getChannelsByCompanyCompany(omit, { companyId }) {
    const { data } = await client().query({
      query: getChannelsByCompanyCompany,
      variables: {
        input: {
          company_id: companyId
        }
      }
    })

    return data.getChannelsByCompanyCompany
  },
  async getChannelsByParent(
    omit,
    { parentChannelId, nextToken = null, limit = 20, filters = null }
  ) {
    const { data } = await client().query({
      query: getChannelsByParent,
      variables: {
        parent_channel_id: parentChannelId,
        limit,
        nextToken,
        filters: _.generateAppsyncFilterPattern(filters)
      }
    })

    return data.getChannelsByParent
  },
  async getChannelsByUser(
    { dispatch },
    { userId, createdAt = null, nextToken = null, limit = 20, filters = null }
  ) {
    try {
      const { data } = await client().query({
        query: getChannelsByUserId,
        variables: {
          input: {
            user_id: userId,
            created_at: createdAt,
            limit,
            nextToken,
            filters: _.generateAppsyncFilterPattern(filters)
          }
        },
        fetchPolicy: 'no-cache'
      })

      return data.getUserChannels
    } catch (e) {
      console.error(e)
      dispatch(
        'alert',
        {
          message: 'Failed to fetch channels.',
          error: true
        },
        { root: true }
      )
    }
  },
  async getAllChannelsByUser({ dispatch, commit }, { userId }) {
    let channels = {}
    let nextToken = null

    const userChannels = await indexDbClient.getAllFromIndex('member', 'created_at')
    const userChannelsCreatedAt = userChannels.length
      ? (userChannels[userChannels.length - 1].created_at.getTime() / 1000).toString()
      : null

    try {
      do {
        const data = await dispatch('getChannelsByUser', {
          userId,
          createdAt: userChannelsCreatedAt,
          limit: 25,
          nextToken
        })
        const transaction = await indexDbClient.getTransaction('member')
        for (const member of data.items) {
          if (userChannels.findIndex((channel) => channel.channel_id === member.channel_id) < 0) {
            userChannels.push(member)
            await transaction.store.put({
              ...member,
              created_at: new Date(Number(member.created_at) * 1000)
            })
          }
        }
        nextToken = data.nextToken
        await transaction.done
      } while (nextToken)

      const removeChannels = []
      channels = userChannels.reduce((acc, member) => {
        if (member.member_status === 'a') {
          delete removeChannels[member.channel_id]
          acc[member.channel_id] = {
            ...member.channel,
            archived: member.member_archived
          }
        } else {
          delete acc[member.channel_id]
          removeChannels[member.channel_id] = member.channel
        }
        return acc
      }, {})

      commit(REMOVE_CHANNELS, removeChannels)
    } catch (err) {
      console.error(err)
    }

    return channels
  },
  async getAllChannelsByCompany({ rootState, state, dispatch }, { companyId }) {
    const channels = {}
    const sessionUser = rootState.session.user
    let company = rootState.session.company

    if (company.company_id.toString() !== companyId.toString()) {
      const { normalized, rootRefId } = await dispatch(
        'Company/fetchNormalized',
        {
          id: companyId
        },
        { root: true }
      )
      company = normalized[rootRefId]
    }

    const { set: projects } = await dispatch(
      'Quote/filter',
      {
        filters: {
          company_id: companyId
        }
      },
      {
        root: true
      }
    )

    company.aoUsers.forEach((user) => {
      if (user.user_id.toString() === sessionUser.user_id.toString()) return
      const memberUserIds = [user.user_id, sessionUser.user_id].sort()
      const channelId = `CHAT-${memberUserIds[0]}-${memberUserIds[1]}`

      if (!state.channels[channelId]) {
        channels[channelId] = {
          channel_id: channelId,
          parent_channel_id: 'NULL',
          channel_type: 'CHAT',
          channel_type_id: `${memberUserIds[0]}-${memberUserIds[1]}`,
          channel_name: user.name,
          created_at: Date.now().toString(),
          company_id: companyId.toString(),
          archived: false,
          channel_members: {
            items: [
              {
                member_id: v4(),
                user_id: sessionUser.user_id.toString(),
                channel_id: channelId,
                user_channel_mute: false,
                user_is_admin: true,
                user_fname: sessionUser.user_fname,
                user_lname: sessionUser.user_lname,
                created_at: Date.now().toString(),
                member_status: 'a',
                member_archived: false
              },
              {
                member_id: v4(),
                user_id: user.user_id.toString(),
                channel_id: channelId,
                user_channel_mute: false,
                user_is_admin: true,
                user_fname: user.user_fname,
                user_lname: user.user_lname,
                created_at: Date.now().toString(),
                member_status: 'a',
                member_archived: false
              }
            ]
          }
        }
      }
    })
    projects.forEach((project) => {
      const quoteChannelId = `QUOTE-${project.quote_id.toString()}`
      const quoteClientChannelId = `QUOTE_CLIENT-${project.quote_id.toString()}-${project.client_user_id.toString()}`

      if (!state.channels[quoteChannelId]) {
        channels[quoteChannelId] = {
          channel_id: quoteChannelId,
          parent_channel_id: 'NULL',
          channel_type: 'QUOTE',
          channel_type_id: project.quote_id.toString(),
          channel_name: `${project.quote_name}`,
          created_at: Date.now().toString(),
          company_id: companyId.toString(),
          channel_status: project.quote_status,
          client_user_id: project.oClient.client_user_id,
          client_fname: project.oClient.user_fname,
          client_lname: project.oClient.user_lname,
          channel_members: {
            items: [
              {
                member_id: v4(),
                user_id: sessionUser.user_id.toString(),
                channel_id: quoteChannelId,
                user_channel_mute: false,
                user_is_admin: true,
                user_fname: sessionUser.user_fname,
                user_lname: sessionUser.user_lname,
                created_at: Date.now().toString(),
                member_status: 'a',
                member_archived: false
              }
            ]
          }
        }
      }

      if (!state.channels[quoteClientChannelId]) {
        channels[quoteClientChannelId] = {
          channel_id: quoteClientChannelId,
          parent_channel_id: `QUOTE-${project.quote_id.toString()}`,
          channel_type: 'QUOTE_CLIENT',
          channel_type_id: `${project.quote_id.toString()}-${project.oClient.toString()}`,
          channel_name: `${project.quote_name}`,
          created_at: Date.now().toString(),
          company_id: companyId.toString(),
          channel_status: project.quote_status,
          client_user_id: project.oClient.client_user_id,
          client_fname: project.oClient.user_fname,
          client_lname: project.oClient.user_lname,
          channel_members: {
            items: [
              {
                member_id: v4(),
                user_id: sessionUser.user_id.toString(),
                channel_id: quoteClientChannelId,
                user_channel_mute: false,
                user_is_admin: true,
                user_fname: sessionUser.user_fname,
                user_lname: sessionUser.user_lname,
                created_at: Date.now().toString(),
                member_status: 'a',
                member_archived: false
              },
              {
                member_id: v4(),
                user_id: project.oClient.client_user_id.toString(),
                channel_id: quoteClientChannelId,
                user_channel_mute: false,
                user_is_admin: true,
                user_fname: project.oClient.user_fname,
                user_lname: project.oClient.user_lname,
                created_at: Date.now().toString(),
                member_status: 'a',
                member_archived: false
              }
            ]
          }
        }
      }
    })

    const supportChannelId = `SUPPORT-${sessionUser.user_id.toString()}`
    channels[supportChannelId] = {
      channel_id: supportChannelId,
      parent_channel_id: 'NULL',
      channel_type: 'SUPPORT',
      channel_type_id: `${sessionUser.user_id.toString()}`,
      channel_name: `Bolster support`,
      created_at: Date.now().toString(),
      company_id: companyId.toString(),
      channel_members: {
        items: [
          {
            member_id: v4(),
            user_id: sessionUser.user_id.toString(),
            channel_id: supportChannelId,
            user_channel_mute: false,
            user_is_admin: true,
            user_fname: sessionUser.user_fname,
            user_lname: sessionUser.user_lname,
            created_at: Date.now().toString(),
            member_status: 'a',
            member_archived: false
          }
        ]
      }
    }

    try {
      const { items } = await dispatch('getChannelsByCompanyCompany', { companyId })
      if (items.length) {
        items.forEach((channel) => {
          channels[channel.channel_id] = channel
        })
      }
    } catch (err) {
      console.error(err)
    }

    return channels
  },
  async getInitChannelsByUser({ dispatch, commit }, { userId }) {
    commit(SET_CHANNELS_LOADING, true)

    const channels = await dispatch('getAllChannelsByUser', { userId })
    commit(ADD_CHANNELS, channels)

    commit(SET_CHANNELS_LOADING, false)
    return channels
  },
  async getInitChannelsByCompany({ dispatch, commit }, { companyId }) {
    commit(SET_CHANNELS_LOADING, true)

    const channels = await dispatch('getAllChannelsByCompany', { companyId })
    commit(ADD_CHANNELS, channels)

    commit(SET_CHANNELS_LOADING, false)
    return channels
  },
  async getChannelMember({ dispatch }, { userId, channelId }) {
    try {
      const { data } = await client().query({
        query: getChannelMember,
        variables: {
          input: {
            member_id: `${userId}-${channelId}`
          }
        }
      })

      return data.getChannelMember
    } catch (err) {
      console.error(err)
      await dispatch(
        'alert',
        {
          message: 'Failed to fetch channel member.',
          error: true
        },
        { root: true }
      )
    }
  },
  async postChannel(
    { state, commit },
    {
      companyId,
      channelType,
      channelTypeId,
      channelName,
      parentChannelId = 'Null',
      clientUserId,
      clientFname,
      clientLname,
      channelStatus
    }
  ) {
    const channelId = `${channelType}-${channelTypeId}`
    let data
    try {
      ;({ data } = await client().mutate({
        mutation: postChannel,
        variables: {
          input: {
            channel_type: channelType,
            channel_type_id: channelTypeId,
            channel_name: channelName,
            parent_channel_id: parentChannelId,
            company_id: companyId,
            client_user_id: clientUserId,
            client_fname: clientFname,
            client_lname: clientLname,
            channel_status: channelStatus
          }
        }
      }))
    } catch (e) {
      if (!import.meta.env.PROD) {
        console.debug(e.message, {
          channel_type: channelType,
          channel_type_id: channelTypeId,
          channel_name: channelName,
          parent_channel_id: parentChannelId,
          company_id: companyId,
          client_user_id: clientUserId,
          client_fname: clientFname,
          client_lname: clientLname,
          channel_status: channelStatus
        })
      }
      return false
    }

    if (state.channels[channelId]) {
      commit(ADD_CHANNEL, {
        channel_id: channelId,
        ...state.channels[channelId]
      })
    }

    return data.postChannel
  },
  async updateChannel(
    { state, commit, rootState },
    {
      channelId,
      channelName,
      clientUserId = null,
      clientFname = null,
      clientLname = null,
      channelStatus = null
    }
  ) {
    const userId = (
      rootState.session.authorizedUser.user_id || rootState.session.user.user_id
    ).toString()
    const { data } = await client().mutate({
      mutation: updateChannel,
      variables: {
        input: {
          channel_id: channelId,
          channel_name: channelName,
          client_user_id: clientUserId,
          client_fname: clientFname,
          client_lname: clientLname,
          channel_status: channelStatus
        }
      }
    })

    if (state.channels[channelId]) {
      commit(ADD_CHANNEL, {
        channel_id: channelId,
        ...state.channels[channelId],
        channel_name: channelName,
        client_user_id: clientUserId || state.channels[channelId].client_user_id,
        client_fname: clientFname || state.channels[channelId].client_fname,
        client_lname: clientLname || state.channels[channelId].client_lname,
        channel_status: channelStatus || state.channels[channelId].channel_status
      })
    }

    const member = await indexDbClient.get('member', `${userId}-${channelId}`)
    if (member) {
      const transaction = await indexDbClient.getTransaction('member')
      await transaction.store.put({
        ...member,
        channel: {
          ...member.channel,
          channel_name: channelName,
          client_user_id: clientUserId || state.channels[channelId].client_user_id,
          client_fname: clientFname || state.channels[channelId].client_fname,
          client_lname: clientLname || state.channels[channelId].client_lname,
          channel_status: channelStatus || state.channels[channelId].channel_status
        }
      })
      await transaction.done
    }

    return data.updateChannel
  },
  async postChannelMember({ dispatch }, { userId, channelId, userFName, userLName, channelType }) {
    try {
      const { data } = await client().mutate({
        mutation: postChannelMember,
        variables: {
          input: {
            user_id: userId,
            user_fname: String(userFName),
            user_lname: String(userLName),
            channel_id: channelId,
            channel_type: channelType
          }
        }
      })

      return data.postMember
    } catch (e) {
      console.error(e)
      await dispatch(
        'alert',
        {
          message: 'Failed to create channel member.',
          error: true
        },
        { root: true }
      )
    }
  },
  async updateChannelMember(
    { state, commit },
    { userId, channelId, userChannelMute, userChannelArchived }
  ) {
    const { data } = await client().mutate({
      mutation: updateChannelMember,
      variables: {
        input: {
          user_id: userId,
          channel_id: channelId,
          user_channel_mute: userChannelMute,
          member_archived: userChannelArchived
        }
      }
    })

    if (state.channels[channelId]) {
      const channelMember = state.channels[channelId]?.channel_members?.items?.find(
        (member) => member.user_id === userId
      )
      if (channelMember) {
        commit(ADD_CHANNEL, {
          channel_id: channelId,
          ..._.imm(state.channels[channelId]),
          archived: userChannelArchived,
          channel_members: {
            items: [
              ...(state.channels[channelId]?.channel_members?.items?.filter(
                (member) => member.user_id !== userId
              ) || []),
              {
                ...channelMember,
                user_channel_mute: userChannelMute,
                member_archived: userChannelArchived
              }
            ]
          }
        })

        const transaction = await indexDbClient.getTransaction('member')
        await transaction.store.put({
          ...channelMember,
          channel: _.imm(state.channels[channelId]),
          user_channel_mute: userChannelMute,
          member_archived: userChannelArchived,
          created_at: new Date(Number(channelMember.created_at) * 1000)
        })
        await transaction.done
      }
    }

    return data.updateChannelMember
  },
  async postChannelMembers({ state, dispatch, commit }, { members = [] }) {
    try {
      const { data } = await client().mutate({
        mutation: postChannelMembers,
        variables: {
          input: members
        }
      })

      members.forEach((member) => {
        if (state.channels[member.channel_id]) {
          let insert = true
          const currentMembers = state.channels[member.channel_id]?.channel_members?.items || []
          const newMembers = currentMembers.map((currentMember) => {
            if (member.user_id === currentMember.user_id) {
              insert = false
              return member
            }
            return currentMember
          })
          if (insert) newMembers.push(member)

          commit(ADD_CHANNEL, {
            channel_id: member.channel_id,
            ..._.imm(state.channels[member.channel_id]),
            channel_members: {
              items: newMembers
            }
          })
        }
      })

      return data.postMembers
    } catch (e) {
      console.error(e)
      await dispatch(
        'alert',
        {
          message: 'Failed to create channel members.',
          error: true
        },
        { root: true }
      )
    }
  },
  async removeChannelMembers({ state, dispatch, commit }, { members = [] }) {
    try {
      const { data } = await client().mutate({
        mutation: removeChannelMembers,
        variables: {
          input: members
        }
      })

      members.forEach((member) => {
        if (state.channels[member.channel_id]) {
          commit(ADD_CHANNEL, {
            channel_id: member.channel_id,
            ..._.imm(state.channels[member.channel_id]),
            channel_members: {
              items: (state.channels[member.channel_id]?.channel_members?.items || []).filter(
                (channelMember) => channelMember.user_id !== member.user_id
              )
            }
          })
        }
      })

      return data.removeMembers
    } catch (err) {
      console.error(err)
      await dispatch(
        'alert',
        {
          message: 'Failed to remove channel members.',
          error: true
        },
        { root: true }
      )
    }
  },
  async subscribeToNewChannels({ rootState, dispatch, commit }, { userId, toastService }) {
    try {
      const observer = client().subscribe({
        query: subscribeToChannelMembers,
        variables: {
          userId
        }
      })

      const channelObserver = observer.subscribe({
        async next(data) {
          const member = data.data.onSavedMember
          if (!member || !member.member_id) return
          const currentMember = await indexDbClient.get('member', member.member_id)
          const memberStatus = member.member_status
          const memberArchived = member.member_archived
          const channel = {
            ...member.channel,
            archived: memberArchived
          }

          const transaction = await indexDbClient.getTransaction('member')
          await transaction.store.put({
            ...member,
            created_at: new Date(Number(member.created_at) * 1000)
          })
          await transaction.done

          const channelName =
            channel.channel_type !== 'CHAT' || channel.channel_members.items.length > 2
              ? channel.channel_name
              : channel.channel_members.items.reduce((acc, member) => {
                  if (
                    member.user_id !==
                    (rootState.session.authorizedUser?.user_id || rootState.session.user.user_id)
                  ) {
                    acc = `${member.user_fname} ${member.user_lname}`
                  }
                  return acc
                }, '')

          if (memberStatus === 'a') {
            commit(ADD_CHANNEL, channel)

            if (!currentMember || currentMember.member_status !== 'a') {
              toastService.add({
                severity: 'secondary',
                summary: memberArchived
                  ? `Message channel ${channelName} has been archived`
                  : `You have been added to message channel ${channelName}`,
                detail: channel,
                life: 5000,
                group: 'notification'
              })
            }
          } else {
            commit(REMOVE_CHANNEL, channel)

            if (currentMember && currentMember.member_status === 'a') {
              toastService.add({
                severity: 'secondary',
                summary: `You have been removed from chat ${channelName}`,
                detail: channel,
                life: 5000,
                group: 'notification'
              })
            }
          }
        },
        complete(com) {
          console.log(com)
        },
        error(err) {
          console.error(err)
          setTimeout(() => dispatch('subscribeToNewChannels', { userId, toastService }), 5000)
        }
      })

      commit(SET_CHANNEL_OBSERVER, channelObserver)
    } catch (err) {
      console.error(err)
      await dispatch(
        'alert',
        {
          message: `Failed to attach channel listener.`
        },
        { root: true }
      )
    }
  },
  async unsubscribeToNewChannels({ commit }) {
    commit(SET_CHANNEL_OBSERVER, null)
  },
  updateChannelLatestActivity({ state, commit }, { channelId, activity }) {
    if (state.channels[channelId]) {
      commit(ADD_CHANNEL, {
        ...state.channels[channelId],
        channel_id: channelId,
        latest_channel_activity: {
          channel_activity_id: `${channelId}-${activity.activity_id}`,
          channel_id: channelId,
          activity_id: activity.activity_id,
          activity_created_at: activity.activity_created_at,
          activity
        }
      })
    }
  },
  async getFilePreSignedPostUrl({ dispatch }, { fileType }) {
    try {
      const { data } = await client().query({
        query: getFilePreSignedPost,
        variables: {
          input: {
            content_type: fileType
          }
        }
      })

      return JSON.parse(data.getFilePreSignedPost)
    } catch (err) {
      console.error(err)
      await dispatch('alert', {
        message: 'Failed to get file pre signed post.',
        error: true
      })
    }
  },
  async clearSession({ commit }) {
    await commit(SET_CHANNEL_OBSERVER, null)
    await commit(SET_NOTIFICATION_OBSERVER, null)
    await commit(SET_CHANNEL_ACTIVITIES_OBSERVER, null)
    await commit(SET_CHANNELS, {})
    await commit(SET_NOTIFICATIONS, {})
    await commit(SET_NOTIFICATIONS_OBJECT, {})
  }
}

// mutations
const mutations = {
  [SET_CHANNELS_LOADING](state, loading) {
    state.channelsLoading = loading
  },
  [SET_CHANNELS](state, channels) {
    state.channels = channels
  },
  [SET_HAS_NOTIFICATIONS](state, hasNotifications) {
    state.hasNotifications = hasNotifications
  },
  [SET_NOTIFICATIONS](state, notifications) {
    state.channelNotifications = notifications
  },
  [SET_NOTIFICATIONS_OBJECT](state, notificationsObject) {
    state.channelNotificationsObject = notificationsObject
  },
  [ADD_CHANNEL_NOTIFICATION](state, notification) {
    if (!state.channelNotifications[notification.channel_id]) {
      state.channelNotifications[notification.channel_id] = [notification]
      return
    }

    insertIntoSortedNotificationsArray(
      notification,
      state.channelNotifications[notification.channel_id]
    )
  },
  [ADD_CHANNEL_NOTIFICATION_OBJECT](state, notification) {
    if (!state.channelNotificationsObject[notification.channel_id]) {
      state.channelNotificationsObject[notification.channel_id] = {}
    }
    state.channelNotificationsObject[notification.channel_id][notification.activity_id] = true
  },
  [SET_CHANNEL_NOTIFICATIONS](state, { channelId, notifications }) {
    state.channelNotifications[channelId] = notifications
  },
  [SET_CHANNEL_NOTIFICATIONS_OBJECT](state, { channelId, notificationsObject }) {
    state.channelNotificationsObject[channelId] = notificationsObject
  },
  [ADD_CHANNEL](state, channel) {
    state.channels[channel.channel_id] = channel
  },
  [REMOVE_CHANNEL](state, channel) {
    delete state.channels[channel.channel_id]
  },
  [ADD_CHANNELS](state, channels) {
    state.channels = { ...(state.channels || {}), ...channels }
  },
  [REMOVE_CHANNELS](state, channels) {
    for (const channel of channels) {
      delete state.channels[channel.channel_id]
    }
  },
  [SET_CHANNEL_OBSERVER](state, observer) {
    if (state.channelObserver) state.channelObserver.unsubscribe()
    state.channelObserver = observer
  },
  [SET_NOTIFICATION_OBSERVER](state, observer) {
    if (state.notificationObserver) state.notificationObserver.unsubscribe()
    state.notificationObserver = observer
  },
  [SET_CHANNEL_ACTIVITIES_OBSERVER](state, observer) {
    if (state.activitiesObserver) state.activitiesObserver.unsubscribe()
    state.activitiesObserver = observer
  },
  [SET_CHANNEL_ACTIVITIES_NEXT_TOKEN](state, { channelId, nextToken }) {
    state.channelNextToken[channelId] = nextToken
  },
  [SET_CHANNEL_ACTIVITIES_LEGACY_NEXT_TOKEN](state, { channelId, nextToken }) {
    state.channelNextToken[channelId] = nextToken
  },
  [PUSH_CHANNEL_ACTIVITY](state, { channelId, activity }) {
    if (!state.channelActivities[channelId]) {
      state.channelActivities[channelId] = [activity]
      return 0
    }

    return state.channelActivities[channelId].push(activity) - 1
  },
  [UPDATE_CHANNEL_ACTIVITY](state, { channelId, activity, index }) {
    state.channelActivities[channelId][index] = activity
  },
  [REMOVE_CHANNEL_ACTIVITY](state, { channelId, activityId }) {
    if (state.channelActivities[channelId]) {
      state.channelActivities[channelId] = state.channelActivities[channelId].filter(
        (a) => a.activity_id !== activityId
      )
    }
  },
  [ADD_CHANNEL_ACTIVITY](state, { channelId, activity }) {
    if (!state.channelActivities[channelId]) {
      state.channelActivities[channelId] = [activity]
      return 0
    }

    if (activity.oTarget?.tag) {
      state.channelActivities[channelId] = state.channelActivities[channelId].filter(
        (a) => a.activity_id !== activity.oTarget.tag
      )
    }

    return insertIntoSortedActivityArray(activity, state.channelActivities[channelId])
  },
  [SET_CHANNEL_ACTIVITIES](state, { channelId, activities }) {
    state.channelActivities[channelId] = activities
  },
  [SET_CHANNEL_HAS_MORE_ACTIVITIES](state, { channelId, hasNextToken }) {
    state.hasMoreActivities[channelId] = hasNextToken
  },
  [SET_CHANNEL_HAS_MORE_LEGACY_ACTIVITIES](state, { channelId, hasNextToken }) {
    state.hasMoreLegacyActivities[channelId] = hasNextToken
  },
  [ADD_SEEN_ACTIVITY](state, activityId) {
    state.seenActivities[activityId] = true
  },
  [SET_CHANNEL_ACTIVITIES_CONNECTED](state, activitiesObserverConnected) {
    state.activitiesObserverConnected = activitiesObserverConnected
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
