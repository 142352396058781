import { useNpsDialog } from './sessionNps'
import { ENpsEntryFeatureType } from '@bolster/types/sessionAnalytics/enums/nps_entry_feature_type'
import { useStore } from 'vuex'

export const useGlobalNpsRegistry = () => {
  const store = useStore()

  // nps registries //
  useNpsDialog(ENpsEntryFeatureType.OnBoarding, 'Rate your <b>onboarding</b> experience:', {
    session: {
      count: {
        gte: 1
      },
      filters: {
        user_id: store.state.session.authorizedUser.user_id,
        company_id: store.state.session.company.company_id,
        time_since_first: {
          gte: '172800000'
        }
      }
    },
    nps_entry: {
      count: {
        lte: 0,
        gte: 0
      },
      filters: {
        user_id: store.state.session.authorizedUser.user_id,
        company_id: store.state.session.company.company_id,
        nps_entry_type: ENpsEntryFeatureType.OnBoarding
      }
    }
  })
  useNpsDialog(ENpsEntryFeatureType.Impression, 'Rate your <b>first</b> Bolster experience:', {
    session: {
      count: {
        gte: 2
      },
      filters: {
        user_id: store.state.session.authorizedUser.user_id,
        company_id: store.state.session.company.company_id,
        time_since_first: {
          gte: '172800000'
        }
      }
    },
    nps_entry: {
      count: {
        lte: 0,
        gte: 0
      },
      filters: {
        user_id: store.state.session.authorizedUser.user_id,
        company_id: store.state.session.company.company_id,
        nps_entry_type: ENpsEntryFeatureType.Impression
      }
    }
  })
  useNpsDialog(
    ENpsEntryFeatureType.Engagement,
    'Rate how well Bolster has <b>impacted</b> your business:',
    {
      session: {
        count: {
          gte: 1
        },
        filters: {
          user_id: store.state.session.authorizedUser.user_id,
          company_id: store.state.session.company.company_id,
          time_since_first: {
            gte: '864000000'
          }
        }
      },
      nps_entry: {
        count: {
          lte: 0,
          gte: 0
        },
        filters: {
          user_id: store.state.session.authorizedUser.user_id,
          company_id: store.state.session.company.company_id,
          nps_entry_type: ENpsEntryFeatureType.Engagement
        }
      }
    }
  )
  useNpsDialog(ENpsEntryFeatureType.Overall, 'Rate how <b>happy</b> you are with Bolster:', {
    session: {
      count: {
        gte: 1
      },
      filters: {
        user_id: store.state.session.authorizedUser.user_id,
        company_id: store.state.session.company.company_id,
        time_since_first: {
          gte: '1728000000'
        }
      }
    },
    nps_entry: {
      count: {
        lte: 0,
        gte: 0
      },
      filters: {
        user_id: store.state.session.authorizedUser.user_id,
        company_id: store.state.session.company.company_id,
        nps_entry_type: ENpsEntryFeatureType.Overall
      }
    }
  })
  // ============== //
}
