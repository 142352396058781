<script setup>
import { computed, defineAsyncComponent, defineProps } from 'vue'

const SvgIcon = defineAsyncComponent(() => import('@/components/ui/Icon/SvgIcon.vue'))

const props = defineProps({
  icon: {
    required: true
  }
})

const isSvg = computed(() => typeof props.icon === 'string' && props.icon.includes('svg:'))
const svgIcon = computed(() => typeof props.icon === 'string' && props.icon.replace('svg:', ''))
</script>

<template>
  <SvgIcon v-bind="props" :svg="svgIcon" v-if="isSvg" />
  <font-awesome-icon v-bind="props" v-else />
</template>
