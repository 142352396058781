import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import { storeToRefs } from 'pinia'
import { useSessionAnalytics } from '@/stores/session_analytics.ts'
import type { ISession } from '@bolster/types/sessionAnalytics/interfaces/session.ts'

/*
 * Session are recorded per company scope.
 * Each time a user enters a company scope, a new session is recorded
 * */
export const useSession = () => {
  // ===== Composables ===== //
  const store = useStore()
  const {
    setActiveSessionState,
    getActiveSessionState,
    clearSessionState,
    postSession,
    updateSession
  } = useSessionAnalytics()
  const { activeSession } = storeToRefs(useSessionAnalytics())
  // ==================== //

  // ===== Computed ===== //
  const userId = computed<string>(
    () =>
      (
        store.state.session.authorizedUser.user_id || store.state.session.user.user_id
      )?.toString() || ''
  )
  const companyId = computed<string>(() => store.state.session.company.company_id?.toString() || '')
  const session = computed<
    | {
        session_id: string
        time_start: string
        time_end?: string | undefined
        user_id: string
        company_id: string
      }
    | undefined
  >(() => activeSession.value)
  // ==================== //

  // ===== Methods ===== //
  const setActiveSession = (session: ISession) => {
    clearSessionState()
    setActiveSessionState(session)
  }
  const initSession = async () => {
    const localActiveSession = getActiveSessionState()
    if (!localActiveSession && userId.value && companyId.value) {
      // create new session //
      const session = await postSession({
        user_id: userId.value,
        company_id: companyId.value,
        time_start: Date.now().toString()
      })
      setActiveSession(session)
    }
    return session.value
  }
  const destroySession = async () => {
    if (session.value) {
      // update session to ended //
      const localActiveSession = getActiveSessionState()
      if (localActiveSession) {
        localActiveSession.time_end = Date.now().toString()
        await updateSession(localActiveSession)
      }
    }
    clearSessionState()
  }
  const refreshSession = async (companyId) => {
    if (!companyId) {
      await destroySession()
    } else {
      await destroySession()
      await initSession()
    }
  }
  // ==================== //

  // ===== Watchers ===== //
  watch(companyId, refreshSession, { immediate: true })
  // ==================== //
}
