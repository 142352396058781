/**
 * Company-scoped key-value pairs.
 */
import _ from '../Helpers'
export default {
  type: 'keyvalue',

  skipAudit: true,

  fields: {
    keyvalue_id: {
      type: 'int'
    },
    keyvalue_key: {
      type: 'string'
    },
    keyvalue_value: {
      type: 'string'
    },
    company_id: {
      type: 'string',
      filter: true,
      mapTo: 'company'
    }
  },

  generateVueActions() {
    return {
      async get({ dispatch, rootState }, payload) {
        let key = payload
        let global = false
        if (typeof payload === 'object') {
          key = payload.key || payload.name
          global = payload.global || false
        }

        const { object } = await dispatch('ajax', {
          path: `keyvalue/get/${key}/${global}`,
          scope:
            payload.asSuper && rootState.session.authorizedUser?.user_id
              ? { user: rootState.session.authorizedUser.user_id }
              : undefined
        })

        try {
          return JSON.parse(object.value || object.message || 'null')
        } catch (e) {
          console.debug(e)
        }

        return object.value || object.message || null
      },

      async set({ dispatch, rootState }, { key, value, global = false, asSuper = false }) {
        let escapedValue = value

        escapedValue = JSON.stringify(value)
        const encoded = encodeURIComponent(btoa(escapedValue))

        return dispatch('ajax', {
          path: `keyvalue/set/${key}/${encoded}/${global}`,
          scope:
            asSuper && rootState.session.authorizedUser?.user_id
              ? { user: rootState.session.authorizedUser.user_id }
              : undefined
        })
      },

      async unshift(
        { dispatch },
        { key, value, global = false, max = 0, asSuper = false, unique = false }
      ) {
        let values = _.makeArray(value) // coerce

        let existing = (await dispatch('get', { key, global, asSuper })) ?? []
        if (unique) values = values.filter((v) => existing.every((e) => !_.jsonEquals(e, v)))

        existing.unshift(...values)
        let final = existing
        if (max && existing.length > max) final = existing.slice(0, max)

        await dispatch('set', { key, global, value: final, asSuper })

        return final
      },

      async push(
        { dispatch },
        { key, value, global = false, max = 0, asSuper = false, unique = false }
      ) {
        let values = _.makeArray(value) // coerce

        let existing = (await dispatch('get', { key, global, asSuper })) ?? []
        if (unique) values = values.filter((v) => existing.every((e) => !_.jsonEquals(e, v)))

        existing.push(...values)
        let final = existing
        if (max && existing.length > max) final = existing.slice(existing.length - max)

        await dispatch('set', { key, global, value: final, asSuper })

        return final
      }
    }
  }
}
