export default {
  root: ({ props, attrs }) => ({
    class: [
      //Font
      'text-xs font-medium',

      //Alignments
      'inline-flex items-center justify-center',

      //Spacing
      'px-1.5 py-0.25',

      //Shape
      {
        'rounded-sm': !props.rounded,
        'rounded-full': props.rounded
      },

      //Colors
      'dark:flame-white',
      'border',
      {
        'border-primary-500 dark:border-primary-400 text-primary-800':
          (props.severity == null && !attrs.fill) || (props.severity == 'primary' && !attrs.fill),
        'border-green-500 dark:border-green-400 text-green-800':
          props.severity == 'success' && !attrs.fill,
        'border-blue-print-400 dark:border-blue-print-400 text-blue-print-400':
          props.severity == 'info' && !attrs.fill,
        'border-orange-500 dark:border-orange-400 text-orange-700':
          props.severity == 'warning' && !attrs.fill,
        'border-red-500 dark:border-red-400 text-red-700':
          props.severity == 'danger' && !attrs.fill,
        'bg-pitch-black text-level-yellow': props.severity == 'bolster' && !attrs.fill,

        'border-primary-500 bg-primary-500 dark:bg-primary-400 dark:border-primary-400 text-primary-100':
          (props.severity == null && attrs.fill === 'solid') ||
          (props.severity == 'primary' && attrs.fill === 'solid'),
        'border-green-500 bg-green-500 dark:border-green-400 dark:bg-green-400 text-green-100':
          props.severity == 'success' && attrs.fill === 'solid',
        'border-blue-500 bg-blue-500 dark:bg-blue-400 dark:border-blue-400 text-blue-print-100':
          props.severity == 'info' && attrs.fill === 'solid',
        'border-orange-500 bg-orange-500 dark:bg-orange-400 dark:border-orange-400 text-orange-100':
          props.severity == 'warning' && attrs.fill === 'solid',
        'border-red-500 bg-red-500 dark:bg-red-400  dark:border-red-400 text-red-100':
          attrs.fill === 'solid' && props.severity == 'danger'
      }
    ]
  }),
  value: {
    class: 'leading-normal'
  },
  icon: {
    class: 'mr-1 text-sm'
  }
}
