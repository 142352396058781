// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate

export default {
  type: 'ai_assistant',

  skipAudit: true,

  vectorStoreEntityTypes: [
    'assembly',
    'cost_type',
    'quote',
    'company',
    'stage',
    'unit_of_measure',
    'dimension',
    'trade_type'
  ],

  vectorStoreIndexes: {
    assembly: 'item_types_vector',
    cost_type: 'item_types_vector',
    quote: 'projects_vector',
    company: 'company_vector',
    stage: 'reference_vector',
    unit_of_measure: 'reference_vector',
    dimension: 'reference_vector',
    trade_type: 'reference_vector'
  },

  fields: {
    ai_assistant_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    ai_assistant_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      default: 'My new AI chatbot'
    },
    asVectorIndexes: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false
    },
    ai_assistant_time_created: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    ai_assistant_creator: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    ai_assistant_status: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      default: 'a'
    },
    instructions: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false,
      default: 'You are a helpful AI assistant that will help me answer complex questions'
    },
    has_code_interpreter: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      default: 0
    },
    model_name: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      default: 'gpt-4o'
    },
    file_ids: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    response_format: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    temperature: {
      type: 'float',
      filter: true,
      format: false,
      mapTo: false,
      default: 0.5
    },
    company_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    }
  }
}
