// This basic version of this file is generated server side,
// Go to: {hostname}/js_model_generator/generate
import UserError from '../UserError'

export default {
  type: 'user',

  possibleStatuses: ['a', 'i', 'b'],

  skipAudit: true,

  actions: {
    delete: {
      visible: () => true,
      selectionRequired: true,
      multiple: false
    },
    duplicate: {
      visible: () => false
    },
    resetPassword: {
      title: 'Reset Password',
      icon: 'key',
      action: 'User/resetPassword',
      selectionRequired: true
    },
    loginAs: {
      title: 'Impersonate',
      icon: 'sign-in-alt',
      action: 'User/loginAs',
      selectionRequired: true,
      visible: (user, store) => !!store.state.session.user.user_is_super_user
    },
    resendInvite: {
      title: 'Resend invite',
      icon: 'paper-plane',
      action: 'User/resendInvite',
      selectionRequired: true
      // visible: (user, store) => String(user.company_ids || '').includes(store.state.session.company.company_id),
    }
  },

  generateVueActions() {
    return {
      async createFromEmail({ dispatch }, payload) {
        const { email, object = {} } = payload

        const { object: user } = await dispatch('ajax', {
          path: `/user/addUserToCompanyFromEmail`,
          data: {
            object: {
              ...object,
              user_email: email
            }
          }
        })

        await dispatch('clearCache')

        return {
          user_id: user.user_id,
          id: user.user_id,
          user,
          object: user
        }
      },
      async resendInvite({ dispatch }, payload) {
        const { id, object: user } = await dispatch('resolveObject', payload)

        let inactiveCompanyCount = 0
        for (const company of user.aoCompanies) {
          if (company.company_status !== 'a') {
            inactiveCompanyCount += 1
          }
        }

        if (inactiveCompanyCount > 0) {
          if (
            !(await dispatch(
              'modal/asyncConfirm',
              {
                message: 'Resend an invite with inactive companies?',
                subMessage:
                  "This user has inactive companies associated to them. Make sure you've unlocked or activated any companies they may need to access."
              },
              { root: true }
            ))
          ) {
            return false
          }
        }

        try {
          await dispatch('ajax', {
            path: `/user/resendInvite/${id}`
          })
        } catch (e) {
          dispatch(
            'alert',
            {
              error: true,
              message: e.userMessage || e.message
            },
            { root: true }
          )

          throw e
        }

        dispatch(
          'alert',
          {
            message: 'Resent!'
          },
          { root: true }
        )

        return payload
      },

      /**
       * add user to company
       * @param dispatch
       * @param payload
       * @returns {Promise<boolean>}
       */
      async addToCompany({ dispatch }, payload) {
        const {
          company, // id
          go = true
        } = payload

        const { object: user } = await dispatch('resolveObject', payload)

        try {
          await dispatch('ajax', {
            path: `/user/addUserToCompany/${user.user_id}/${company}`
          })
        } catch (e) {
          dispatch(
            'alert',
            {
              error: true,
              message: e.userMessage || e.message
            },
            { root: true }
          )

          throw e
        }

        dispatch(
          'alert',
          {
            message: 'User successfully added.'
          },
          { root: true }
        )

        if (go) {
          this.$store.dispatch('to', '/users')
        }

        return true
      },

      /**
       *
       * @param dispatch
       * @param rootState
       * @param payload
       * @returns {Promise<void>}
       */
      async loginAs({ dispatch, rootState }, payload) {
        const { go = true } = payload
        const { object } = await dispatch('resolveObject', payload)

        const masquerade =
          `${rootState.session.authorizedUser.user_id}` !== `${object.user_id}` &&
          rootState.session.authorizedUser.user_is_super_user

        // // add to recently dones list
        if (masquerade)
          await dispatch(
            'Keyvalue/unshift',
            {
              key: `${rootState.session.authorizedUser.user_id}-masq-list`,
              value: { id: object.user_id, name: `${object.user_fname} ${object.user_lname}` },
              global: true,
              max: 20,
              asSuper: true,
              unique: true
            },
            { root: true }
          )

        await dispatch(
          'setScope',
          {
            masquerade,
            scope: { user: object.user_id }
          },
          { root: true }
        )

        if (masquerade === false && go) await dispatch('to', '/super/users', { root: true })
        else if (masquerade) await dispatch('to', '/home', { root: true })

        return true
      },

      /**
       *
       * @param dispatch
       * @param rootState
       * @param payload
       * @returns {Promise<*>}
       */
      async removeAdmin({ dispatch, rootState }, payload) {
        const { company = rootState.session.scope.company } = payload
        const { id } = await dispatch('resolveObject', payload)
        return dispatch('ajax', {
          path: `/user/removeAdmin/${id}/${company}`
        })
      },

      /**
       *
       * @param dispatch
       * @param rootState
       * @param payload
       * @returns {Promise<*>}
       */
      async grantAdmin({ dispatch, rootState }, payload) {
        const { company = rootState.session.scope.company } = payload
        const { id } = await dispatch('resolveObject', payload)
        return dispatch('ajax', {
          path: `/user/grantAdmin/${id}/${company}`
        })
      },

      /**
       *
       * @param dispatch
       * @param payload
       *    @see resolveObject for which params to include to find the object
       * @returns {Promise<void>}
       */
      async message({ dispatch }) {
        return dispatch(
          'alert',
          {
            message: 'Messaging is not yet available. Coming soon..',
            error: true
          },
          {
            root: true
          }
        )
      },

      async delete({ rootState, dispatch }, payload) {
        return dispatch(
          'Company/removeUser',
          {
            id: rootState.session.company.company_id,
            user: payload.selected[0].user_id
          },
          { root: true }
        )
      },

      /**
       *
       * @param dispatch
       * @param payload
       *    @see resolveObject for which params to include to find the object
       * @returns {Promise<void>}
       */
      async call({ dispatch }, payload) {
        let { tags = {} } = payload

        const { object: user } = await dispatch('resolveObject', payload)

        tags = {
          ...tags,
          user_id: user.user_id
        }

        if (!user.user_phone) {
          dispatch('alert', {
            error: true,
            message: 'That user does not have a phone number to call.'
          })
          throw new UserError({
            userMessage: 'That user does not have a phone number to call.'
          })
        }

        return dispatch(
          'phone/call',
          {
            number: user.user_phone,
            tags
          },
          { root: true }
        )
      },
      /**
       * Update password for the current user. Will prompt for values
       * not provided.
       * @param dispatch
       * @param state
       * @param rootState
       * @param payload
       *    @param existing String|Null       existing password
       *    @param password String|Null       new password
       *    @param again String|Null          new password confirmation
       * @returns {Promise<{userMessage: string}>}
       */
      async updatePassword({ dispatch, rootState }, payload = {}) {
        const {
          /**
           * Old password
           */
          existing = null,
          /**
           * New password
           */
          password = null,
          /**
           * New password, again
           */
          again = null
        } = payload

        let old = existing
        if (!rootState.session.user.user_is_using_temp_pass) {
          old =
            old ||
            (await dispatch(
              'prompt',
              {
                message: 'Enter your current password:',
                inputType: 'password',
                required: true
              },
              { root: true }
            ))
        }

        const pass =
          password ||
          (await dispatch(
            'prompt',
            {
              message: 'New password:',
              inputType: 'password',
              required: true
            },
            { root: true }
          ))

        const pass2 =
          again ||
          (await dispatch(
            'prompt',
            {
              message: 'Enter password again:',
              inputType: 'password',
              required: true
            },
            { root: true }
          ))

        if (pass2 !== pass) {
          throw {
            userMessage: 'New password and confirmation password do not match. Try again.'
          }
        }

        try {
          await dispatch('ajax', {
            path: `user/updatePassword/${rootState.session.user.user_id}`,
            data: {
              user_id: rootState.session.user.user_id,
              'user_password-old': old,
              'user_password-new': pass,
              'user_password-again': pass2
            }
          })
        } catch (e) {
          dispatch(
            'alert',
            {
              error: true,
              message: e.userMessage || 'Could not update password.  Please try again.'
            },
            { root: true }
          )

          throw e
        }

        dispatch(
          'alert',
          {
            message: 'Password successfully updated.'
          },
          { root: true }
        )

        return {
          userMessage: 'Password successfully updated.'
        }
      },

      /**
       *
       * @param dispatch
       * @param state
       * @param rootState
       * @param payload
       * @returns {Promise<any>}
       */
      async resetPassword({ dispatch }, payload) {
        const { button = null, grid = null } = payload

        if (
          !(await dispatch(
            'modal/asyncConfirm',
            {
              message: "Are you sure you want to reset this user's password?",
              subMessage:
                'Their account will immediately have their password reset and a reset code will be emailed to them.'
            },
            { root: true }
          ))
        ) {
          return false
        }

        return new Promise((resolve, reject) => {
          dispatch('resolveObject', payload).then(({ set }) => {
            dispatch('ajax', { path: `user/resetPassword/${set[0].user_id}`, button })
              .then((resetPayload) => {
                if (alert) {
                  dispatch(
                    'alert',
                    {
                      message:
                        "Password was reset. Email with instructions were sent to the user's email address."
                    },
                    { root: true }
                  )
                }

                if (grid && typeof grid.reload === 'function') {
                  grid.reload()
                }
                resolve({ ...payload, ...resetPayload })
              })
              .catch((rej) => reject({ ...payload, ...rej }))
          })
        })
      }
    }
  },

  fields: {
    curency_id: {
      type: 'int',
      mapTo: 'currency'
    },
    user_terms_time_accepted: {
      type: 'int',
      format: 'datetime',
      save: false,
      reload: true,
      trackChanges: false
    },
    aoDashWidgets: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false
    },
    user_count_leads_incomplete: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    timezone_name: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    timezone_desc: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    timezone_utc_offset: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    timezone_utc_dst_offset: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    location_ids: {
      type: 'array',
      mapTo: 'location',
      save: true,
      filter: true
    },
    localization_language_code: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    localization_name: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    localization_country_code: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    localization_thousands_separator: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    localization_decimal_separator: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    localization_currency_code: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    currency_iso: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    localization_currency_symbol: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    localization_currency_symbol_position: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    user_company_ids: {
      type: 'array',
      save: false
    },
    aoCompanies: {
      type: 'array',
      mapTo: 'company',
      save: false
    },
    user_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false,
      component: 'PreviewUser'
    },
    group_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    employee_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    user_status: {
      type: 'string',
      filter: true,
      format: 'status',
      mapTo: false,
      default: 'a'
    },
    user_time_created: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    user_password: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      trackChanges: false
    },
    user_phone: {
      type: 'string',
      filter: true,
      format: 'phone',
      mapTo: false
    },
    user_phone_ext: {
      type: 'string',
      filter: true,
      mapTo: false
    },
    user_phone_ext_alt: {
      type: 'string',
      filter: true,
      mapTo: false
    },
    cc_account_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    user_is_admin: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: false
    },
    timezone_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'timezone',
      defaultSetting: true
    },
    localization_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'localization'
    },
    currency_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'currency'
    },
    company_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'company'
    },
    parent_file_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    user_postal: {
      type: 'string',
      save: true,
      filter: false,
      mapTo: false
    },
    user_suite: {
      type: 'string',
      save: true,
      filter: false,
      mapTo: false
    },
    user_address: {
      type: 'string',
      save: true,
      filter: false,
      mapTo: false
    },
    user_city: {
      type: 'string',
      save: true,
      filter: false,
      mapTo: false,
      defaultSetting: true
    },
    user_prov: {
      type: 'string',
      save: false,
      filter: false,
      mapTo: false,
      defaultSetting: true
    },
    province_id: {
      type: 'int',
      save: true,
      mapTo: 'province',
      defaultSetting: true
    },
    docusign_user_id: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    profile_file_id: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    user_fname: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    user_lname: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    user_fname_alt: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    user_lname_alt: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false
    },
    user_email: {
      type: 'string',
      filter: true,
      format: false,
      mapTo: false,
      validate: {
        type: 'email'
      }
    },
    user_email_signature: {
      type: 'string',
      filter: true,
      format: 'email',
      mapTo: false
    },
    user_email_alt: {
      type: 'string',
      filter: true,
      format: 'email',
      mapTo: false
    },
    user_phone_alt: {
      type: 'string',
      filter: true,
      format: 'phone',
      mapTo: false
    },
    user_time_first_login: {
      type: 'int',
      filter: true,
      format: 'datetime',
      mapTo: false
    },
    user_name: {
      type: 'string',
      filter: false,
      format: false,
      mapTo: false
    },
    country_id: {
      type: 'int',
      filter: true,
      format: false,
      mapTo: 'country'
    },
    country_name: {
      type: 'string',
      filter: true,
      format: false
    },
    country_abbr: {
      type: 'string',
      filter: true,
      format: false
    },
    user_country: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    aoUserPermissions: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false
    },
    aoPermissions: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false
    },
    aoUserRoles: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false
    },
    aoRolePermissions: {
      type: 'array',
      filter: false,
      format: false,
      mapTo: false
    },
    user_count_notifications: {
      type: 'int',
      filter: false,
      format: false,
      mapTo: false
    },
    user_is_using_temp_pass: {
      type: 'int',
      filter: false,
      trackChanges: false,
      default: 1
    },
    user_primary_role: {
      type: 'string',
      default: 'manager'
    },
    oMeta: {
      type: 'object',
      save: false,
      trackChanges: false
    },
    user_has_email: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: true
    },
    user_has_announcement: {
      type: 'bool',
      save: true,
      trackChanges: true,
      reload: true,
      default: 'false'
    },
    user_has_phone: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: true,
      format: 'phone'
    },
    user_is_client: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: true
    },
    user_is_company_user: {
      type: 'int',
      save: false,
      trackChanges: false,
      reload: true
    },
    user_is_super_user: {
      type: 'int'
    }
  }
}
