import * as types from '../../mutation-types'
import _ from '../../../../imports/api/Helpers'
import version from '../../../version'
import Router from '@/router'
import { fetchAuthSession } from 'aws-amplify/auth'
import indexDbClient from '@/indexDb-client'
const userAgent = (navigator && navigator.userAgent) || 'app'

const testing = import.meta.env.DEV

// initial state
const originalState = {
  user: {},
  authorizedUser: {},
  userHasConnectedAuthProvider: false,
  company: {},
  quote: {},
  isLoggedIn: 0,
  specificallyLoggedOut: 0,
  token: {},
  shortToken: null,
  accessToken: null,
  idToken: null,
  refreshToken: null,
  persistentUserToken: null,
  headerHeight: 50,
  pagePadding: 0,
  deviceSize: 'xs',
  deviceWidth: _.viewPortSize(),
  deviceHeight: _.viewPortHeight(),
  appType: 'web',
  ip: '',
  userAgent,
  background: 'transparent',
  suggestedDefaultMarkup: 1.42,
  suggestedMinimumMargin: 0.2,
  ckeditorAdded: false,
  testing,
  isOnline: 1,
  isFocused: 1,
  // For pages that rely on a sidebar, that
  // also have a subnav, the subnav needs to slide
  // over the exact width of the sidebar
  slideSubNav: 0,
  scope: {},
  masquerade: false,
  scopableObjects: {},
  scopesByRouteName: {},
  scopableObjectsFetched: 0,
  loading: 0,
  progress: 0,
  loadingMessage: '',
  hideNav: false,
  disableScrolling: 0,
  accessFee: 799,
  version,
  disableClickaway: false,
  defaultPipelineItemDisplayLimit: 5,
  preview: {}
}

const cleanSlate = _.imm(originalState)

// getters
const getters = {
  isGuestUser: (state, gets) => !gets.isCompanyUser,

  isCompanyUser: (state) =>
    state.isLoggedIn && state.scope.company && !state.scope.quote && !state.scope.file,

  isMetric: (state) =>
    !(state.company && state.company.country_id && +state.company.country_id <= 2),

  smallFormat: (state) => {
    return state.deviceWidth < 768
  },

  defaultMarkup: (state) =>
    (state.scope.company && state.company && state.company.company_default_markup) || 1.43,

  defaultMod: (state) => ({
    mod_id: state.company.mod_id || null,
    mod_labor_net: state.company.mod_labor_net || 1,
    mod_materials_net: state.company.mod_materials_net || 1
  }),

  activatePaywall: (state, gets) =>
    gets.inCompany && !gets.companyIsVendor && !gets.companyIsSubscribed && gets.trialIsExpired,

  inCompany: (state, gets) =>
    state.scope &&
    !gets.isGuestuser &&
    Object.keys(state.scope).length === 2 &&
    state.scope.company &&
    String(state.scope.company) === String(state.company.company_id),

  inClient: (state) => {
    return state.isLoggedIn && state.scope.company && state.scope.quote
  },
  inSuper: (state, gets) => {
    const path = Router.currentRoute.value.path
    return (
      state.scope &&
      !gets.isGuestuser &&
      Object.keys(state.scope).length === 1 &&
      state.authorizedUser.user_is_super_user &&
      state.scope.user &&
      String(state.scope.user) === String(state.user.user_id) &&
      (path === '/super' || path.startsWith('/super/'))
    )
  },

  companyIsVendor: (state, gets) => gets.inCompany && state.company.company_is_vendor_count,

  companyIsSubscribed: (state, gets) => gets.inCompany && !!state.company.stripe_subscription_id,

  trialStart: (state, gets) =>
    (gets.inCompany &&
      !gets.companyIsVendor &&
      (state.company.company_time_trial_start || state.company.company_time_created)) ||
    Date.now(),

  trialEnd: (state, gets) => gets.trialStart + 1000 * 60 * 60 * 24 * 14,

  trialIsExpired: (state, gets) => Date.now() > gets.trialEnd,

  trialing: (state, gets) =>
    gets.inCompany && !gets.companyIsVendor && !gets.companyIsSubscribed && !gets.trialIsExpired,

  $b: (state) =>
    state.user.localization_currency_symbol_position === 'b' ? state.company.currency_symbol : '',

  $: (state, gets) => gets.$b,

  $a: (state) =>
    state.user.localization_currency_symbol_position === 'a' ? state.company.currency_symbol : '',

  // Quote warnings
  qwNorm: (sess, g, state) => (state.Quote && state.Quote.normalized) || {},

  qwItemKeys: (state, g) => Object.keys(g.qwNorm),

  qwBelowDefaultMarkup: (state, g) => {
    const min = g.defaultMarkup
    return g.qwItemKeys.filter((refId) => {
      const item = g.qwNorm[refId]

      if (
        item.type === 'cost_item' &&
        (!item.oMeta?.itemType || item.oMeta?.itemType === 'costItem') &&
        item.cost_item_markup_net_adjusted < min
      ) {
        return true
      }

      return false
    })
  },

  qwBelowMinimumMarkup: (state, g) => {
    const min = c.markupToMargin(
      (state.company && state.company.company_minimum_quote_margin) || 1.4
    )
    return g.qwItemKeys.filter((refId) => {
      const item = g.qwNorm[refId]

      if (
        item.type === 'cost_item' &&
        (!item.oMeta?.itemType || item.oMeta?.itemType === 'costItem') &&
        item.cost_item_markup_net_adjusted < min
      ) {
        return true
      }

      return false
    })
  },

  qwZeroItems: (state, g) =>
    g.qwItemKeys.filter((refId) => {
      const item = g.qwNorm[refId]

      if (
        item.type === 'cost_item' &&
        (!item.oMeta?.itemType || item.oMeta?.itemType === 'costItem') &&
        item.cost_item_price_net < 1 &&
        item.cost_item_qty_net >= 0.01
      ) {
        return true
      }

      return false
    }),

  qwZeroQuantityItems: (state, g) =>
    g.qwItemKeys.filter((refId) => {
      const item = g.qwNorm[refId]

      if (
        (item.type === 'cost_item' &&
          (!item.oMeta?.itemType || item.oMeta?.itemType === 'costItem') &&
          item.cost_item_qty_net < 1) ||
        (item.type === 'assembly' && item.quote_qty_net < 1)
      ) {
        return true
      }

      return false
    }),

  qwZeroDimensions: (state, g) =>
    g.qwItemKeys.reduce((acc, refId) => {
      const item = g.qwNorm[refId]

      const emptyDims = _.makeArray(item.asRequiredDimensions || []).filter(
        (abbr) => item.oDimensions[abbr] && item.oDimensions[abbr].value < 1
      )

      if (item.type !== 'assembly' || !emptyDims.length) {
        return acc
      }

      return {
        ...acc,
        [refId]: emptyDims
      }
    }, {}),

  quoteCountWarnings: (state, g) =>
    Object.keys(g.qwZeroDimensions).length +
    g.qwZeroQuantityItems.length +
    g.qwZeroItems.length +
    g.qwBelowMinimumMarkup.length
}

let isBaseOut = false
let isRefsPopulated = false

// actions
const actions = {
  /**
   * Disable scrolling is like mixins/Button
   * where you can add levels of disabling, and then decrease to
   * eventually get to zero (not disabled). OR you can override the levels
   * and end it entirely endDisableScrolling()
   *
   * For example, if you had modal windows the opened, and that modal
   * had more modals that opened, and you wanted to disable scrolling when
   * modal windows are opened, then each time a modal opened it could
   * addDisableScrolling(), to for example 3, then each time one closed, it
   * would decrease one by one until it got to 0, and scrolling would again
   * be enabled automatically.
   *
   * @param commit
   * @param state
   * @returns {Promise<void>}
   */
  async endDisableScrolling({ commit }) {
    commit({
      type: types.SET_DISABLE_SCROLLING,
      disableScrolling: 0
    })
  },
  async removeDisableScrolling({ commit, state }) {
    commit({
      type: types.SET_DISABLE_SCROLLING,
      disableScrolling: Math.max(state.disableScrolling - 1, 0)
    })
  },
  async addDisableScrolling({ commit, state }) {
    commit({
      type: types.SET_DISABLE_SCROLLING,
      disableScrolling: state.disableScrolling + 1
    })
  },

  /**
   * Toggle nav to be hidden or shown
   * @param state
   * @param commit
   * @param visible bool || nul
   * @returns {Promise<void>}
   */
  async toggleNav({ state, commit }, visible = null) {
    const hide = visible === null ? !state.hideNav : !visible

    commit({
      type: types.SET_HIDE_NAV,
      hide
    })

    return !hide
  },

  async getUserMeta({ state }) {
    // TODO: Replace this with something like MaxMind?
    let ipData = {}
    const timeLimit = 1000
    try {
      if (state.ip) {
        let resolved = false
        ipData = await new Promise((resolve, reject) => {
          $.getJSON(
            `http${
              import.meta.env.VITE_BASE_API_URL.includes('https') ? 's' : ''
            }://api.ipstack.com/${state.ip}?access_key=f34a76dfd7ce4fb283fdb37208b6640b&format=1`,
            (data) => {
              resolved = true
              resolve(data)
            }
          )

          setTimeout(() => {
            if (!resolved) {
              reject({})
            }
          }, timeLimit)
        })
      }
    } catch (e) {
      console.log('exception on getUserMeta', e)
    }

    return {
      ...ipData,
      IP: state.ip,
      user_id: state.user.user_id,
      user_name: state.user.user_name,
      user_email: state.user_email,
      user_is_using_temp_pass: state.user_is_using_temp_pass
    }
  },
  getScopableObjects({ commit, dispatch }, payload = {}) {
    return dispatch('ajax', { path: 'pub/getScopableObjects', setToken: false }).then(
      ({ payload: scopableObjects }) => {
        commit({
          type: types.SET_SCOPABLE_OBJECTS,
          scopableObjects
        })
        return Promise.resolve(payload)
      }
    )
  },
  setShortToken({ commit }, payload = {}) {
    const { shortToken = null } = payload
    commit({
      type: types.SET_SHORT_TOKEN,
      token: shortToken
    })
    _.setStorage('shortToken', shortToken)
    return Promise.resolve(payload)
  },
  setPersistentUserToken({ commit }, payload = {}) {
    const { persistentUserToken = null } = payload
    commit({
      type: types.SET_PERSISTENT_USER_TOKEN,
      token: persistentUserToken
    })
    _.setStorage('persistentUserToken', persistentUserToken)
    return Promise.resolve(payload)
  },
  setToken({ commit }, payload = {}) {
    const { token = null } = payload
    commit({
      type: types.SET_TOKEN,
      token
    })
    return Promise.resolve(payload)
  },
  setAccessToken({ commit }, payload = {}) {
    const { accessToken = null } = payload
    commit({
      type: types.SET_ACCESS_TOKEN,
      accessToken
    })
    return Promise.resolve(payload)
  },
  setIdToken({ commit }, payload = {}) {
    const { idToken = null } = payload
    commit({
      type: types.SET_ID_TOKEN,
      idToken
    })
  },
  setRefreshToken({ commit }, payload = {}) {
    const { refreshToken = null } = payload
    commit({
      type: types.SET_REFRESH_TOKEN,
      refreshToken
    })
    return Promise.resolve(payload)
  },
  getScope({ rootState }, payload = {}) {
    const scope = _.imm(rootState.session.scope || _.getStorage('scope') || {})
    return Promise.resolve({ ...payload, scope })
  },
  getScopeRoute({ rootState }, { scopeType, scopeId }) {
    const so = rootState.session.scopableObjects
    return Object.keys(so).find((key) => (so[key]?.[scopeType] ?? null) === scopeId)
  },

  /**
   * Gets the short token (if available)
   *
   * @param dispatch
   * @param route
   *
   * @returns {String|null}
   */
  getShortToken(options, route) {
    if (route?.query) {
      if (route.query.shortToken && typeof route.query.shortToken === 'string') {
        return route.query.shortToken
      }

      if (route.query.st && typeof route.query.st === 'string') {
        return route.query.st
      }
    }

    return null
  },

  /**
   * Get full/long token if available
   *
   * @param dispatch
   * @param route
   *
   * @returns {String|null}
   */
  getToken(options, route) {
    if (route?.query) {
      if (route.query.token && typeof route.query.token === 'string') {
        return route.query.token
      }

      if (route.query.lt && typeof route.query.lt === 'string') {
        return route.query.lt
      }
    }

    return null
  },

  /**
   * Get access token if available
   *
   * @param rootState
   * @param route
   *
   * @returns {String|null}
   */
  getAccessToken(options, route) {
    if (route?.query) {
      if (route.query.accessToken && typeof route.query.accessToken === 'string') {
        return route.query.accessToken
      }

      if (route.query.at && typeof route.query.at === 'string') {
        return route.query.at
      }
    }

    return null
  },

  /**
   * Get id token if available
   *
   * @param dispatch
   * @param route
   *
   * @returns {String|null}
   */
  getIdToken(options, route) {
    if (route?.query) {
      if (route.query.idToken && typeof route.query.idToken === 'string') {
        return route.query.idToken
      }

      if (route.query.it && typeof route.query.it === 'string') {
        return route.query.it
      }
    }

    return null
  },

  /**
   * Get refresh token if available
   *
   * @param dispatch
   * @param route
   *
   * @returns {String|null}
   */
  getRefreshToken(options, route) {
    if (route?.query) {
      if (route.query.refreshToken && typeof route.query.refreshToken === 'string') {
        return route.query.refreshToken
      }

      if (route.query.rt && typeof route.query.rt === 'string') {
        return route.query.rt
      }
    }

    return null
  },

  /**
   * Check tokens, if they exist then set them.
   * @param dispatch
   * @param route
   * @returns {Promise<*>}
   */
  async checkGuestTokens({ dispatch }, route) {
    const availableShortToken = await dispatch('getShortToken', route)
    const availableToken = await dispatch('getToken', route)

    if (availableToken || availableShortToken) {
      await dispatch('logout', { go: false })
    }

    if (availableToken) {
      await dispatch('setToken', { token: availableToken })
    }

    if (availableShortToken) {
      await dispatch('setShortToken', { shortToken: availableShortToken })
    }

    const availableAccessToken = await dispatch('getAccessToken', route)
    const availableIdToken = await dispatch('getIdToken', route)
    const availableRefreshToken = await dispatch('getRefreshToken', route)

    if (availableAccessToken) {
      await dispatch('setAccessToken', { accessToken: availableAccessToken })
    }

    if (availableIdToken) {
      await dispatch('setIdToken', { idToken: availableIdToken })
    }

    if (availableRefreshToken) {
      await dispatch('setRefreshToken', { refreshToken: availableRefreshToken })
    }

    return route
  },

  populateReferenceTables({ rootState, dispatch, getters: g }, payload = {}) {
    if (rootState.session.scope.company && rootState.session.scope.user !== 1 && !g.isGuestUser) {
      dispatch('UnitOfMeasure/search', { saveSet: true })
      dispatch('UnitOfMeasureConversion/search', { saveSet: true })
      dispatch('Dimension/getPossibleDimensions', { full: true })
      dispatch('Dimension/getPossibleDimensions', { full: false })
      isRefsPopulated = true
      // dispatch('Vendor/search');
      // dispatch('Stage/search');
      // dispatch('TradeType/search');
    }
    return Promise.resolve(payload)
  },
  enterSuperScope({ dispatch, rootState }) {
    if (!rootState.session.authorizedUser.user_is_super_user) {
      throw new Error('Cannot enter super scope.')
    }

    return dispatch('setScope', {
      scope: {
        user: rootState.session.authorizedUser.user_id
      }
    })
  },
  async setScope({ commit, dispatch, rootState }, payload = {}) {
    const { scope = {}, skipBaseValues = false, masquerade = null } = payload
    const string = JSON.stringify(scope)
    const scopeChanged = string !== JSON.stringify(rootState.session.scope)
    if (
      scopeChanged ||
      !Object.keys(scope).every(
        (t) => rootState.session[t] && String(rootState.session[t][`${t}_id`]) === String(scope[t])
      )
    ) {
      dispatch('Quote/clearAllCache', {}, { root: true }) // will clear all types not just quote
      commit({
        type: types.SET_SCOPE,
        scope,
        masquerade
      })
      if (scopeChanged && !skipBaseValues) {
        const basePayload = await dispatch('getBaseValues', { ...payload, skipBaseValues: true })
        return dispatch('getScopableObjects', basePayload)
      }
    }

    c.setUniqueScopeId((rootState.session.user && rootState.session.user.user_id) || Date.now())

    return payload
  },
  async browserFocus({ commit }, focusStatus) {
    c.throttle(() =>
      commit({
        type: types.SET_FOCUS_STATUS,
        focusStatus
      })
    )
  },
  browserOnline({ commit }, onlineStatus) {
    commit({
      type: types.SET_ONLINE_STATUS,
      onlineStatus
    })
    return Promise.resolve(onlineStatus)
  },
  ckeditorAdded({ commit }) {
    commit({
      type: types.CKEDITOR_ADDED
    })
    return Promise.resolve(true)
  },
  authFailed({ commit, dispatch }, payload = {}) {
    if (payload.alert) {
      dispatch('alert', {
        error: true,
        text: 'Your login has expired.  Please login again.'
      })
    }
    commit({
      type: types.AUTH_FAILED
    })
    return Promise.resolve(payload)
  },

  async checkPassword({ dispatch, state }) {
    if (state.user && state.user.user_is_using_temp_pass) {
      await dispatch('modal/quickConfirm', {
        title: 'New Password',
        message: 'You are using a temporary password!',
        subMessage:
          'Would you like to update it now? <strong class="text-info">Recommended</strong>'
      })
      try {
        await dispatch('to', '/profile')
      } catch (e) {
        dispatch('alert', {
          message: e.userMessage || 'Could not change password'
        })
        throw e
      }
      return true
    }
    return false
  },

  /**
   * Login to backend, get base values and set access token values
   * @param commit
   * @param dispatch
   * @param rootState
   * @param {object} payload
   *    {
   *      email = null, // email user is attempting to login with
   *      password = null, // password user is attempting to login with
   *      providerLogin = false,
          lastName = null,
          firstName = null,
          picUrl = null,
          token = null,
   *      event,
   *      button,
   *      alert = true
   *    }
   * @returns {Promise} { ...BaseValues } see getBaseValues dispatch
   */
  async login({ commit, dispatch }, payload = {}) {
    const {
      superUserEmail = null,
      email = null,
      password = null,
      // Provider details
      providerLogin = false,
      provider = null,
      lastName = null,
      firstName = null,
      picUrl = null,
      token = null
    } = payload

    dispatch('addLoading')
    commit({ type: types.SESSION_LOGOUT })
    _.setStorage('remainLoggedOut', 0)

    const basicCredentials = [
      ...(email && password && superUserEmail ? [superUserEmail] : []),
      ...(email && password ? [email, password] : [])
    ]

    // TODO: the initial token is available here
    const { object } = await dispatch(
      'ajax',
      {
        path: '/pub/login',
        setToken: true,
        authorization: basicCredentials,
        data: {
          providerLogin,
          provider,
          lastName,
          firstName,
          picUrl,
          token,
          email
        }
      },
      { root: true }
    )

    let skipBaseValues = false

    if (object.authService) {
      const responseValue = await dispatch('modal/prompt', {
        message: object.message,
        inputType: object.responseType || 'password'
      })

      const returnObject = await c.ajax('pub/secondStepLogin', {
        ...object,
        responseValue
      })

      await dispatch('setScope', {
        scope: returnObject.scope
      })

      skipBaseValues = true
    }

    if (!object.skipBaseValues && !skipBaseValues) {
      await dispatch('getBaseValues', payload)
    }

    const isNative = window.localStorage.getItem('isNative')
    const shortToken = window.localStorage.getItem('shortToken')
    const accessToken = window.localStorage.getItem('accessToken')
    const idToken = window.localStorage.getItem('idToken')
    const refreshToken = window.localStorage.getItem('refreshToken')
    if (isNative === 'true') {
      const database = window.sqlitePlugin.openDatabase({
        name: 'CostCerrtified.db',
        location: 'default'
      })
      database.transaction((transaction) => {
        let queryString = 'CREATE TABLE IF NOT EXISTS LocalStorage'
        queryString = `${queryString}(short_token TEXT, token TEXT);`
        transaction.executeSql(queryString, [])
      })
      const queryString = 'INSERT OR REPLACE INTO LocalStorage VALUES (?,?,?,?)'
      database.transaction(
        (transaction) => {
          transaction.executeSql(queryString, [
            shortToken,
            token,
            accessToken,
            idToken,
            refreshToken
          ])
        },
        (error) => {
          // OK to close here:
          console.log(`transaction error: ${error.message}`)
          database.close()
        },
        () => {
          // OK to close here:
          console.log('Login Sqltransaction ok')
          database.close(() => {
            console.log('Login transaction complete database is closed ok')
          })
        }
      )
    }
    return payload
  },

  /**
   * Logout from user
   * @param commit
   * @param state
   * @param dispatch
   * @returns {Promise}
   */
  async logout({ commit, dispatch }, payload = {}) {
    const isNative = window.localStorage.getItem('isNative')
    const router = Router

    await dispatch('addLoading')

    if (isNative === 'true') {
      const database = window.sqlitePlugin.openDatabase({
        name: 'CostCerrtified.db',
        location: 'default'
      })
      database.transaction(
        (transaction) => {
          const queryString = 'DROP TABLE IF EXISTS LocalStorage'
          transaction.executeSql(queryString)
        },
        (error) => {
          // OK to close here:
          console.log(`transaction error: ${error.message}`)
          database.close()
        },
        () => {
          // OK to close here:
          console.log('logout transaction ok')
          database.close(() => {
            console.log(' logout transaction finished database is closed ok')
          })
        }
      )
    }
    const { go = true } = payload

    _.clearStorage()
    _.setStorage('remainLoggedOut', 0)

    try {
      // clear chat session //
      setTimeout(async () => {
        await indexDbClient.deleteDb()
        await dispatch('activityChat/clearSession')
      })
    } catch (e) {
      window.reload()
    }

    // let google and facebook know you are logging out
    commit({
      type: types.SESSION_LOGOUT
    })
    if (go) await router.push({ name: 'Login' })

    await dispatch('removeLoading')

    return true
  },

  /**
   * Get client, project, invoice, task etc counts
   *  relating to the session client.
   * @param commit
   * @param state
   * @param {object} payload
   * @returns {Promise} { ...payload, counts }
   */
  updateCounts({ commit, state, dispatch }, payload = {}) {
    const { button = null, delay = 120000 } = payload

    return c
      .throttle(
        () =>
          dispatch('ajax', {
            path: `/user/getCounts/${state.user.user_id}`,
            button
          }),
        { delay }
      )
      .then(({ object: counts = false }) => {
        if (counts) {
          commit({
            type: types.SET_COUNTS,
            counts
          })
        }
        return Promise.resolve({ ...payload, object: counts, counts })
      })
  },

  /**
   * Get session variables from server for front-end consumption
   * and set them.
   * @param commit
   * @param root
   * @param dispatch
   * @param {object} payload
   * @returns {Promise} { object, token, session, ...payload } see setBaseValues dispatch
   */
  async getBaseValues({ dispatch, rootState }, payload = {}) {
    const {
      cloak = true,
      authorizationType = null,
      authorization = null,
      scope = null,
      alert = true
    } = payload

    // Only do one at a time
    if (isBaseOut) {
      return payload
    }

    let finalPayload = {}
    let ajaxPayload = {}

    try {
      if (cloak) {
        await dispatch('addLoading')
      }
      isBaseOut = true
      ajaxPayload = await dispatch('ajax', {
        path: '/pub/baseValues',
        authorizationType,
        authorization,
        scope,
        useAuthScope: true,
        setToken: true
      })
      finalPayload = await dispatch('setBaseValues', { ...payload, ...ajaxPayload })

      const authedUser = ajaxPayload.object.authorizedUser
      if (!rootState.session.scopableObjectsFetched && authedUser) {
        await dispatch('getScopableObjects', finalPayload)
      }

      if (!isRefsPopulated && authedUser) {
        await dispatch('populateReferenceTables')
      }

      return finalPayload
    } catch (e) {
      if (alert && e.userMessage) {
        dispatch('alert', {
          message: `${e.userMessage}.`,
          error: true
        })
      }
      throw e
    } finally {
      isBaseOut = false
      if (cloak) {
        await dispatch('removeLoading')
      }
    }
  },

  /**
   * Set BaseValues (session/token/user/company values) based on provided values
   * @param commit
   * @param root
   * @param dispatch
   * @param {object} payload
   * @returns {Promise} { object, token, session, ...payload }
   */
  setBaseValues({ commit, dispatch, state }, payload = {}) {
    const {
      token,
      shortToken,
      accessToken,
      idToken,
      refreshToken,
      object,
      persistentCompanyToken,
      persistentUserToken
    } = payload

    const scope = payload.scope || object.scope
    // Set user & login values
    commit({
      type: types.SESSION_LOGIN,
      ...object,
      userHasConnectedAuthProvider: object.userHasConnectedAuthProvider,
      token,
      shortToken,
      accessToken,
      idToken,
      refreshToken,
      persistentUserToken,
      persistentCompanyToken
    })

    if (object.authorized) {
      // Set scope only if it hasn't been set
      if (scope && !Object.keys(state.scope).length) {
        dispatch('setScope', { scope, skipBaseValues: true })
      }
    }

    return payload
  },

  /**
   * DEPRECATED use setMetaItem and getMetaItem instead
   * @param dispatch
   * @param payload
   * @returns {Promise<void>}
   */
  async setUserSetting({ dispatch }, payload = {}) {
    const { name = false, value = 'NULL' } = payload

    if (!name) return payload

    await dispatch('ajax', {
      path: `/user/setUserSetting/${name}/${value}`
    })

    await dispatch('getBaseValues', { ...payload, cloak: false })

    return payload
  },
  setBackground({ commit }, payload = {}) {
    const { background } = payload
    commit({
      type: types.SET_BACKGROUND,
      background
    })
    return Promise.resolve(payload)
  },
  setHeaderHeight({ commit }, payload = {}) {
    const { height } = payload
    commit({
      type: types.SET_HEADER_HEIGHT,
      height
    })
    return Promise.resolve(payload)
  },
  setDeviceSize({ commit }, payload = {}) {
    const { size, height, width } = payload
    commit({
      type: types.SET_DEVICE_SIZE,
      size,
      height,
      width
    })
    return Promise.resolve(payload)
  },
  async setProgress({ commit }, { progress = 0, loadingMessage = '' }) {
    commit({
      type: types.SET_LOADING,
      progress,
      loadingMessage
    })
  },
  async addLoading({ commit, state }, { loadingMessage = '' } = {}) {
    commit({
      type: types.SET_LOADING,
      loading: state.loading + 1,
      loadingMessage
    })
  },
  async removeLoading({ commit, state }) {
    const loading = Math.max(state.loading - 1, 0)
    const progress = loading === 0 ? 0 : state.progress + 1
    commit({
      type: types.SET_LOADING,
      loading,
      progress
    })
  },
  async endLoading({ commit }) {
    commit({
      type: types.SET_LOADING,
      loading: 0,
      progress: 0
    })
  },
  async setUserAnnouncementFlag({ commit }, flag) {
    commit({
      type: types.SET_USER_ANNOUNCEMENT_FLAG,
      flag
    })
  }
}

const getRoutableName = (object) => {
  const objectId = object[`${object.type}_id`]
  const objectShortName = object[`${object.type}_name_short`]
  const objectName = object[`${object.type}_name`]

  if (objectId && (objectShortName || objectName)) {
    return `${objectId}-${(objectShortName || objectName).replace(/[^A-Za-z0-9]/g, '')}`
  }
}
// mutations
const mutations = {
  [types.SET_DISABLE_CLICKAWAY](state) {
    state.disableClickaway = true
  },
  [types.SET_ENABLE_CLICKAWAY](state) {
    state.disableClickaway = false
  },
  [types.SET_DISABLE_SCROLLING](state, { disableScrolling }) {
    state.disableScrolling = disableScrolling
  },
  [types.SET_HIDE_NAV](state, { hide }) {
    state.hideNav = hide
  },
  [types.SET_TOKEN](state, { token }) {
    state.token = token || null
    _.setStorage('token', token)
  },
  [types.SET_SHORT_TOKEN](state, { token }) {
    state.shortToken = token || null
    _.setStorage('shortToken', token)
  },
  [types.SET_ACCESS_TOKEN](state, { accessToken = null }) {
    state.accessToken = accessToken
    _.setStorage('accessToken', accessToken)
  },
  async [types.SET_ID_TOKEN](state, { idToken = null }) {
    const { tokens } = await fetchAuthSession()
    idToken = tokens.idToken.toString()

    _.setStorage('idToken', idToken)
    document.cookie = `jwt=${idToken}; domain=.${window.location.hostname}; path=/`
  },
  [types.SET_REFRESH_TOKEN](state, { refreshToken = null }) {
    state.refreshToken = refreshToken
    _.setStorage('refreshToken', refreshToken)
  },
  [types.SET_PERSISTENT_USER_TOKEN](state, { token }) {
    state.persistentUserToken = token || null
    _.setStorage('persistentUserToken', token)
  },
  [types.SET_SCOPE](state, { scope = null, masquerade = null }) {
    state.scope = _.imm(scope ?? state.scope)

    if (state.authorizedUser.user_is_super_user) {
      state.masquerade = masquerade ?? state.masquerade
      _.setStorage('masquerade', state.masquerade && state.scope)
    }

    c.assert(false, `SET SCOPE: ${JSON.stringify(scope)}`)
    _.setStorage('scope', _.imm(scope || {}))
  },
  [types.CKEDITOR_ADDED](state) {
    state.ckeditorAdded = true
  },
  [types.AUTH_FAILED](state) {
    state.isLoggedIn = 0
    state.user = {}
    state.company = {}
    _.setStorage('token', '')
  },
  [types.SET_SCOPABLE_OBJECTS](state, { scopableObjects = {} }) {
    const scopeObjs = {}
    const scopesByRouteName = {}

    for (const [scopableType, objects] of Object.entries(scopableObjects)) {
      scopeObjs[scopableType] = objects.map((obj) => {
        const route = getRoutableName(obj)
        const mappedObj = { ...obj, route }

        // Populate scopesByRouteName directly
        const scopeData = {}
        for (const field in obj) {
          if (field.includes('_id')) {
            const key = field.replace('_id', '')
            if (scopableObjects[key]) {
              scopeData[key] = +obj[field] // Convert to number
            }
          }
        }

        scopesByRouteName[route] = scopeData
        return mappedObj
      })
    }

    state.scopableObjects = scopeObjs
    state.scopesByRouteName = scopesByRouteName
    state.scopableObjectsFetched = 1
  },
  async [types.SESSION_LOGIN](state, payload = {}) {
    const {
      user = null,
      company = null,
      quote = null,
      userHasConnectedAuthProvider = null,
      token = null,
      shortToken = null,
      accessToken = null,
      refreshToken = null,
      scope = [],
      ip = null,
      authorizedUser = null,
      persistentUserToken = null,
      persistentCompanyToken = null
    } = payload

    if (user) {
      state.user = user
      if (user.oCounts && user.oCounts.messages_unread) user.oCounts.messages_unread = 0
    }
    if (authorizedUser && Object.keys(authorizedUser).length) {
      state.isLoggedIn = 1
      state.authorizedUser = authorizedUser
    }
    if (company) state.company = company
    if (quote) state.quote = quote
    if (ip) state.ip = ip
    state.scope = _.imm(scope || {})
    _.setStorage('scope', scope)
    if (userHasConnectedAuthProvider) {
      state.userHasConnectedAuthProvider = userHasConnectedAuthProvider
    }
    state.persistentUserToken = persistentUserToken
    state.persistentCompanyToken = persistentCompanyToken

    const { tokens } = await fetchAuthSession()
    const idToken = tokens.idToken.toString()

    _.setStorage('persistentUserToken', persistentUserToken)
    _.setStorage('persistentCompanyToken', persistentCompanyToken)
    _.setStorage('token', token)
    _.setStorage('shortToken', shortToken)
    _.setStorage('accessToken', accessToken)
    _.setStorage('idToken', idToken)
    _.setStorage('refreshToken', refreshToken)

    document.cookie = `jwt=${idToken}; domain=.${window.location.hostname}; path=/`
  },
  [types.SET_COUNTS](state, { counts }) {
    // extract messages
    counts.messages_unread = 0
    state.user = {
      ...state.user,
      oCounts: counts
    }
  },
  [types.SESSION_LOGOUT](state) {
    localStorage.clear()
    const cs = _.imm(cleanSlate)
    Object.keys(cs).forEach((key) => {
      state[key] = cs[key]
    })
  },
  [types.SET_BACKGROUND](state, { background }) {
    state.background = background
  },
  [types.SET_HEADER_HEIGHT](state, { height }) {
    state.headerHeight = height
  },
  [types.SET_DEVICE_SIZE](state, { size, height, width }) {
    state.deviceSize = size
    state.deviceWidth = width
    state.deviceHeight = height
  },
  [types.SET_APP_TYPE](state, { appType }) {
    state.appType = appType
  },
  [types.SET_FOCUS_STATUS](state, { focusStatus }) {
    state.isFocused = focusStatus
  },
  [types.SET_ONLINE_STATUS](state, { onlineStatus }) {
    state.isOnline = onlineStatus
  },
  [types.SET_SLIDE_SUBNAV](state, { slide }) {
    state.slideSubNav = slide
  },
  [types.SET_LOADING](state, { loading, progress = null, loadingMessage = '' }) {
    state.loading = loading
    if (progress !== null) state.progress = progress
    state.loadingMessage = loading === 0 ? '' : state.loadingMessage || loadingMessage
  },
  [types.SET_USER_ANNOUNCEMENT_FLAG](state, { flag }) {
    state.user.user_has_announcement = flag
  },
  [types.SET_PREVIEW](state, { preview }) {
    state.preview = preview
  }
}

export default {
  state: _.imm(originalState),
  getters,
  actions,
  mutations
}
