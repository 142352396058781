const meta = {
  scopesAllowed: ['user', 'company', 'franchisor'],
  menuContext: 'franchisor'
}

export default [
  {
    path: '/:scopeRoute/franchisor',
    alias: ['/:scopeRoute/franchisor'],
    children: [
      {
        path: 'metrics',
        name: 'Franchise Metrics',
        component: () => import('@/components/pages/Metrics.vue'),
        meta
      },
      {
        path: 'franchise',
        name: 'Franchise',
        component: () => import('@/components/pages/Franchise.vue'),
        meta: {
          ...meta
        }
      },
      {
        path: 'items',
        name: 'Franchise Items List',
        component: () => import('@/components/pages/Items.vue'),
        meta: {
          ...meta
        }
      },
      {
        path: 'users',
        name: 'Franchise Users',
        component: () => import('@/components/pages/Users.vue'),
        meta: {
          ...meta
        }
      }
    ],
    meta
  }
]
